import React, { useRef, useEffect, useState } from "react";
import { Paper, Theme, Button } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import { getFromJson, saveToJson } from "../../../helpers/localData";
import { off } from "process";
import Tooltip from "@mui/material/Tooltip";
import { fetchSkusByBrandAndIncreaseOrder, getAllSkus, getSkuById, getSkusOrderedByBrand } from "../../../../services/skuService";
import { getSkusWithPriority } from "../../../../services/skuPriorityService";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    flexContainer: {
      display: "flex",
      //margin: `5px`,
    },
    square: {
      width: 400,
      height: 50,
      backgroundColor: "#E8F3FF",
      boxShadow: "none",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      backgroundSize: "cover",
      backgroundPosition: "center",
      position: "relative",
      zindex: -1,
      "&:hover": {
        cursor: "pointer",
      },
    },
    buttonContainer: {
      display: "flex",
      justifyContent: "center",
      paddingBottom: "20px",
      paddingTop: "20px",
    },
    button: {
      display: "flex",
      width: 140,
      height: 56,
      fontFamily: "Mulish",
      flexDirection: "column",
      alignItems: "center",
      margin: "0 2px",
      padding: "10px 20px",
      borderRadius: "0",
      border: "1px solid transparent",
      backgroundColor: "#fff",
      fontSize: "16px",
      fontWeight: 400,
      color: "#383A49",
      "&:hover": {
        backgroundColor: "#f0f0f0",
      },
      "&.selected": {
        color: "#0179FF  !important ",
        fontSize: "16px !important",
        fontWeight: 700,
      },
      "&.disabled": {
        opacity: 0.95,
        cursor: "not-allowed",
      },
    },
    smallDiv: {
      width: 28.5,
      height: 45,
      backgroundColor: "#ffffff",
      margin: "1.25px",
      borderRadius: "4px",
    },
    smallDivContainer: {
      position: "absolute",
      display: "flex",
      alignItems: "center",
      zIndex: 0,
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      justifyContent: "flex-start",
    },
    selectedSquare: {
      border: "2px solid #0179FF",
      boxSizing: "border-box"
    },
    closeIcon: {
      position: "absolute",
      width: "16px !important",
      height: "16px !important",
      top: 0,
      right: 0,
      cursor: "pointer",
      zIndex: 600,
      color: "black",
      "&:hover": {
        opacity: 0.4,
      },
    },
    yellowSquare: {
      position: "absolute",
      backgroundColor: "#FFD700",
      cursor: "grab",
      zIndex: 400,
      "&:active": {
        cursor: "grabbing",
      },
    },
    buttonText: {
      marginBottom: "5px",
    },
    buttonDot: {
      width: "8px",
      height: "8px",
      borderRadius: "50%",
      backgroundColor: "#0179FF",
      marginTop: "5px",
    },
    buttonLeft: {
      borderTopLeftRadius: "30px",
      borderBottomLeftRadius: "30px",
    },
    buttonRight: {
      borderTopRightRadius: "30px",
      borderBottomRightRadius: "30px",
    },
    pinkSquare: {
      position: "absolute",
      backgroundColor: "#F95252",
      cursor: "grab",
      zIndex: 500,
      "&:active": {
        cursor: "grabbing",
      },
    },
  })
);
type Array3D = boolean[][][];

interface DuplexTypeSummary {
  text: string;
  value: number;
}

interface GridDemoProps {
  row: number;
  col: number;
  squaresData: SquareData[];
  setSquaresData: React.Dispatch<React.SetStateAction<SquareData[]>>;
  items: {
    text: string;
    id: number;
    color: string;
    value: number;
    width: number;
  }[];
  pinkSquares: {
    width: number;
    height: number;
    x: number;
    y: number;
    title?: string | undefined;
    positions?: any;
  }[];
  yellowSquares: {
    width: number;
    height: number;
    x: number;
    y: number;
    title?: string | undefined;
    positions?: any;
  }[];
  selectedSquare: string | null;
  setSelectedSquare: (id: string) => void;
  selectedImage: string | null;
  setSelectedImage: (image: string | null) => void;
  ccSpace: number;
  widths: number[];
  keyAccount: number
}

interface DuplexType {
  id: number;
  value: number;
  images: (string | null)[];
}

interface SquareData {
  row: number;
  col: number;
  duplexTypes: { id: number; value: number ,color:string,text:string,width:number,images:string[]}[];
}
type ItemProps = {
  id: number;
  pack_image: string;
  variantName: string;
  brandName: string;
  min_facings: number;
};
const GridDemo: React.FC<GridDemoProps> = ({
  row,
  col,
  items,
  pinkSquares,
  yellowSquares,
  selectedSquare,
  setSelectedSquare,
  selectedImage,
  setSelectedImage,
  squaresData,
  setSquaresData,
  ccSpace,
  widths,
  keyAccount
}) => {
  const classes = useStyles();
  const containerRef = useRef<HTMLDivElement>(null);
  const [squaresDataFacing, setSquaresDataFacing] = useState<SquareData[]>([]);
  const [overLayResult, setOverLayResult] = useState<boolean[][][]>();

  useEffect(() => {
    const fetchOrderedSkus = async (brand: string) => {
      try {
        const data = await getSkusOrderedByBrand(brand, "RRP");
        return data
      } catch (error) {
        console.error("Error fetching ordered SKUs:", error);
      } finally {
      }
    };
    const fetchSkusList = async () => {
      const falseCount = 10
      const aggregatedData = squaresData.reduce<DuplexTypeSummary[]>((acc, current) => {
        current.duplexTypes.forEach((type:any) => {
            const existingType = acc.find((item:any) => item.text === type.text);
            if (existingType) {
                existingType.value += type.value;
            } else {
                acc.push({ text: type.text, value: type.value });
            }
        });
        return acc;
      }, []);
      
      
      const textsToDuplicate = [
        "VEEV ONE Standing & Laying DP",
        "VEEV ONE Laying DP",
        "HEETS",
        "LEVIA",
        "TEREA",
        "Delia",
        "Fiit"
      ];
      
      const updatedData = aggregatedData.map(item => {
        if (textsToDuplicate.includes(item.text)) {
            return { ...item, value: item.value * 2 };
        }
        return item;
      });
      
      const groupMappings: { [key: string]: string } = {
        "VEEV ONE Standing DP": "VEEV ONE",
        "VEEV ONE Standing & Laying DP": "VEEV ONE",
        "VEEV ONE Laying DP": "VEEV ONE",
        "VEEV ONE": "VEEV ONE",
        "VEEV NOW Laying  DP": "VEEV NOW",
        "VEEV Now Standing DP": "VEEV NOW",
        "VEEV NOW": "VEEV NOW"
      };
      
      const groupedData = updatedData.reduce((acc: { [key: string]: number }, item) => {
        const groupKey = groupMappings[item.text] || item.text;
        if (!acc[groupKey]) {
            acc[groupKey] = 0;
        }
        acc[groupKey] += item.value;
        return acc;
      }, {});
      
      const result = Object.keys(groupedData).map(key => ({
        text: key,
        value: groupedData[key]
      }));
      
      console.log(result);
      const veevOneValue = result.find(item => item.text === "VEEV ONE")?.value;
      const veevNowValue = result.find(item => item.text === "VEEV NOW")?.value;
      const ccLaying2DPValue = result.find(item => item.text === " CC Laying 2 DP")?.value;
      const hnbDPValue = result.find(item => item.text === "HNB DP")?.value;
      const fiitValue = result.find(item => item.text === "Fiit")?.value;
      const deliaValue = result.find(item => item.text === "Delia")?.value;
      const leviaValue = result.find(item => item.text === "LEVIA")?.value;
      const heetsValue = result.find(item => item.text === "HEETS")?.value;
      const tereaValue = result.find(item => item.text === "TEREA")?.value;
    

const data = await getSkusWithPriority(keyAccount, 'RRP');
const dataInc = data.sort((a: any, b: any) => a.increase_order - b.increase_order);
let heetsIncrease: ItemProps[] = [];
let tereaIncrease: ItemProps[] = [];
let fiitIncrease: ItemProps[] = [];
let deliaIncrease: ItemProps[] = [];
let veevOneIncrease: ItemProps[] = [];
let veevNowIncrease: ItemProps[] = [];
let leviaIncrease: ItemProps[] = [];

dataInc.forEach((item: ItemProps) => {
  switch (item.brandName.toLowerCase()) {
    case 'heets':
      heetsIncrease.push(item);
      break;
    case 'terea':
      tereaIncrease.push(item);
      break;
    case 'fiit':
      fiitIncrease.push(item);
      break;
    case 'delia':
      deliaIncrease.push(item);
      break;
    case 'veev one':
      veevOneIncrease.push(item);
      break;
    case 'veev now':
      veevNowIncrease.push(item);
      break;
    case 'levia':
      leviaIncrease.push(item);
      break;
    default:
      break;
  }
});
console.log('heetsIncrease',heetsIncrease)
const heets = (await fetchOrderedSkus('HEETS')).filter((item: any) => {
  const matchingItem = heetsIncrease.find((incItem: any) => incItem.id === item.id);
  return matchingItem ? matchingItem.min_facings > 0 : true;
});

const fiit = (await fetchOrderedSkus('FIIT')).filter((item: any) => {
  const matchingItem = fiitIncrease.find((incItem: any) => incItem.id === item.id);
  return matchingItem ? matchingItem.min_facings > 0 : true;
});

const terea = (await fetchOrderedSkus('TEREA')).filter((item: any) => {
  const matchingItem = tereaIncrease.find((incItem: any) => incItem.id === item.id);
  return matchingItem ? matchingItem.min_facings > 0 : true;
});

const delia = (await fetchOrderedSkus('DELIA')).filter((item: any) => {
  const matchingItem = deliaIncrease.find((incItem: any) => incItem.id === item.id);
  return matchingItem ? matchingItem.min_facings > 0 : true;
});

const VEEV_NOW = (await fetchOrderedSkus('VEEV NOW')).filter((item: any) => {
  const matchingItem = veevNowIncrease.find((incItem: any) => incItem.id === item.id);
  return matchingItem ? matchingItem.min_facings > 0 : true;
});

const VEEV_ONE = (await fetchOrderedSkus('VEEV ONE')).filter((item: any) => {
  const matchingItem = veevOneIncrease.find((incItem: any) => incItem.id === item.id);
  return matchingItem ? matchingItem.min_facings > 0 : true;
});

const levia = (await fetchOrderedSkus('LEVIA')).filter((item: any) => {
  const matchingItem = leviaIncrease.find((incItem: any) => incItem.id === item.id);
  return matchingItem ? matchingItem.min_facings > 0 : true;
});
// Convert to string IDs only for filtered items
const heetsIds = heets.map((item: any) => item.id.toString());
const fiitIds = fiit.map((item: any) => item.id.toString());
const tereaIds = terea.map((item: any) => item.id.toString());
const deliaIds = delia.map((item: any) => item.id.toString());
const veevNowIds = VEEV_NOW.map((item: any) => item.id.toString());
const veevOneIds = VEEV_ONE.map((item: any) => item.id.toString());
const leviaIds = levia.map((item: any) => item.id.toString());
const heetsData = heetsIncrease
  .filter((item: any) => item.min_facings > 0)
  .map((item: any) => item.id.toString());

const tereaData = tereaIncrease
  .filter((item: any) => item.min_facings > 0)
  .map((item: any) => item.id.toString());

const fiitData = fiitIncrease
  .filter((item: any) => item.min_facings > 0)
  .map((item: any) => item.id.toString());

const deliaData = deliaIncrease
  .filter((item: any) => item.min_facings > 0)
  .map((item: any) => item.id.toString());

const veevOneData = veevOneIncrease
  .filter((item: any) => item.min_facings > 0)
  .map((item: any) => item.id.toString());

const veevNowData = veevNowIncrease
  .filter((item: any) => item.min_facings > 0)
  .map((item: any) => item.id.toString());

const leviaData = leviaIncrease
  .filter((item: any) => item.min_facings > 0)
  .map((item: any) => item.id.toString());


const heetsDataReversed = [...heetsData].reverse();
const tereaDataReversed = [...tereaData].reverse();
const fiitDataReversed = [...fiitData].reverse();
const deliaDataReversed = [...deliaData].reverse();
const veevOneDataReversed = [...veevOneData].reverse();
const veevNowDataReversed = [...veevNowData].reverse();
const leviaDataReversed = [...leviaData].reverse();
const minimumFacingHeets = heetsIncrease
.filter((item: any) => item.min_facings > 0)
.map((item: any) => {
  return { minFacing: item.min_facings, title: item.id.toString() };
});

const minimumFacingFiit = fiitIncrease
.filter((item: any) => item.min_facings > 0)
.map((item: any) => {
  return { minFacing: item.min_facings, title: item.id.toString() };
});

const minimumFacingTerea = tereaIncrease
.filter((item: any) => item.min_facings > 0)
.map((item: any) => {
  return { minFacing: item.min_facings, title: item.id.toString() };
});

const minimumFacingDelia = deliaIncrease
.filter((item: any) => item.min_facings > 0)
.map((item: any) => {
  return { minFacing: item.min_facings, title: item.id.toString() };
});

const minimumFacingVeevNow = veevNowIncrease
.filter((item: any) => item.min_facings > 0)
.map((item: any) => {
  return { minFacing: item.min_facings, title: item.id.toString() };
});

const minimumFacingVeevOne = veevOneIncrease
.filter((item: any) => item.min_facings > 0)?.map((item: any) => {
  return { minFacing: item.min_facings, title: item.id.toString() };
});
console.log('TestV1',minimumFacingVeevOne)

const minimumFacingLevia = leviaIncrease
.filter((item: any) => item.min_facings > 0)
.map((item: any) => {
  return { minFacing: item.min_facings, title: item.id.toString() };
});

     
      const facedArrayHeetsNOrder = createFacingArray(Math.ceil((heetsValue || 0) * (100 * 0.01)), minimumFacingHeets, heetsIds, heetsData, heetsDataReversed);
const facedArrayHeets = orderByCustomOrder(facedArrayHeetsNOrder, heetsIds);
console.log('test achivment')

// For FIIT

const facedArrayFiitNOrder = createFacingArray(Math.ceil((fiitValue || 0)  * (100 * 0.01)), minimumFacingFiit, fiitIds, fiitData, fiitDataReversed);
const facedArrayFiit = orderByCustomOrder(facedArrayFiitNOrder, fiitIds);

// For TEREA
const facedArrayTereaNOrder = createFacingArray(Math.ceil((tereaValue || 0)  * (100 * 0.01)), minimumFacingTerea, tereaIds, tereaData, tereaDataReversed);
const facedArrayTerea = orderByCustomOrder(facedArrayTereaNOrder, tereaIds);

// For DELIA
const facedArrayDeliaNOrder = createFacingArray(Math.ceil((deliaValue || 0) * (100 * 0.01)), minimumFacingDelia, deliaIds, deliaData, deliaDataReversed);
const facedArrayDelia = orderByCustomOrder(facedArrayDeliaNOrder, deliaIds);

// For VEEV NOW
const facedArrayVeevNowNOrder = createFacingArray(Math.ceil((veevNowValue || 0) * (100 * 0.01)), minimumFacingVeevNow, veevNowIds , veevNowData, veevNowDataReversed);
const facedArrayVeevNow = orderByCustomOrder(facedArrayVeevNowNOrder, veevNowIds);

// For VEEV ONE
const facedArrayVeevOneNOrder =  createFacingArray(Math.ceil((veevOneValue || 0) * (100 * 0.01)), minimumFacingVeevOne, veevOneIds , veevOneData, veevOneDataReversed);
const facedArrayVeevOne = orderByCustomOrder(facedArrayVeevOneNOrder, veevOneIds);

// For LEVIA
const facedArrayLeviaNOrder = createFacingArray(Math.ceil((leviaValue || 0) * (100 * 0.01)), minimumFacingLevia, leviaIds , leviaData, leviaDataReversed);
const facedArrayLevia = orderByCustomOrder(facedArrayLeviaNOrder, leviaIds);

console.log("facedArrayHeets:", facedArrayHeets);
console.log("facedArrayFiit:", facedArrayFiit);
console.log("facedArrayTerea:", facedArrayTerea);
console.log("facedArrayDelia:", facedArrayDelia);
console.log("facedArrayVeevNow:", facedArrayVeevNow);
console.log("facedArrayVeevOne:", facedArrayVeevOne);
console.log("facedArrayLevia:", facedArrayLevia);

let facedArrayIndex = 0; // To keep track of where we are in facedArrayHeets
// Loop through each entry in data
const squaresData2 = JSON.parse(JSON.stringify(squaresData));
 // To keep track of where we are in facedArrayHeets

// Loop through each entry in data
// squaresData2.forEach(entry => {
//     entry.duplexTypes.forEach(duplexType => {
//         if (textsToDuplicate.includes(duplexType.text)) {
//             // Double the value if the text is in the duplication list
//             duplexType.value *= 2;
//         }

//         if (duplexType.text === "HEETS" && facedArrayIndex < facedArrayHeets.length) {
//             // Initialize the images array if it doesn't exist
//             duplexType.images = [];

//             // Fill the images array with values from facedArrayHeets
//             while (facedArrayIndex < facedArrayHeets.length  && duplexType.images.length < duplexType.value) {
              
//                 duplexType.images.push(facedArrayHeets[facedArrayIndex]);
           
//                 facedArrayIndex++;
//             }
//         }
//     });
// });
const processFacings = (facedArray:any, text:any, data:any) => {
  let facedArrayIndex = 0;

  squaresData2.forEach((entry:any) => {
      entry.duplexTypes.forEach((duplexType:any) => {
          const mappedText = groupMappings[duplexType.text] || duplexType.text;

          if (textsToDuplicate.includes(duplexType.text) && mappedText === text) {
              // Double the value if the text is in the duplication list
              duplexType.value *= 2;
          }

          if (mappedText === text && facedArrayIndex < facedArray.length) {
              // Initialize the images array if it doesn't exist
              duplexType.images = [];

              // Fill the images array with values from facedArray
              while (facedArrayIndex < facedArray.length && duplexType.images.filter((image:any) => image !== null).length < duplexType.value) {
                if(duplexType.text==='VEEV ONE Standing DP'){
                  duplexType.images.push(facedArray[facedArrayIndex].toString());
                  duplexType.images.push(null);

                }else if (duplexType.text==="VEEV NOW Laying  DP"){
                  duplexType.images.push(null);
                  duplexType.images.push(facedArray[facedArrayIndex].toString());
                }else if (duplexType.text==='VEEV Now Standing DP'){
                  duplexType.images.push(facedArray[facedArrayIndex].toString());
                  duplexType.images.push(null);

                }else {
                  duplexType.images.push(facedArray[facedArrayIndex].toString());

                }
                  facedArrayIndex++;
              }
          }
      });
  });
};

// Process all the facings
processFacings(facedArrayHeets, "HEETS", squaresData2);
processFacings(facedArrayFiit, "Fiit", squaresData2);
processFacings(facedArrayTerea, "TEREA", squaresData2);
processFacings(facedArrayDelia, "Delia", squaresData2);
processFacings(facedArrayVeevNow, "VEEV NOW", squaresData2);
processFacings(facedArrayVeevOne, "VEEV ONE", squaresData2);
processFacings(facedArrayLevia, "LEVIA", squaresData2);
console.log('squaresData2',squaresData2);

setSquaresDataFacing(squaresData2)

};
  if(squaresData.length > 0 && squaresDataFacing.length ===0) { 
    fetchSkusList()
    console.log('squaresDataFacing', squaresData)
  }
  
  }, [squaresData]);
  function createFacingArray(n:any, minimumFacing:any, packOrder:any, increaseOrder:any, decreaseOrder:any) {
    // Initialize the array without null values initially
    if(packOrder.length ===0 ) {
      return []
    }
    let resultArray = [];

    // Create a map from minimumFacing for quick lookup
    const facingMap = new Map(minimumFacing.map((item:any) => [item.title.trim(), item.minFacing]));

    // Fill the array based on the order and minFacing values
    for (let pack of packOrder) {
        pack = pack.trim(); // Clean any whitespace
        if (facingMap.has(pack)) {
            let count = facingMap.get(pack);
            for (let i = 0; i < (count as any); i++) {
                resultArray.push(pack);
            }
        }
    }

    // Handle the case where the initial array is less than n
    let currentIndex = resultArray.length;
    while (currentIndex < n) {
        for (let pack of increaseOrder) {
            if (currentIndex >= n) break;
            pack = pack.trim();
            resultArray.push(pack);
            currentIndex++;
        }
    }

    // If the total exceeds n, start decreasing according to the decreaseOrder
    currentIndex = resultArray.length;
    if (currentIndex > n) {
        let decreaseIndex = 0;
        while (currentIndex > n) {
            let packToRemove = decreaseOrder[decreaseIndex % decreaseOrder.length].trim();
            let removalIndex = resultArray.lastIndexOf(packToRemove);
            if (removalIndex !== -1) {
                resultArray.splice(removalIndex, 1);
                currentIndex--;
            }
            decreaseIndex++;
        }
    }

    // Fill any remaining 'null' slots if needed (safety check)
    resultArray = resultArray.concat(new Array(n - resultArray.length).fill(null));

    return resultArray;
}
function orderByCustomOrder(stringsArray: string[], orderArray: string[]): string[] {
  if(stringsArray.length ===0 ) {
    return []
  }
  return stringsArray.sort((a, b) => orderArray.indexOf(a) - orderArray.indexOf(b));
}
  useEffect(() => {
    if (selectedSquare && selectedImage) {
       
        
      const [rowIndex, colIndex, duplexId, divIndex] = selectedSquare.split("-").map(Number);
      const updatedSquaresData = squaresData.map((data) => {
        if (data.row === rowIndex && data.col === colIndex) {

          const updatedDuplexTypes = data.duplexTypes.map((duplex) => {
            console.log("duplex.id",duplex)

            if (duplex.id === duplexId) {
             
              const images = [...(duplex?.images || [])];
              images[divIndex] = selectedImage;
              return { ...duplex, images };
            }
            return duplex;
          });
          return { ...data, duplexTypes: updatedDuplexTypes };
        }
        return data;
      });

      setSquaresData(updatedSquaresData);
      saveToJson("squaresData", updatedSquaresData);
      setSelectedImage(null);
    }
  }, [selectedSquare, selectedImage, squaresData, setSelectedImage]);

  const handleSquareClick = (rowIndex: number, colIndex: number, duplexId: number, divIndex: number) => {
    setSelectedSquare(`${rowIndex}-${colIndex}-${duplexId}-${divIndex}`);
  };
  const renderSmallDivs = (numDivs: number,rowIndex:number) => {
    const smallDivs = [];
    const currentRowOverLay = overLayResult ? overLayResult?.[0]?.[rowIndex] : null
    for (let i = 0; i < numDivs; i++) {
      smallDivs.push(<div key={i} className={classes.smallDiv} style={{ left: `${i * 32}px`, background: currentRowOverLay?.[i] ? 'red': 'white',opacity:currentRowOverLay?.[i] ?0.4 :1   }} />); // Position each small div
    }
    return smallDivs;
  };
  const [skuImages, setSkuImages] = useState<Record<number, { packImage: string | null; layingPackImage: string | null }>>({});

  useEffect(() => {
    const fetchSkuImages = async () => {
      const skus = await getAllSkus();

    // Create a mapping from SKU IDs to their images
    const imagesMap = skus.reduce((acc:any, sku:any) => {
      acc[sku.id] = {
        packImage: sku.pack_image || null,
        layingPackImage: sku.pack_image_laying || null,
        name: sku.brandName +' '+ sku.variantName
      };
      return acc;
    }, {} as Record<number, { packImage: string | null; layingPackImage: string | null }>);

    // Set the images map to state
  
      setSkuImages(imagesMap);
    };
  
    fetchSkuImages();
  }, [squaresData]);
  useEffect(() => {
    const createImageArray = () => {
      // Initialize the new array
      const newArray: { top: string; bottom: string }[][] = [];
  
      // Iterate through each row
      squaresDataFacing.forEach((rowData) => {
        let rowArray: { top: string; bottom: string }[] = [];
  
        // Iterate through each duplexType in the row
        rowData.duplexTypes.forEach((duplexType) => {
          // Iterate through each boxIdx (index for each image pair)
          for (let boxIdx = 0; boxIdx < duplexType.value; boxIdx++) {
            // Extract imageTopSecond and imageBottomSecond
            const imageTopSecond =duplexType.images?.[boxIdx * 2] &&  skuImages[parseInt(duplexType.images[boxIdx * 2])] ? skuImages[parseInt(duplexType.images[boxIdx * 2])].packImage : null;
            const imageBottomSecond = duplexType.images?.[boxIdx * 2 + 1] && skuImages[parseInt(duplexType.images[boxIdx * 2 + 1])] ? skuImages[parseInt(duplexType.images[boxIdx * 2 + 1])].packImage : null;
  
            // Create an object for the image pair
            const imageObject = {
              top: imageTopSecond || "",
              bottom: imageBottomSecond || "",
            };
  
            // Only add the object if either top or bottom is not empty
            if (imageObject.top || imageObject.bottom) {
              rowArray.push(imageObject);
            }
          }
        });
  
        // Add the row to the new array if it has valid objects
        if (rowArray.length > 0) {
          newArray.push(rowArray);
        }
      });
  
      console.log("Filtered Image Array with Objects: ", newArray);
      return newArray;
    };
  
    const newImageArray = createImageArray();
    // Use the newImageArray for further processing or rendering
  }, [squaresDataFacing, skuImages]);
  const [squaresDataWithCC, setSquaresDataWithCC] = useState<[][]>([[]]);
  useEffect(() => {
    console.log('roccSpacew',ccSpace)
    const clonesquaresData = createRowsWithSingleTrue(row, ccSpace);
    setSquaresDataWithCC(clonesquaresData as any);
  
  
  }, [row, ccSpace,items,squaresData]);
  useEffect(()=>{
    let pink = pinkSquares.map(({width,height,x,y})=>{
      return [handleMouseUp((width*5),(height*5),x,y)]
   })
  
   let yellow =  yellowSquares.map(({width,height,x,y})=>{
    return [handleMouseUp((width*5),(height*5),x,y)]

   })
   console.log('yellowSquares',yellow)
   console.log('pink',combineArrays([...pink,...yellow]))
   if(pink.length > 0 ||yellow.length > 0  ){
    setOverLayResult(combineArrays([...pink,...yellow]))
  } else {
    setOverLayResult([squaresDataWithCC.map(val=>val.map(val2=>false))])
  }
  },[squaresDataWithCC,widths,squaresData,yellowSquares,pinkSquares,row,ccSpace])
  const createRowsWithSingleTrue = (rows: number, ccspace: any) => {
    const spacing = parseInt(ccspace)
  return Array.from({ length: rows }, (_, rowIndex) => {
    const row = Array(spacing).fill(true); // Start with all false
    return row;
  });
};
  const handleMouseUp = (width: number,height: number,x:number,y:number) => {
    // Constants
    const boxWidth = 30.5; // Fixed width of each box
    const boxHeight = 51.5;
  
  
  
    const selectionStartX = x;
    const selectionEndX = selectionStartX + (width-6.5)  ;
    console.log('selectionStartX',selectionStartX)
  
    const selectionStartY = y;
    console.log(selectionEndX)
    const selectionEndY = selectionStartY + height;
    const spacing = parseInt(ccSpace.toString())
    // Calculate column offsets dynamically based on actual column widths
    let columnOffsets = widths.reduce((acc:any, curr, idx) => {
      if (idx === 0) {
        acc.push(0);
      } else {
        acc.push(acc[idx - 1] + widths[idx - 1]);
      }
      return acc;
    }, []);
    let intersectingBoxCount = 0;
  let wasAlreadySelected = false;
  let indexRow:number = -1 
  let indexCol: number= -1
  let IndexBox :number= -1
  
    let updatedSelectedBoxes =   squaresDataWithCC.map((set, setIndex) => 
        set.map((isSelected:any, boxIndex:any) => {
          const columnOffset =  columnOffsets[0];
          const dynamicBoxCount =spacing; 
          
          // Calculate boxes per column dynamically
          //if (boxIndex >= dynamicBoxCount) return false;
           // Ignore boxes that exceed the dynamic count
  
          const boxStartX = columnOffset + (boxIndex * (widths[0]*5/dynamicBoxCount  )) +(((1.25 as number) * 5 || 2)* 0) ;
          const boxEndX = boxStartX + (widths[0]*5/dynamicBoxCount  );
          const boxTopY = (setIndex) * (boxHeight +((2 as number) * 5 )) ; // Adjust if your layout differs
          const boxBottomY = boxTopY + (boxHeight  );
  
          // Check if the box intersects with the selection area
          const intersectsSelection = !(
            boxEndX <= selectionStartX ||
            boxStartX >= selectionEndX ||
            boxBottomY <= selectionStartY ||
            boxTopY >= selectionEndY
          );
          if (intersectsSelection ) {
            if(indexRow!=0 && indexCol!=setIndex && IndexBox!=boxIndex)
            {
              intersectingBoxCount++;
  
            }
            if (isSelected) wasAlreadySelected = true;
            indexRow=0
            indexCol=setIndex
            IndexBox=boxIndex
         
  
          }
  
  
          return  intersectsSelection;
        })
      )
    
   
    return updatedSelectedBoxes
  
  
  };
  const combineArrays = (arrays: Array3D[]): Array3D => {
    if (arrays.length === 0) {
        // Handle the case where no arrays are provided
        return [];
    }
  
    // Find the maximum depth and rows across all arrays
    const maxDepth = arrays.reduce((acc, curr) => Math.max(acc, curr.length), 0);
    const maxRows = arrays.reduce((acc, curr) => Math.max(acc, curr.reduce((accRow, currRow) => Math.max(accRow, currRow.length), 0)), 0);
  
    // Initialize the result array with appropriate depth and rows but no columns yet
    const result: Array3D = Array.from({ length: maxDepth }, () =>
        Array.from({ length: maxRows }, () => [])
    );
  
    // Determine the maximum columns for each specific depth and row and fill with `false`
    for (let d = 0; d < maxDepth; d++) {
        for (let r = 0; r < maxRows; r++) {
            let maxCols = 0;
            // Find the maximum column size for the current depth and row across all arrays
            arrays.forEach(array => {
                if (array.length > d && array[d].length > r) {
                    maxCols = Math.max(maxCols, array[d][r].length);
                }
            });
            // Initialize columns for the current depth and row
            result[d][r] = Array.from({ length: maxCols }, () => false);
        }
    }
  
    // Iterate over every array and every element to apply the logical OR operation
    arrays.forEach(array => {
        for (let d = 0; d < array.length; d++) {
            for (let r = 0; r < array[d].length; r++) {
                for (let c = 0; c < array[d][r].length; c++) {
                    result[d][r][c] = result[d][r][c] || array[d][r][c];
                }
            }
        }
    });
  
    return result;
  };
const getTheOverlayedDuplex = (
  boolArray: boolean[],
  currentItems: {
    text: string;
    id: number;
    color: string;
    value: number;
    width: number;
  }[]
) => {
  console.log("currentItems ", currentItems);
  const displayItemsList = [];
  const activeItems = currentItems
    .filter((item) => item.value > 0)
    .map((item) => ({ ...item }));
  console.log("activeItems", activeItems);

  let activeItemIndex = 0; // Index in activeItems
  let boolIndex = 0; // Index in boolArray

  while (boolIndex < boolArray.length) {
    if (boolArray[boolIndex]) {
      // Fixed space
      displayItemsList.push({
        id: `fixed-${boolIndex}`,
        text: "Fixed Space",
        color: "gray",
        value: 1,
        width: 30.5,
        duplexTypes: [],
      });
      boolIndex++;
    } else {
      // Available space
      let start = boolIndex;
      let widthAvailable = 0;

      // Calculate the total available width dynamically
      while (boolIndex < boolArray.length && !boolArray[boolIndex]) {
        widthAvailable += 30.5; // Add width for each empty space
        boolIndex++;
      }

      // Try to fit active items within the available space
      while (activeItemIndex < activeItems.length) {
        const item = activeItems[activeItemIndex];
        const itemWidth = item.width;
        const totalItemWidth = itemWidth * item.value;

        if (totalItemWidth <= widthAvailable) {
          // Item fits completely
          displayItemsList.push({ ...item, id: item.id });
          widthAvailable -= totalItemWidth;
          activeItemIndex++;
        } else if (itemWidth <= widthAvailable) {
          // Item partially fits
          const maxUnits = Math.floor(widthAvailable / itemWidth);
          if (maxUnits > 0) {
            displayItemsList.push({
              ...item,
              value: maxUnits,
              id: item.id,
            });
            activeItems[activeItemIndex].value -= maxUnits;
            widthAvailable -= itemWidth * maxUnits;
          }
          break; // Move to next available space
        } else {
          // Item cannot fit in this space
          break; // Move to next available space
        }
      }

      // Dynamically calculate the width of the remaining empty space
      if (widthAvailable > 0) {
        displayItemsList.push({
          id: `empty-${start}`,
          text: "Empty Space",
          color: "white",
          value: 1,
          width: widthAvailable, // Use the dynamically calculated width
          duplexTypes: [],
        });
      }
    }
  }

  return displayItemsList;
};

  const getTheOverlayedDuplexFacings = (
    boolArray: boolean[],
    currentItems: {
      text: string;
      id: number;
      color: string;
      value: number;
      width: number;
    }[],
    currentItemcorrectValue: {
      text: string;
      id: number;
      color: string;
      value: number;
      width: number;
    }[]
  ) => {
    console.log("currentItems ", currentItems);
    const displayItemsList = [];
    const activeItems = currentItems
      .filter((item, index) => item.value > 0)
      .map((item, index) => ({ ...item, value: currentItemcorrectValue[index].value }));
    console.log("activeItems", activeItems);
  
    let activeItemIndex = 0; // Index in activeItems
    let boolIndex = 0; // Index in boolArray
  
    while (boolIndex < boolArray.length) {
      if (boolArray[boolIndex]) {
        // Fixed space
        displayItemsList.push({
          id: `fixed-${boolIndex}`,
          text: "Fixed Space",
          color: "gray",
          value: 1,
          width: 28.5,
          duplexTypes: [],
        });
        boolIndex++;
      } else {
        // Available space
        let start = boolIndex;
        let widthAvailable = 0;
  
        // Calculate the total available width dynamically
        while (boolIndex < boolArray.length && !boolArray[boolIndex]) {
          widthAvailable += 28.5; // Add the width of each empty space
          boolIndex++;
        }
  
        // Place active items within the available space
        while (activeItemIndex < activeItems.length) {
          const item = activeItems[activeItemIndex];
          const itemWidth = item.width;
          const totalItemWidth = itemWidth * item.value;
  
          if (totalItemWidth <= widthAvailable) {
            // Item fits completely
            displayItemsList.push({ ...item, id: item.id });
            widthAvailable -= totalItemWidth;
            activeItemIndex++;
          } else {
            // Item partially fits or doesn't fit
            const maxUnits = Math.floor(widthAvailable / itemWidth);
            if (maxUnits > 0) {
              displayItemsList.push({
                ...item,
                value: maxUnits,
                id: item.id,
              });
              activeItems[activeItemIndex].value -= maxUnits;
              widthAvailable -= itemWidth * maxUnits;
            }
            break; // Break to calculate the next available space
          }
        }
  
        // Fill remaining space dynamically as empty space
        if (widthAvailable > 0) {
          displayItemsList.push({
            id: `empty-${start}`,
            text: "Empty Space",
            color: "white",
            value: 1,
            width: widthAvailable, // Use the dynamically calculated width
            duplexTypes: [],
          });
        }
      }
    }
  
    return displayItemsList;
  };
  
  const renderSquares =  () => {
    return Array.from({ length: row }, (_, rowIndex) => (
      <div key={rowIndex} className={classes.flexContainer}>
        {Array.from({ length: col }, (_, colIndex) => {
          const squareData = squaresData.find((data) => data.row === rowIndex && data.col === colIndex);
          const squareDataFacing = squaresDataFacing.find((data) => data.row === rowIndex && data.col === colIndex);

          const isSelected = (duplexId: number, divIndex: number) => selectedSquare === `${rowIndex}-${colIndex}-${duplexId}-${divIndex}`;
          const OverLayedresult=     squareData &&(overLayResult && overLayResult?.length >0 ) &&  (overLayResult[0].length === row) && getTheOverlayedDuplex(overLayResult[0]?.[rowIndex] as any,squareData.duplexTypes)
          console.log(`'currentitemsNoFacings ${rowIndex}'`,squareData)

          console.log(`'currentitems ${rowIndex}'`,squareDataFacing)
          const OverLayedresultFacings=     squareData && squareDataFacing &&(overLayResult && overLayResult?.length >0 ) &&  (overLayResult[0].length === row) && getTheOverlayedDuplexFacings(overLayResult[0]?.[rowIndex] as any,squareDataFacing?.duplexTypes,squareData?.duplexTypes)
          let TotalEmptySpace = 0
          let numberofElements = 0
          OverLayedresult &&  OverLayedresult.forEach((value:any)=> {
            TotalEmptySpace = value.id.toString().split('-')[0] ==='empty' ? TotalEmptySpace + value.width : TotalEmptySpace
            numberofElements = value.id.toString().split('-')[0] ==='empty' ? numberofElements : numberofElements + value.value
          })
          console.log(`TotalEmptySpace ${rowIndex}`,TotalEmptySpace)
          console.log(`numberofElements ${rowIndex}`,TotalEmptySpace /numberofElements)

          return (
            <div
              key={`${rowIndex}-${colIndex}`}
              style={{ padding: rowIndex !=0 ? `4px 4px` : '0 4px' }}
            >
              <Paper
                className={classes.square}
                style={{
                  position: "relative",
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "flex-start",
                  padding: "2px",
                  width:(widths?.[0] || 50) * 5,

                  justifyContent: "start",
                  gap: "2px",
                }}
              >
                 <div className={classes.smallDivContainer}>
                  {renderSmallDivs(ccSpace,rowIndex )}
                </div>
                { squareData && OverLayedresult &&OverLayedresultFacings &&  OverLayedresult.map((item, index) => {
                  const duplex = OverLayedresult.find((d) => d.id === item.id) as any;

                  const duplexFacing = OverLayedresultFacings.find((d) => d.id === item.id) as any;
                  console.log(`duplexFacing ${rowIndex}`,duplexFacing)
                  
                  return duplex && duplexFacing ? (
                    <>
                      {[...Array(duplex.value)]?.map( (_, boxIdx) => {
                    
                        const imageTopSecond = skuImages[parseInt(duplexFacing?.images?.[boxIdx * 2])]?.packImage?.replace(/\s/g, '%20').replace(/\(/g, '%28').replace(/\)/g, '%29') ;
                        const imageBottomSecond = skuImages[parseInt(duplexFacing?.images?.[boxIdx * 2 + 1])]?.packImage?.replace(/\s/g, '%20').replace(/\(/g, '%28').replace(/\)/g, '%29') ;
                        const imageTopLayingSecond = skuImages[parseInt(duplexFacing?.images?.[boxIdx * 2])]?.layingPackImage?.replace(/\s/g, '%20').replace(/\(/g, '%28').replace(/\)/g, '%29') ;
                        const imageBottomLayingSecond  = skuImages[parseInt(duplexFacing?.images?.[boxIdx * 2 + 1])]?.layingPackImage?.replace(/\s/g, '%20').replace(/\(/g, '%28').replace(/\)/g, '%29') ;
                        const imageTopSecondName = (skuImages[parseInt(duplexFacing?.images?.[boxIdx * 2])] as any)?.name ;
                        const imageName =duplex?.images?.length > 0 ?( skuImages[parseInt(duplex?.images?.[boxIdx * 2])]as any)?.name ||imageTopSecondName : imageTopSecondName;

                        const imageTop =duplex?.images?.length > 0 ? skuImages[parseInt(duplex?.images?.[boxIdx * 2])]?.packImage?.replace(/\s/g, '%20').replace(/\(/g, '%28').replace(/\)/g, '%29') ||imageTopSecond : imageTopSecond;
                        const imageBottom = duplex?.images?.length > 0 ? skuImages[parseInt(duplex?.images?.[boxIdx * 2 + 1])]?.packImage?.replace(/\s/g, '%20').replace(/\(/g, '%28').replace(/\)/g, '%29') || imageBottomSecond:imageBottomSecond;
                        const imageTopLaying = skuImages[parseInt(duplex?.images?.[boxIdx * 2])]?.layingPackImage?.replace(/\s/g, '%20').replace(/\(/g, '%28').replace(/\)/g, '%29') || imageTopLayingSecond ;
                        const imageBottomLaying = skuImages[parseInt(duplex?.images?.[boxIdx * 2 + 1])]?.layingPackImage?.replace(/\s/g, '%20').replace(/\(/g, '%28').replace(/\)/g, '%29') || imageBottomLayingSecond ;
                        const imageBottomSecondName = (skuImages[parseInt(duplexFacing?.images?.[boxIdx * 2 +1])] as any)?.name ;

                        const imageBottomName =duplex?.images?.length > 0 ?( skuImages[parseInt(duplex?.images?.[boxIdx * 2 + 1])]as any)?.name ||imageBottomSecondName : imageBottomSecondName;

                        if(duplex.text ==='LEVIA'){
                          console.log('duplex test',imageTopSecond)
                        }
                        const renderTooltip = (content:any,name?:string) => (
                          <Tooltip
                            key={`type-${index}-${boxIdx}`}
                            title={ name=== undefined ? item.text: <div style={{ whiteSpace: 'pre-line' }}>
                            {name=== undefined ?'': name}
                          </div>}
                            arrow
                            placement="top"
                            PopperProps={{
                              modifiers: [
                                {
                                  name: "offset",
                                  options: {
                                    offset: [0, 10],
                                  },
                                },
                              ],
                            }}
                          >
                            {content}
                          </Tooltip>
                        );
                  
                        if (item.id === 4 || item.id === 12) {
                          return renderTooltip(
                            <div
                              key={`type-${index}-${boxIdx}`}
                              onClick={() => handleSquareClick(rowIndex, colIndex, parseInt(item.id.toString()), boxIdx * 2)}
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                height: "100%",
                                zIndex: 500,
                              }}
                            >
                              <div
                                className={isSelected(item.id, boxIdx * 2) ? classes.selectedSquare : ""}
                                style={{
                                  width: `${item.width}px`,

                                  height: "100%",
                                  marginTop: "2px",
                                  backgroundColor: 'transparent',
                                  backgroundImage: imageTop ? `url(${imageTop})` : "none",
                                  backgroundSize: "contain",
                                  backgroundPosition: 'center',
                                  backgroundRepeat: 'no-repeat',
                                  borderRadius: "3px",
                                  marginBottom: "2px",
                                  marginLeft: (TotalEmptySpace/numberofElements)/2,
                                  marginRight: (TotalEmptySpace/numberofElements)/2

                                }}
                              />
                            </div>
                          ,imageName);
                        }
                        if (item.id === 0 || item.id === 11) {
                          return renderTooltip(
                            <div
                              key={`type-${index}-${boxIdx}`}
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                height: "100%",
                                zIndex: 500,
                              }}
                            >
                              <div
                                onClick={() => handleSquareClick(rowIndex, colIndex,parseInt(item.id.toString()), boxIdx * 2)}
                                className={isSelected(item.id, boxIdx * 2) ? classes.selectedSquare : ""}
                                style={{
                                  width: `${item.width}px`,
                                  height: "100%",
                                  backgroundColor: items.find((item2)=>{return item.id === item2.id})?.color,
                                  backgroundImage: imageTop ? `url(${imageTop})` : "none",
                                  backgroundSize: "contain",
                                  backgroundPosition: 'center',
                                  backgroundRepeat: 'no-repeat',
                                  borderRadius: "3px",
                                  marginBottom: "2px",
                                  marginLeft: (TotalEmptySpace/numberofElements)/2,
                                  marginRight: (TotalEmptySpace/numberofElements)/2
                                }}
                              />
                            </div>
                          ,imageName);
                        }
                        if (item.id === 1) {
                          return renderTooltip(
                            <div
                              key={`type-${index}-${boxIdx}`}
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                height: "100%",
                                zIndex: 500,
                              }}
                            >
                              <div
                                onClick={() => handleSquareClick(rowIndex, colIndex, parseInt(item.id.toString()), boxIdx * 2 + 1)}
                                className={isSelected(item.id, boxIdx * 2 +1) ? classes.selectedSquare : ""}

                                style={{
                                  width: `${item.width}px`,
                                  height: "100%",
                                  display: 'flex',
                                  flexDirection: 'column',
                                  alignItems: 'center',
                                  justifyContent: 'space-between',
                                  boxSizing: 'border-box',
                                  padding: '2px',
                                  gap: '1px',
                                  borderRadius: "3px",
                                  border:   !isSelected(item.id, boxIdx * 2 +1) ? "1px solid" : "2px solid",
                                  borderColor: !isSelected(item.id, boxIdx * 2 +1) ? item.color : '#0179FF' ,
                                  marginLeft: (TotalEmptySpace/numberofElements)/2,
                                  marginRight: (TotalEmptySpace/numberofElements)/2
                                }}
                              >
                                <div
                                  style={{
                                    width: `${item.width - 4}px`,
                                    height: "24%",
                                    backgroundColor: imageBottom ? "transparent" : items.find((item2)=>{return item.id === item2.id})?.color,
                                    backgroundImage: imageBottom ? `url(${imageBottomLaying})` : "none",
                                    backgroundSize: "cover",
                                    backgroundPosition: 'center',
                                    borderRadius: "2px",
                                  }}
                                />
                                <div
                                  style={{
                                    width: `${item.width - 4}px`,
                                    height: "24%",
                                    backgroundColor: imageBottom ? "transparent" : items.find((item2)=>{return item.id === item2.id})?.color,
                                    backgroundImage: imageBottom ? `url(${imageBottomLaying})` : "none",
                                    backgroundSize: "cover",
                                    backgroundPosition: 'center',
                                                                        borderRadius: "2px",
                                  }}
                                />
                                <div
                                  style={{
                                    width: `${item.width - 4}px`,
                                    height: "24%",
                                    backgroundColor: imageBottom ? "transparent" : items.find((item2)=>{return item.id === item2.id})?.color,
                                    backgroundImage: imageBottom ? `url(${imageBottomLaying})` : "none",
                                    backgroundSize: "cover",
                                    backgroundPosition: 'center',
                                    borderRadius: "2px",
                                  }}
                                />
                                <div
                                  style={{
                                    width: `${item.width - 4}px`,
                                    height: "24%",
                                    backgroundColor: imageBottom ? "transparent" : items.find((item2)=>{return item.id === item2.id})?.color,
                                    backgroundImage: imageBottom ? `url(${imageBottomLaying})` : "none",
                                    backgroundSize: "cover",
                                    backgroundPosition: 'center',
                                    borderRadius: "2px",
                                  }}
                                />
                              </div>
                            </div>
                         ,imageBottomName );
                        }
                        if (item.id === 6) {
                          return renderTooltip(
                            <div
                              key={`type-${index}-${boxIdx}`}
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                height: "100%",
                                zIndex: 500,
                              }}
                            >
                              <div
                                style={{
                                  width: `${item.width}px`,
                                  height: "100%",
                                  display: 'flex',
                                  flexDirection: 'column',
                                  alignItems: 'center',
                                  justifyContent: 'space-between',
                                  boxSizing: 'border-box',
                                  padding: '2px',
                                  gap: '1px',
                                  borderRadius: "3px",
                                  border: "1px solid",
                                  borderColor: item.color,
                                  marginLeft: (TotalEmptySpace/numberofElements)/2,
                                  marginRight: (TotalEmptySpace/numberofElements)/2
                                }}
                              >

                                <div
                                  onClick={() => { handleSquareClick(rowIndex, colIndex, parseInt(item.id.toString()), boxIdx * 2) }}
                                  style={{
                                    width: `${item.width - 4}px`,
                                    backgroundColor: imageTop ? "transparent" : items.find((item2)=>{return item.id === item2.id})?.color,
                                    backgroundImage: imageTop ? `url(${imageTopLaying})` : "none",
                                    backgroundSize: "contain",
                                    height: "16%",
                                    borderRadius: "2px",
                                    border:   !isSelected(item.id, boxIdx * 2) ? "0px solid" : "1px solid",
                                    borderColor: !isSelected(item.id, boxIdx * 2 ) ? item.color : '#0179FF' ,
                                  
                                    
                                  }}
                                />
                                <div
                                  onClick={() => { handleSquareClick(rowIndex, colIndex,parseInt(item.id.toString()), boxIdx * 2) }}
                                  style={{
                                    width: `${item.width - 4}px`,
                                    height: "16%",
                                    backgroundColor: imageTop ? "transparent" : items.find((item2)=>{return item.id === item2.id})?.color,
                                    backgroundImage: imageTop ? `url(${imageTopLaying})` : "none",
                                    backgroundSize: "contain",
                                    borderRadius: "2px",
                                    border:   !isSelected(item.id, boxIdx * 2 ) ? "0px solid" : "1px solid",
                                    borderColor: !isSelected(item.id, boxIdx * 2 ) ? item.color : '#0179FF' ,
                                  }}
                                />
                                <div
                                  onClick={() => { handleSquareClick(rowIndex, colIndex, parseInt(item.id.toString()), boxIdx * 2) }}
                                  style={{
                                    width: `${item.width - 4}px`,
                                    height: "16%",
                                    backgroundColor: imageTop ? "transparent" : items.find((item2)=>{return item.id === item2.id})?.color,
                                    backgroundImage: imageTop ? `url(${imageTopLaying})` : "none",
                                    backgroundSize: "contain",
                                    marginBottom: '4px',
                                    borderRadius: "2px",
                                    border:   !isSelected(item.id, boxIdx * 2 ) ? "0px solid" : "1px solid",
                                    borderColor: !isSelected(item.id, boxIdx * 2 ) ? item.color : '#0179FF' ,
                                  }}
                                />
                                <div
                                  onClick={() => { handleSquareClick(rowIndex, colIndex, parseInt(item.id.toString()), boxIdx * 2 + 1) }}
                                  style={{
                                    width: `${item.width - 4}px`,
                                    height: "16%",
                                    backgroundColor: imageBottom ? "transparent" : items.find((item2)=>{return item.id === item2.id})?.color,
                                    backgroundImage: imageBottom ? `url(${imageBottomLaying})` : "none",
                                    backgroundSize: "contain",
                                    borderRadius: "2px",
                                    border:   !isSelected(item.id, boxIdx * 2 +1) ? "0px solid" : "1px solid",
                                    borderColor: !isSelected(item.id, boxIdx * 2 +1) ? item.color : '#0179FF' ,
                                  }}
                                />
                                <div
                                  onClick={() => { handleSquareClick(rowIndex, colIndex, parseInt(item.id.toString()), boxIdx * 2 + 1) }}
                                  style={{
                                    width: `${item.width - 4}px`,
                                    height: "16%",
                                    backgroundColor: imageBottom ? "transparent" : items.find((item2)=>{return item.id === item2.id})?.color,
                                    backgroundImage: imageBottom ? `url(${imageBottomLaying})` : "none",
                                    backgroundSize: "contain",
                                    borderRadius: "2px",
                                    border:   !isSelected(item.id, boxIdx * 2 +1) ? "0px solid" : "1px solid",
                                    borderColor: !isSelected(item.id, boxIdx * 2 +1) ? item.color : '#0179FF' ,
                                  }}
                                />
                                <div
                                  onClick={() => { handleSquareClick(rowIndex, colIndex, parseInt(item.id.toString()), boxIdx * 2 + 1) }}
                                  style={{
                                    width: `${item.width - 4}px`,
                                    height: "16%",
                                    backgroundColor: imageBottom ? "transparent" : items.find((item2)=>{return item.id === item2.id})?.color,
                                    backgroundImage: imageBottom ? `url(${imageBottomLaying})` : "none",
                                    backgroundSize: "contain",
                                    borderRadius: "2px",
                                    border:   !isSelected(item.id, boxIdx * 2 +1) ? "0px solid" : "1px solid",
                                    borderColor: !isSelected(item.id, boxIdx * 2 +1) ? item.color : '#0179FF' ,
                                  }}
                                />
                              </div>
                            </div>
                          ,`${imageName} \n ${imageBottomName}`);
                        }
                        if (item.id === 2) {
                          return renderTooltip(
                            <div
                              key={`type-${index}-${boxIdx}`}
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                height: "100%",
                                zIndex: 500,
                              }}
                            >
                              <div
                                style={{
                                  width: `${item.width}px`,
                                  marginLeft: (TotalEmptySpace/numberofElements)/2,
                                  marginRight: (TotalEmptySpace/numberofElements)/2,
                                  height: "100%",
                                  display: 'flex',
                                  flexDirection: 'column',
                                  alignItems: 'center',
                                  justifyContent: 'space-between',
                                  boxSizing: 'border-box',
                                  padding: '2px',
                                  gap: '1px',
                                  borderRadius: "3px",
                                  border: "1px solid",
                                  borderColor: item.color,
                                }}
                              >
                                <div
                                  onClick={() => handleSquareClick(rowIndex, colIndex,parseInt(item.id.toString()), boxIdx * 2)}
                                  style={{
                                    width: `${item.width - 4}px`,
                                    height: "50%",
                                    backgroundColor: items.find((item2)=>{return item.id === item2.id})?.color,
                                    backgroundImage: imageTop ? `url(${imageTop.replace(".png", ".png")})` : "none",
                                    backgroundSize: "contain",
                                    backgroundRepeat: "no-repeat",
                                    backgroundPosition: "center",
                                    marginBottom: '4px',
                                    borderRadius: "2px",
                                    border:   !isSelected(item.id, boxIdx * 2) ? "0px solid" : "1px solid",
                                    borderColor: !isSelected(item.id, boxIdx * 2 ) ? item.color : '#0179FF' ,
                                  }}
                                />
                                <div
                                  onClick={() => handleSquareClick(rowIndex, colIndex, parseInt(item.id.toString()), boxIdx * 2 + 1)}
                                  style={{
                                    width: `${item.width - 4}px`,
                                    height: "15%",
                                    backgroundColor: items.find((item2)=>{return item.id === item2.id})?.color,
                                    backgroundImage: imageBottom ? `url(${imageBottomLaying})` : "none",
                                    backgroundSize: "contain",
                                    backgroundRepeat: "no-repeat",
                                    backgroundPosition: "center",
                                    borderRadius: "2px",
                                    border:   !isSelected(item.id, boxIdx * 2+ 1) ? "0px solid" : "1px solid",
                                    borderColor: !isSelected(item.id, boxIdx * 2 + 1 ) ? item.color : '#0179FF' ,
                                  }}
                                />
                                <div
                                  onClick={() => handleSquareClick(rowIndex, colIndex, parseInt(item.id.toString()), boxIdx * 2 + 1)}
                                  style={{
                                    width: `${item.width - 4}px`,
                                    height: "15%",
                                    backgroundColor: items.find((item2)=>{return item.id === item2.id})?.color,
                                    backgroundImage: imageBottom ? `url(${imageBottomLaying})` : "none",
                                    backgroundSize: "contain",
                                    backgroundRepeat: "no-repeat",
                                    backgroundPosition: "center",
                                    borderRadius: "2px",
                                    border:   !isSelected(item.id, boxIdx * 2 + 1) ? "0px solid" : "1px solid",
                                    borderColor: !isSelected(item.id, boxIdx * 2 +1 ) ? item.color : '#0179FF' ,
                                  }}
                                />
                                <div
                                  onClick={() => handleSquareClick(rowIndex, colIndex,parseInt(item.id.toString()), boxIdx * 2 + 1)}
                                  style={{
                                    width: `${item.width - 4}px`,
                                    height: "15%",
                                    backgroundColor: items.find((item2)=>{return item.id === item2.id})?.color,
                                    backgroundImage: imageBottom ? `url(${imageBottomLaying})` : "none",
                                    backgroundSize: "contain",
                                    backgroundRepeat: "no-repeat",
                                    backgroundPosition: "center",
                                    borderRadius: "2px",
                                    border:   !isSelected(item.id, boxIdx * 2 + 1) ? "0px solid" : "1px solid",
                                    borderColor: !isSelected(item.id, boxIdx * 2 + 1) ? item.color : '#0179FF' ,
                                  }}
                                />
                              </div>
                            </div>
                          ,`${imageName} \n ${imageBottomName}`);
                        }
                        if(item.id.toString()?.split('-')[0] ==='empty') {
                          return <></>
                        }
                        return renderTooltip(
                          <div
                            key={`type-${index}-${boxIdx}`}
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              height: "100%",
                              zIndex:  item.id.toString()?.split('-')[0] ==='fixed' ? 22 : 500,

                            }}
                          >
                            <div
                              onClick={() => handleSquareClick(rowIndex, colIndex,parseInt(item.id.toString()), boxIdx * 2)}
                              className={isSelected(parseInt(item.id.toString()), boxIdx * 2) ? classes.selectedSquare : ""}
                              style={{
                                width: item.id.toString()?.split('-')[0] ==='empty' ?0 : `${item.width}px`,
                                height: "50%",
                                backgroundColor: item.color,
                                backgroundImage: imageTop ? `url(${imageTop})` : "none",
                                backgroundSize: "contain",
                                backgroundPosition: 'center',
                                backgroundRepeat: 'no-repeat',
                                borderRadius: "3px",
                                marginBottom: "2px",
                                marginLeft: (TotalEmptySpace/numberofElements)/2,
                                marginRight: (TotalEmptySpace/numberofElements)/2
                              }}
                            />
                            <div
                              onClick={() => handleSquareClick(rowIndex, colIndex, parseInt(item.id.toString()), boxIdx * 2 + 1)}
                              className={isSelected(parseInt(item.id.toString()), boxIdx * 2 + 1) ? classes.selectedSquare : ""}
                              style={{
                                width: item.id.toString()?.split('-')[0] ==='empty' ? 0 : `${item.width}px`,
                                 height: "50%",
                                backgroundColor: item.color,
                                backgroundImage: imageBottom ? `url(${imageBottom})` : "none",
                                backgroundSize: "contain",
                                backgroundPosition: 'center',
                                backgroundRepeat: 'no-repeat',
                                borderRadius: "3px",
                                marginLeft: (TotalEmptySpace/numberofElements)/2,
                                marginRight: (TotalEmptySpace/numberofElements)/2
                              }}
                            />
                          </div>
                        ,  (imageName  || imageBottomName) ? `${imageName} \n ${imageBottomName}` : undefined);
                      })}
                    </>
                  ) : null;
                  
                })}
              </Paper>
            </div>
          );
        })}
      </div>
    ));
  };

  const buttons = [
    {
      text: "HNB",
      className: `${classes.buttonLeft} disabled`,
      color: "",
      disabled: true,
    },
    {
      text: "Veev One",
      className: "disabled",
      color: "#9747FF",
      disabled: false,
    },
    { text: "Veev Now", className: "", color: "#FF9F47", disabled: true },
    {
      text: "Levia",
      className: `${classes.buttonRight} disabled`,
      color: "#5DD671",
      disabled: true,
    },
  ];

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        backgroundColor: "#F5F9FF",
        height:'100vh'
      }}
    >
       <div className={classes.buttonContainer}>
        {buttons.map((button) => (
          <Button
            key={button.text}
            className={`${classes.button} ${button.className} ${
             ""
            }`}
          //  onClick={() => !button.disabled && setSelectedButton(button.text)}
            disabled={true}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <div className={classes.buttonText}>{button.text}</div>
              {button.text == "HNB" ? (
                <div style={{ display: "flex", gap: "2px" }}>
                  <div
                    className={classes.buttonDot}
                    style={{ backgroundColor: "#0179FF" }}
                  ></div>
                  <div
                    className={classes.buttonDot}
                    style={{ backgroundColor: "#F95252" }}
                  ></div>
                  <div
                    className={classes.buttonDot}
                    style={{ backgroundColor: "#FFE455" }}
                  ></div>
                  <div
                    className={classes.buttonDot}
                    style={{ backgroundColor: "#A5ABAA" }}
                  ></div>
                </div>
              ) : (
                <div
                  className={classes.buttonDot}
                  style={{ backgroundColor: button.color }}
                ></div>
              )}
            </div>
          </Button>
        ))}
      </div>
      <div
        style={{
          backgroundColor: "#F5F9FF",
          height: "100vh",
          display: "flex",
          width: (widths?.[0] || 50) * 5,
          padding: "0 10px",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "start",
        }}
      >
        <div
          style={{
            alignSelf: "center",
            fontSize: "32px",
            color: "#9CA0AB",
            fontWeight: 800,
            marginBottom: "20px",
          }}
        >
          
        </div>
        <div
          style={{
            display: "flex",
            width: "100%",
            gap: (getFromJson<number>("rowDistance") as number) * 5 || 2,
            justifyContent: "center",
            alignItems: "center",
            position: "relative",
          }}
          ref={containerRef}
        >
          <div style={{ position: "relative" }}>
            {renderSquares()}
            {pinkSquares.map((square, index) => (
              <div
                key={index}
                className={classes.pinkSquare}
                style={{
                  width: square.width * 5,
                  height: square.height * 5,
                  left: square.x,
                  top: square.y,
                  position: "absolute",
                }}
              />
            ))}
            {yellowSquares.map((square, index) => (
              <div
                key={index}
                className={classes.yellowSquare}
                style={{
                  width: square.width * 5,
                  height: square.height * 5,
                  left: square.x,
                  top: square.y,
                  position: "absolute",
                }}
              >
                        <img  src={(square as any).image} width={square.width * 5} height={square.height * 5} style={{objectFit:'contain'}}>

</img>
                 </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default GridDemo;


function hexToHexWithOpacity(hex: any, opacity: any) {
  // Remove the hash at the start if it's there
  // Remove the hash at the start if it's there
  hex = hex.replace(/^#/, "");

  // Parse the r, g, b values
  let r = parseInt(hex.substring(0, 2), 16);
  let g = parseInt(hex.substring(2, 4), 16);
  let b = parseInt(hex.substring(4, 6), 16);

  // Blend with white (255, 255, 255)
  r = Math.round(r + (255 - r) * opacity);
  g = Math.round(g + (255 - g) * opacity);
  b = Math.round(b + (255 - b) * opacity);

  // Convert to hex and pad with zeroes if necessary
  const blendedHex = `#${((1 << 24) + (r << 16) + (g << 8) + b)
    .toString(16)
    .slice(1)
    .toUpperCase()}`;

  return blendedHex;
}


