export const processUserRoles = (roles: string[]) => {
    let userType: string = "all";
    const keyAccounts: string[] = [];
  
    roles.forEach((role) => {
      if (role.includes("Admin")) {
        userType = "admin";
      } else if (role.includes("Manager")) {
        if (userType !== "admin") userType = "manager";
      } else if (role.includes("User")) {
        if (userType !== "admin" && userType !== "manager") userType = "user";
  
        // Extract key account from role string (assuming last part after SKUser is the key account)
        const keyAccountMatch = role.match(/SKUser([A-Z]+)PRDIGA/);
        if (keyAccountMatch && keyAccountMatch[1]) {
          keyAccounts.push(keyAccountMatch[1]);
        }
       // keyAccounts.push('Individual Store')
      }
    });
  
  
    return { userType, keyAccounts };
  };