import makeStyles from "@mui/styles/makeStyles";
export const useStyles = makeStyles(
  (theme) => ({
    container: {
      display: "flex",
      width: "100%",
      height: "100%",
      backgroundColor: "white",
    },
    sidebarContent: {
      width: "40%",
      backgroundColor: "white",
      padding: "24px",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      boxShadow: theme.shadows[2],
    },
    content: {
      width: "60%",
      backgroundColor: "#F5F9FF",
      display: "flex",
      justifyContent: "flex-start", // Ensure content is aligned to the left
      alignItems: "center",
      flexDirection: "column", // Ensure content is stacked vertically
      padding: "20px", // Add some padding for spacing
    },
    contentTextContainer: {
      textAlign: "center",
      display: "flex",
      flexDirection: "column", // Ensure texts and button are stacked vertically
      alignItems: "center",
      marginTop: "30%",
      // Center items horizontally
    },
    mainText: {
      fontSize: "14px",
      fontStyle: "normal",
      fontWeight: 600,
      color: "black",
    },
    subText: {
      fontSize: "12px",
      fontStyle: "normal",
      fontWeight: 400,
      color: "var(--Zen-Sub-Text, #9CA0AB)",
      marginTop: "10px",
    },
    logo: {
      maxWidth: "100%",
      maxHeight: "44px",
      marginBottom: theme.spacing(2),
      marginTop: "40px",
    '&:hover' : {
cursor: 'pointer'
}
    },
    buttonContainer: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
      height: "100%",
      marginBottom: "10px",
      width: "100%",
    },
    iconButton: {
      width: 32,
      height: 32,
      borderRadius: "50%",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      background: "rgba(217, 231, 248, 0.50)",
      color: "#0179FF",
    },
    assignedContent: {
      textAlign: "center",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      marginTop: "10px",
      width: "100%",
    },
    loaderContainer: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      height: "100%",
      position: "absolute",
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: "rgba(255, 255, 255, 0.7)", // Semi-transparent background
      zIndex: 1000, // Ensure loader is above other content
    },
  }),
  { name: "SideBarLeftStyles" }
);
