import React, { useCallback, useEffect, useState } from "react";
import Typography from "@mui/material/Typography";
import CircularProgress from "@mui/material/CircularProgress";
import { useNavigate, useParams } from "react-router-dom";

import MeasurementInput from "../InputV2/InputV2";
import StoreCardsList from "../StoreCardList/StoreCardList";
import ChainCard from "../ChainCard/ChainCard";
import {
  getAllChannels,
  getAllKeyAccounts,
  getKeyAccountById,
  getPosUniversesByKeyAccountId,
  getShelfConfigsByKeyAccountId,
  searchPosUniversByKeyAccount,
} from "../../../services/posuniversService";
import SideBarRight from "../../POSUniverse/AddSkuModal/SideBarRight";
import { useStyles } from "./ContentStyles";
import { channel } from "diagnostics_channel";
import Pagination from "@mui/material/Pagination";
import { debounce } from "lodash";

interface ContentComponentProps {
  title: string;
  subtitle: string;
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

type StoreType = {
  name: string;
  posId: string;
  address: string;
  region: string;
  visibility: boolean;
};

type PlanogramType = {
  id: number;
  name: string;
};

const ContentComponent: React.FC<ContentComponentProps> = ({
  title,
  subtitle,
  isOpen,
  setIsOpen,
}) => {
  const classes = useStyles();
  const { keyAccountId } = useParams<{ keyAccountId: string }>();
  const [stores, setStores] = useState<StoreType[]>([]);
  const [planograms, setPlanograms] = useState<PlanogramType[]>([]);
  const [chainName, setChainName] = useState<string>("");
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [selectedId, setSelectedId] = useState<number | null>(null);
  const [loadingStores, setLoadingStores] = useState<boolean>(true);
  const [loadingPlanograms, setLoadingPlanograms] = useState<boolean>(true);
  const [loadingKeyAccount, setLoadingKeyAccount] = useState<boolean>(true);
  const [keyAccounts, setKeyAccounts] = useState<any[]>([]);
  const [channels, setChannels] = useState<{ id: number; name: string }[]>([]);

   // Pagination State
   const [page, setPage] = useState<number>(1);
   const [limit, setLimit] = useState<number>(8);
   const [totalPages, setTotalPages] = useState<number>(1);
   const [totalResults, setTotalResults] = useState<number>(0);
  const navigate = useNavigate();

  const fetchPosUniverses = async () => {
    setLoadingStores(true);
    try {
      const response =
      keyAccountId &&
      (await getPosUniversesByKeyAccountId(parseInt(keyAccountId, 10), page, limit));    
        if (response) {
          setStores(response.data);
          setTotalPages(response.pagination.totalPages);
          setTotalResults(response.pagination.total);
        }
    } catch (error) {
      console.error("Error fetching POS Universes:", error);
    } finally {
      setLoadingStores(false);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoadingKeyAccount(true);
        const [keyAccounts, chnnels] = await Promise.all([
          getAllKeyAccounts(),
          getAllChannels(),
        ]);
        setKeyAccounts(keyAccounts);
        setChannels(chnnels);
      } catch (error) {
        console.error("Error fetching data", error);
      } finally {
        setLoadingKeyAccount(false);
      }
    };

    const fetchShelfConfigs = async () => {
      setLoadingPlanograms(true);
      try {
        const shelfConfigs =
          keyAccountId &&
          (await getShelfConfigsByKeyAccountId(parseInt(keyAccountId, 10)));
        setPlanograms(shelfConfigs);
      } catch (error) {
        console.error("Error fetching Shelf Configurations:", error);
      } finally {
        setLoadingPlanograms(false);
      }
    };

    const fetchKeyAccount = async () => {
      setLoadingKeyAccount(true);
      try {
        const keyAccount =
          keyAccountId && (await getKeyAccountById(parseInt(keyAccountId, 10)));
        setChainName(keyAccount.name);
      } catch (error) {
        console.error("Error fetching Key Account:", error);
      } finally {
        setLoadingKeyAccount(false);
      }
    };
    fetchData();
    fetchPosUniverses();
    fetchShelfConfigs();
    fetchKeyAccount();
  }, [keyAccountId,page,limit]);

    // Debounced Search Function
    const handleSearchDebounced = useCallback(
      debounce(async (value: string) => {
        if (!keyAccountId) return;
  
        try {
          setLoadingStores(true);
          const searchResults = await searchPosUniversByKeyAccount(parseInt(keyAccountId, 10), value);
          setStores(searchResults);
        } catch (error) {
          console.error("Error searching POS Universes:", error);
        } finally {
          setLoadingStores(false);
        }
      }, 300), // Adjust the debounce delay as needed (e.g., 300ms)
      [keyAccountId]
    );
  
    const handleSearch = (value: string) => {
      setSearchTerm(value);
      if (value.trim() === "") {
        fetchPosUniverses(); // Reset to default if search term is empty
      } else {
        handleSearchDebounced(value); // Call debounced function
      }
    };
  
    useEffect(() => {
      fetchPosUniverses();
      return () => {
        handleSearchDebounced.cancel(); // Clean up debounce on unmount
      };
    }, [keyAccountId, page, limit]);

  const handleOpenSidebar = (id: number | null = null) => {
    setSelectedId(id);
    setIsOpen(true);
  };
  const handlePageChange = (event: React.ChangeEvent<unknown>, newPage: number) => {
    setPage(newPage);
  };
  const [isExporting, setIsExporting] = useState(false);

  return (
    <div className={classes.root}>
      {!isExporting && <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          width: "100%",
          alignItems: "center",
        }}
      >
 {     isOpen &&  <SideBarRight
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          id={selectedId}
          channels={channels}
          keyaccounts={keyAccounts}
        />}
        { !isExporting &&<div>
          <Typography> My POS Universe</Typography>
          <Typography variant="h2" sx={{maxWidth:'450px'}}>
            In this section, you'll find all the POS locations related to a
            specific key account, as well as their associated planograms.
          </Typography>
        </div>}
        <MeasurementInput
          width={280}
          unit=""
          value={searchTerm}
          onValueChange={handleSearch}
          onUnitChange={() => {}}
          label=""
          type="text"
        />
      </div>}
      <div style={{ width: "100%" }}>
        {loadingKeyAccount || loadingStores ? (
          <CircularProgress />
        ) : (
          <>
            <ChainCard
              chainName={chainName}
              numberOfStores={totalResults}
              planograms={planograms}
              isExporting={isExporting}
              setIsExporting={setIsExporting}
            />
           {!isExporting && <>  <StoreCardsList
              stores={stores as any}
              onEdit={(id) => {
                handleOpenSidebar(id);
              }}
            />
            {searchTerm === '' &&  <div style={{ marginTop: "20px", display: "flex", justifyContent: "center" }}>
              <Pagination
                count={totalPages}
                page={page}
                onChange={handlePageChange}
                color="primary"
              />
            </div>} </>}
          </>
        )}
      </div>
    </div>
  );
};

export default ContentComponent;
