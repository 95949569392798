import React, { createContext, useContext, useEffect, useState } from "react";
import { PublicClientApplication, AuthenticationResult } from "@azure/msal-browser";
import { msalConfig, loginRequest } from "../authConfig";

const msalInstance = new PublicClientApplication(msalConfig);

interface AuthContextType {
  token: string | null;
  isAuthenticated: boolean;
  login: () => Promise<void>;
  logout: () => void;
}

const AuthContext = createContext<AuthContextType | undefined>(undefined);

export const AuthProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [token, setToken] = useState<string | null>(null);
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);

  useEffect(() => {
    const checkAccount = async () => {
      const accounts = msalInstance.getAllAccounts();
      if (accounts.length > 0) {
        try {
          const response: AuthenticationResult = await msalInstance.acquireTokenSilent({
            ...loginRequest,
            account: accounts[0],
          });
          setToken(response.accessToken);
          setIsAuthenticated(true);
        } catch (error) {
          console.error("Silent token acquisition failed:", error);
        }
      }
    };

    checkAccount();
  }, []);
  useEffect(() => {
    const checkAndRefreshToken = async () => {
      const accounts = msalInstance.getAllAccounts();
      if (accounts.length > 0) {
        try {
          const response: AuthenticationResult = await msalInstance.acquireTokenSilent({
            ...loginRequest,
            account: accounts[0],
          });
          setToken(response.accessToken);
          setIsAuthenticated(true);
  
          // Schedule the next token refresh before expiry
          const expiresIn = response.expiresOn ? response.expiresOn.getTime() - Date.now() - 60000 : 300000; 
          setTimeout(checkAndRefreshToken, expiresIn); // Refresh 1 minute before expiration
        } catch (error) {
          console.error("Silent token acquisition failed:", error);
          
          setIsAuthenticated(false);
        }
      }
    };
  
    checkAndRefreshToken();
  }, []);
  useEffect(() => {
    const checkTokenExpiration = () => {
      const storedToken = sessionStorage.getItem("authToken");
  
      if (storedToken) {
        try {
          // Decode the token payload
          const decodedToken = JSON.parse(atob(storedToken.split('.')[1]));
          const currentTime = Math.floor(Date.now() / 1000);
  
          if (decodedToken.exp < currentTime) {
            console.warn("Token expired, clearing session and redirecting...");
            sessionStorage.removeItem("authToken");
            msalInstance.clearCache();
            setToken(null);
            setIsAuthenticated(false);
            window.location.href = "/login";  // Redirect to login page
          }
        } catch (error) {
          console.error("Error decoding token", error);
          sessionStorage.removeItem("authToken");
          setToken(null);
          setIsAuthenticated(false);
          window.location.href = "/login";
        }
      }
    };
  
    // Run the token check immediately and every 1 minute
    checkTokenExpiration();
    const interval = setInterval(checkTokenExpiration, 60000);
  
    return () => clearInterval(interval);
  }, []);
  const login = async () => {
    try {
      const response = await msalInstance.loginPopup(loginRequest);
      setToken(response.accessToken);
      setIsAuthenticated(true);
    } catch (error) {
      console.error("Login failed:", error);
    }
  };

  const logout = () => {
    msalInstance.logoutPopup();
    setToken(null);
    setIsAuthenticated(false);
  };

  return (
    <AuthContext.Provider value={{ token, isAuthenticated, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};

// Custom hook to use Auth Context
export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error("useAuth must be used within an AuthProvider");
  }
  return context;
};
