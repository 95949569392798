import React, { useState } from "react";
import { Box, Button, IconButton, Snackbar, CircularProgress } from "@mui/material";
import Logo from "../../../assets/images/Logo1.png";
import { ReactComponent as Back } from "../../../assets/icons/Back.svg";
import { useStyles } from "./SideBarStyles";
import Input from "../Input/Input";
import InputV2 from "../InputV2/Input";

import { ReactComponent as Col } from "../../../assets/icons/Col.svg";
import { ReactComponent as Row } from "../../../assets/icons/Row.svg";
import { ReactComponent as DrawerDistance } from "../../../assets/icons/DrawerDistance.svg";
import { ReactComponent as ColDistance } from "../../../assets/icons/ColDistance.svg";
import { useNavigate } from 'react-router-dom';
import Typography from "@mui/material/Typography";
import { createShelfConfig, updateShelfConfig } from "../../../services/shelfConfigurationService";
import MuiAlert, { AlertProps } from '@mui/material/Alert';

interface SidebarProps {
  id?: string;
  onColChange: (value: number) => void;
  onRowChange: (value: number) => void;
  onRowDistanceChange: (value: number) => void;
  onColDistanceChange: (value: number) => void;
  colDistance: number;
  rowDistance: number;
  height: number;
  row: number;
  col: number;
  hiddencells: boolean[][];
}

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const Sidebar: React.FC<SidebarProps> = ({
  id, onColChange, onRowChange, row, col, onRowDistanceChange, rowDistance, colDistance, onColDistanceChange, height,hiddencells, 
}) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState<"success" | "error">("success");

  const handleSave = async () => {
    setLoading(true);
    const shelfConfig = {
      col_position: col,
      row_position: row,
      rowDistance,
      colDistance,
      height,
      hiddencells,
    };

    try {
      if (id) {
        await updateShelfConfig(id, shelfConfig);
        setSnackbarMessage("Shelf configuration updated successfully!");
        setSnackbarSeverity("success");
        navigate(`/step11/${id}`);
      } else {
        const response = await createShelfConfig(shelfConfig);
        const { data } = response; // Assuming the response includes the created ID
        setSnackbarMessage("Shelf configuration created successfully!");
        setSnackbarSeverity("success");
        navigate(`/step11/${data.id}`); // Navigate to the next step with the created ID
      }
    } catch (error) {
      console.error('Error saving shelf configuration', error);
      setSnackbarMessage("Error saving shelf configuration");
      setSnackbarSeverity("error");
    } finally {
      setLoading(false);
      setSnackbarOpen(true);
    }
  };

  const handleSnackbarClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbarOpen(false);
  };

  return (
    <Box className={classes.container}>
      <img src={Logo} alt="Logo"  onClick={()=>navigate('/')} className={classes.logo} />
      <div className={classes.buttonContainer}>
        <div style={{ display: "flex", flexDirection: "column" }}>
          <Typography variant="body2" sx={{ marginBottom: "12px" }}>
            STEP 1/10
          </Typography>
          <Typography variant="h4" sx={{ marginBottom: "12px" }}>
            New Shelf Composition
          </Typography>
          <Typography variant="h6" sx={{ marginBottom: "50px" }}>
            Add a new shelf composition
          </Typography>
          <InputV2
            onChange={onColChange}
            value={col}
            icon={<Col />}
            max={4}
            min={1}
            text={"Columns per drawer"}
          ></InputV2>
          <InputV2
            onChange={onRowChange}
            value={row}
            icon={<Row />}
            max={12}
            min={2}
            text={"Drawers"}
          ></InputV2>
          <Input
            onChange={onRowDistanceChange}
            value={rowDistance}
            icon={<DrawerDistance />}
            text={"Drawers Distance"}
            type={2}
          ></Input>
          <Input
            onChange={onColDistanceChange}
            value={colDistance}
            icon={<ColDistance />}
            text={"Column Distance"}
            type={2}
          ></Input>
        </div>
        <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
          <IconButton onClick={() => { navigate('/') }}>
            <Box component="span" className={classes.iconButton}>
              <Back />
            </Box>
          </IconButton>
          <Button
            variant="contained"
            color="info"
            onClick={handleSave}
            sx={{ marginTop: "0px", position: "relative" }}
            disabled={loading}
          >
            {loading && <CircularProgress size={24} sx={{ position: "absolute" }} />}
            {!loading && "Next"}
          </Button>
        </div>
      </div>
      <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={handleSnackbarClose}>
        <Alert onClose={handleSnackbarClose} severity={snackbarSeverity}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default Sidebar;
