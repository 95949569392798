import React, { useState } from "react";
import StoreIcon from "../../../assets/icons/KeyAccount.svg";
import Back from "../../../assets/icons/Back2.svg";
import Settings from "../../../assets/icons/settings.svg";
import MeasurementInput from "../InputV2/InputV2";
import DownloadIcon from "@mui/icons-material/ArrowDownward";
import { ReactComponent as Download } from "../../../assets/icons/Download.svg";
import BrandModalOptions from "../ModalComponentOptions/Modal";
import { format } from 'date-fns';
import ReactDOM from 'react-dom/client'; // Add this import at the top of your file

import { Alert, Box, Button, LinearProgress, Snackbar, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { exportToExcelForBluk, handleExportToPNG, insertBase64ImageIntoExcelBlob, mergeExcelBlobs } from "../../Step19 copy 2/Content/utils/exportFunctions";
import ShelfPreview from "../../Step19 copy 2/ShelfPreview";
import { getShelfConfigByPosId } from "../../../services/posuniversService";
import { getShelfConfigById } from "../../../services/shelfConfigurationService";

// Define the types for the planogram and the component props
type Planogram = {
  id: number;
  name: string;
};

type ChainCardProps = {
  chainName: string;
  numberOfStores: number;
  planograms: Planogram[];
  isExporting: boolean;
  setIsExporting: React.Dispatch<React.SetStateAction<boolean>>
};

// The ChainCard component
const ChainCard: React.FC<ChainCardProps> = ({
  chainName,
  numberOfStores,
  planograms,
  isExporting,
  setIsExporting
}) => {
  // Inline styles
  const cardStyle: React.CSSProperties = {
    borderRadius: "10px",
    padding: "16px",
    marginBottom: "12px",
    backgroundColor: "white",
    marginTop:"24px"
  };
  const conStyle: React.CSSProperties = {width:'100%',height:'100%',display:'flex',flexDirection:'column',justifyContent:'center',alignItems:'center'}

  const titleStyle: React.CSSProperties = {
    fontSize: "24px",
    color: "#333",
  };

  const subtitleStyle: React.CSSProperties = {
    fontSize: "18px",
    color: "#666",
  };

  const planogramListStyle: React.CSSProperties = {
    listStyleType: "none",
    paddingLeft: "0",
  };
const navigate = useNavigate()
  const planogramItemStyle: React.CSSProperties = {
    padding: "10px ",
    background: "#F5F9FF",
    borderRadius: "6px",
    fontSize: "12px",
    fontWeight: "500",
    color: "#0179FF",
  };
  const rowStyle = {
    marginBottom: "5px",
  };
  const [openModaOptionsl, setOpenModalOptions] = useState(false);
  const [currentPlanogramIndex, setCurrentPlanogramIndex] = useState(0);

  const [exportOptions, setExportOptions] = useState({
    compositionName: false,
    eanCode: false,
    validityDate: false,
  });
  const handleSubmit = () => {
    console.log('exportOptions',exportOptions)
    console.log('exportOptions',planograms)


  }
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState<"success" | "error" | "info" | "warning">("info");

  const showSnackbar = (message: string, severity: "success" | "error" | "info" | "warning" = "info") => {
    setSnackbarMessage(message);
    setSnackbarSeverity(severity);
    setSnackbarOpen(true);
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };
  const hiddenContainerRef = React.useRef<HTMLDivElement>(null);
   const [loading,setLoading] = useState(false)
   const monitorNetworkRequests = () => {
    const originalFetch = window.fetch;
    const pendingRequests = new Set<Promise<any>>();

    window.fetch = async (...args) => {
      const requestPromise = originalFetch(...args);
      pendingRequests.add(requestPromise);

      try {
        const response = await requestPromise;
        return response;
      } finally {
        pendingRequests.delete(requestPromise);
      }
    };

    return async () => {
      // Wait until all pending requests are resolved
      while (pendingRequests.size > 0) {
        await Promise.race(pendingRequests);
      }
      // Restore the original fetch
      window.fetch = originalFetch;
    };
  };
  async function processPlanograms(planogramsT:any, exportOptions:any) {
    
     setIsExporting(true)
     const totalPlanograms = planogramsT.length;
      let currentIndex = 0
      const planograms = planogramsT
    const blobs = []; // Array to store the final blobs for each shelfConfig
    console.log('planograms',planograms)
    for (const shelfConfig of planograms) {
      try {
      
      
        let validFrom = null;
        let validTo = null;
        currentIndex++
        setCurrentPlanogramIndex(currentIndex);
        
        // Adjust validFrom and validTo dates
        if (shelfConfig.validFrom) {
            const validFromDate = new Date(shelfConfig.validFrom);
            validFromDate.setMinutes(validFromDate.getMinutes() + validFromDate.getTimezoneOffset());
            validFrom = validFromDate;
        }

        if (shelfConfig.validTo) {
            const validToDate = new Date(shelfConfig.validTo);
            validToDate.setMinutes(validToDate.getMinutes() + validToDate.getTimezoneOffset());
            validTo = validToDate;
        }

        // Format the dates
        const formattedValidFrom = validFrom
            ? format(new Date(validFrom), 'dd-MM-yyyy') === '01-01-1970'
                ? 'N/A'
                : format(new Date(validFrom), 'dd-MM-yyyy')
            : 'N/A';

        const formattedValidTo = validTo
            ? format(new Date(validTo), 'dd-MM-yyyy') === '01-01-1970'
                ? 'N/A'
                : format(new Date(validTo), 'dd-MM-yyyy')
            : 'N/A';

        // Export to Excel
        console.log('starting export function bulk ',currentIndex)
        const blob = await exportToExcelForBluk(shelfConfig, exportOptions, shelfConfig.id.toString());
        console.log('ending  export function bulk',currentIndex)

        const containerId = `planogram-container-${shelfConfig.id}`;
        console.log('bulkfunctiondone')
        const waitForNetworkRequests = monitorNetworkRequests();

      if (hiddenContainerRef.current) {
        
        hiddenContainerRef.current.innerHTML = ''; // Clear previous content
        const container = document.createElement('div');
      
        container.id = containerId;
        hiddenContainerRef.current.appendChild(container);

        // Render the planogram dynamically
        const root = ReactDOM.createRoot(container);
        await new Promise<void>((resolve) => {
          const observer = new MutationObserver((mutationsList) => {
            console.log("DOM mutation detected", mutationsList);
            observer.disconnect(); // Stop observing after detecting render
            resolve(); // Resolve the promise to continue processing
          });
  
          observer.observe(hiddenContainerRef.current!, {
            childList: true,
            subtree: true,
          });
  
          // Render the component
          root.render(<ShelfPreview isBulk id={shelfConfig.id.toString()} />);
        });
        setTimeout(() => {
          // Get the width of the ShelfPreview component directly
          const shelfPreviewElement = hiddenContainerRef.current?.querySelector(`[id="${shelfConfig.id.toString()}"]`) as HTMLElement;
      
          if (shelfPreviewElement) {
              const width = shelfPreviewElement.getBoundingClientRect().width;
              container.style.width = `${width}px`;
              console.log('Applied width from ShelfPreview component:', width);
          } else {
              console.warn('ShelfPreview component not found');
          }
      }, 100);

      }
      console.log('wating...')
      await waitForNetworkRequests();
      // Wait for rendering and then export PNG
      await new Promise((resolve) => setTimeout(resolve, 30000)); // En
        // Export PNG and insert into Excel blob
        const pngDataUrl = await handleExportToPNG(hiddenContainerRef,setLoading); // here we should parse the container of that spesefic id 
        console.log('PNG export done for:', shelfConfig.name);

        const finalBlob = pngDataUrl
            ? await insertBase64ImageIntoExcelBlob(
                  blob,
                  pngDataUrl,
                  shelfConfig.name,
                  `Valid From: ${formattedValidFrom} => Valid To: ${formattedValidTo}`,
                  shelfConfig.id,
                  shelfConfig.row_position 
              )
            : blob;

        // Add the final blob to the array
        blobs.push(finalBlob);

      } catch {
        console.log('shelf goes wrong')
        showSnackbar(`Something went wrong while processing a shelf ${shelfConfig?.name} .`, `warning`);


      }
    }

    // Merge all final blobs into a single file
    const mergedBlob = await mergeExcelBlobs(blobs,planograms);
    const link = document.createElement('a');
    link.href = URL.createObjectURL(mergedBlob);
    link.download = `key-account-${chainName}.xlsx`
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    setIsExporting(false)
    // Handle the merged blob (e.g., save it or download it)
    console.log('All blobs processed and merged.');
    return mergedBlob;
}
 
  return (
    <div style={ isExporting ? {...cardStyle,...conStyle }:{...cardStyle }}>
        <BrandModalOptions
        open={openModaOptionsl}
        setExportOptions={setExportOptions}
        exportOptions={exportOptions}
        handleClose={() => setOpenModalOptions(false)}
        handleSubmit={()=>{   
          
          processPlanograms(planograms,exportOptions)
        
        }}
        label="Shelf Configuration"
        usedName={''}
      />
      {isExporting && (
        <div style={{ marginBottom: "16px" }}>
          <Typography variant="body2" style={{ marginBottom: "8px" }}>
            {currentPlanogramIndex  > planograms.length ?`Combining the planograms...` : ` Processing ${currentPlanogramIndex} out of ${planograms.length} planograms...` }
           
          </Typography>
          <LinearProgress
            variant="determinate"
            value={(currentPlanogramIndex / (planograms.length + 1)) * 100}
          />
        </div>
      )}
     {isExporting && <div
          ref={hiddenContainerRef}
          style={{
            display:'flex',
            justifyContent:'center',
            alignItems:'center',
            
            width:'fit-content',
          //  height:'100%'
          }}
        ></div>}
    { !isExporting && <>
    <div
  style={{
    display: "flex",
    alignItems: "center",
    gap: "14px",
    marginBottom: "12px",
    justifyContent: "space-between", // Pushes items to the edges
    width: "100%",
  }}
>

 {/* <div> This is the preview and where we should have the container id 
 <ShelfPreview id={thePlanoId.id.toString()} /> </div> */}

  <div style={{ display: "flex", alignItems: "center", gap: "14px" }}>
  <img
    src={Back}
    width={50}
    height={50}
    onClick={() => navigate('/pos')}
  ></img>
    <img src={StoreIcon} width={50} height={50}></img>
    <div>
      <div style={rowStyle}> {chainName}</div>
      <div style={rowStyle}> {numberOfStores}</div>
    </div>
  </div>

  <Button
      variant="outlined"
      onClick={()=>{setOpenModalOptions(true)}}
      startIcon={<Download />}
      sx={{
        width: "229px",
        height: "32px",
        padding: "10px",
        justifyContent: "center",
        alignItems: "center",
        gap: "2px",
        borderRadius: "20px",
        border: "1px solid",
        borderColor: "#0179FF",
        color: "#383A49 !important", // Text color
        fontSize: "12px", // Font size
        fontStyle: "normal", // Font style
        fontWeight: 500, // Font weight
        lineHeight: "normal", // Line height
        textTransform: "none", // Prevents uppercase text
        "&:hover": {
          opacity:0.8,
          backgroundColor: "white !important", // Light blue hover background
          borderColor: "#0179FF", // Retain border color on hover
        //  color: "white", // Change text color to blue on hover
          // "& .MuiButton-startIcon": {
          //   color: "white", // Change icon color on hover
          // },
        },
        // "& .MuiButton-startIcon": {
        //   color: "#383A49", // Default icon color
        // },
      }}
    >
      Download All {planograms.length} Planograms
    </Button>
</div>
      <div>
        <div style={{fontSize:"12px",fontWeight:"600",color:"#9CA0AB",textTransform:"uppercase",marginBottom:"8px",borderTop:"0.5px solid #E1E3E6", paddingTop:"12px",marginTop:"12px"}}>
        Planograms Included:

        </div>
        <div style={{ display: "flex", gap: "10px" }}>
          {planograms.slice(0, 10).map((planogram) => (
            <div  title={planogram.name} key={planogram.id} style={{...planogramItemStyle,   whiteSpace: 'nowrap',
              overflow: 'hidden',
              minWidth: '50px',
              textOverflow: 'ellipsis',}}>
              {planogram.name}
            </div>
          ))}
          <div  style={{...planogramItemStyle, display:planograms.length-10 > 0 ? `block`:"none"}}>
             
              {planograms.length-5 > 0 ? `+${planograms.length-10}`:""}
            </div>
        </div>
        {/* <MeasurementInput  width={280} unit="" value="" onValueChange={()=>{}} onUnitChange={()=>{}} label={""} ></MeasurementInput> */}

      </div>  </>}
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <Alert onClose={handleSnackbarClose} severity={snackbarSeverity}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default ChainCard;
