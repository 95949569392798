import React, { useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import Typography from "@mui/material/Typography";
import Input from "../Input/Input";

interface BrandModalProps {
  open: boolean;
  handleClose: () => void;
  label: string;
  onAdd: (name: string) => void; // Callback for adding a new key account
}

const BrandModal: React.FC<BrandModalProps> = ({
  open,
  handleClose,
  label,
  onAdd,
}) => {
  const [brandName, setBrandName] = useState("");

  const handleAddBrand = () => {
    onAdd(brandName); // Call the callback with the new key account name
    setBrandName(""); // Clear the input
    handleClose(); // Close the modal
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      sx={{
        "& .MuiDialog-paper": {
          borderRadius: "15px !important",
          paddingBottom: "0",
        },
      }}
    >
      <DialogTitle>
        <Typography variant="h4">Add New {label}</Typography>
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          <Typography
            variant="h6"
            sx={{ marginBottom: "12px", color: "#9CA0AB" }}
          >
            To add a new {label}, please enter the name of the brand you want to
            add.{" "}
          </Typography>
        </DialogContentText>
        <Input
          label={label}
          onChange={(e) => setBrandName(e)}
          placeholder={`Enter ${label}`}
          value={brandName}
          marginB={0}
        ></Input>
      </DialogContent>
      <DialogActions>
        <div style={{ display: "flex", width: "100%", gap: "8px" }}>
          <Button
            color="primary"
            variant="contained"
            onClick={handleClose}
            sx={{
              marginBottom: "10px !important",
              background: "rgba(217, 231, 248, 0.50)",
              justifyContent: "center",
              color: "#383A49",
              flexBasis: "33%",
              "&:hover": {
                color: "black",
              },
            }}
          >
            Cancel
          </Button>
          <Button
            color="primary"
            variant="contained"
            onClick={handleAddBrand}
            sx={{
              marginBottom: "10px !important",
              "background-color": "#0179FF",
              color: "white",
              flexGrow: 1,
              justifyContent: "center",
              "&:hover": {
                color: "white",
                backgroundColor: "#0179FF !important",
                opacity: 0.8,
              },
            }}
          >
            Add {label}
          </Button>
        </div>
      </DialogActions>
    </Dialog>
  );
};

export default BrandModal;
