import React, { useState } from "react";
import { Box, Button, IconButton } from "@mui/material";
import { Add as AddIcon } from "@mui/icons-material";
import Logo from "../../../../assets/images/Logo1.png";
import { ReactComponent as Back } from "../../../../assets/icons/Back.svg";
import { useStyles } from "./SideBarStyles";
import { useNavigate, useParams } from 'react-router-dom';
import Typography from "@mui/material/Typography";
import SearchInput from "../SearchInput/SearchInput";
import HNBProductList from "../HNBProductList/HNBProductList";
import SideBarRight from "../../../SKUList/AddSkuModal/SideBarRight";
import BrandModal from "../ModalComponent/Modal";
import FillOrder from "../FillOrder/FillOrder";
import ColoredMenuItem from "../ColorMenuItem/ColoredMenuItem ";

interface SidebarProps {

  
 
  onChange:(value:number)=>void
}

const Sidebar: React.FC<SidebarProps> = ({
 
 

  onChange,

}) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const { id, index } = useParams<{ id: string,index?:string }>();

  return (
    <Box className={classes.container}>
      <SideBarRight isOpen={isOpen} setIsOpen={setIsOpen}></SideBarRight>
      <BrandModal open={openModal} handleClose={() => { setOpenModal(false) }} label={"Configuration"} />
      <img src={Logo} alt="Logo"  onClick={()=>navigate('/')} className={classes.logo} />
      <div className={classes.buttonContainer}>
        <div style={{ display: "flex", flexDirection: "column" ,width:"100%"}}>
          <Typography variant="body2" sx={{ marginBottom: "12px" }}>
            STEP 6/7
          </Typography>
          <Typography variant="h4" sx={{ marginBottom: "12px" }}>
            New RRP Configuration
          </Typography>
          <Typography variant="h6" sx={{ marginBottom: "10px",maxWidth:'230px' }}>
          Add a special materials to RRP configuration
          </Typography>
          <ColoredMenuItem onChange={(value)=>onChange(value)} ></ColoredMenuItem>

          {/* <Button
            color="primary"
            variant="contained"
            onClick={()=>{setIsOpen(!isOpen)}}
            sx={{marginBottom:"10px !important","background-color":"#F2F8FF","color":"#0179FF",width:"100%",justifyContent:"center", height:"32px",marginTop:"12px !important",
            "&:hover": {
              color: "black",
            }
           }}
            startIcon={
              <Box component="span" className={classes.iconButton}>
                <AddIcon />
              </Box>
            }
          >
            Add 
          </Button> */}
        </div>
      
      

        <div style={{ display: "flex", alignItems: "center" }}>
          <IconButton onClick={() => {
              if(index ) {
                navigate('/rrp/step2/' + (id || '')+'/'+(index || ''))
  
              }else {
                navigate('/rrp/step2/' + (id || ''))
  
              }
            
            // if(index ) {
            //   navigate('/rrp/step4New/' + (id || '')+'/'+(index || ''))

            // }else {
            //   navigate('/rrp/step4New/' + (id || ''))

            // }
          
            
            }} size="large">
            <Box component="span" className={classes.iconButton2}>
              <Back />
            </Box>
          </IconButton>
          <Button variant="contained" color="info" onClick={() => {navigate("/rrp/step3/"+  (id || '')+'/'+(index || ''))}} sx={{marginTop:"0px"}}>
            Continue
          </Button>
        </div>
      </div>
    </Box>
  );
};

export default Sidebar;
