import { useMsal } from '@azure/msal-react';
import axios from 'axios';
const API_URL = process.env.REACT_APP_API_URL;

const axiosInstance = axios.create({
  baseURL: API_URL, // Your backend API
  headers: {
    'Content-Type': 'application/json',
  },
});

export const setupAxiosInterceptors = (setError: (message: string | null) => void) => {
  axiosInstance.interceptors.response.use(
    (response) => response,
    async (error) => {
      if (error.response?.status === 403) {
        setError('Access Denied: You do not have permission to access this resource.');
      }
      return Promise.reject(error);
    }
  );
};
// Use interceptors to dynamically attach token for each request
axiosInstance.interceptors.request.use(
    async (config) => {
      const token = sessionStorage.getItem("authToken");
  
      if (token) {
        const decodedToken = JSON.parse(atob(token.split('.')[1])); // Decode JWT
        const currentTime = Math.floor(Date.now() / 1000);
  
        if (decodedToken.exp < currentTime + 120) { // Refresh token 1 minute before expiry
          const { instance } = useMsal();
          try {
            const response = await instance.acquireTokenSilent({ scopes: ["openid", "profile", "email"] });
            sessionStorage.setItem("authToken", response.accessToken);
            config.headers.Authorization = `Bearer ${response.accessToken}`;
          } catch (error) {
            console.error("Token refresh failed", error);
            window.location.href = "/login";
          }
        } else {
          config.headers.Authorization = `Bearer ${token}`;
        }
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

export default axiosInstance;
