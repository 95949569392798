import React, { useState } from "react";
import { Avatar, Box, Button, Popover, Typography } from "@mui/material";
import { Add as AddIcon } from "@mui/icons-material";
import Logo from "../../../assets/images/Logo1.png";
import { ReactComponent as SKUS } from "../../../assets/icons/SKUS.svg";
import { ReactComponent as Stores } from "../../../assets/icons/Stores.svg";
import { ReactComponent as RRPIcon } from "../../../assets/icons/RRP.svg";

import { ReactComponent as Account } from "../../../assets/icons/Account.svg";
import { ReactComponent as Home } from "../../../assets/icons/Home.svg";
import { ReactComponent as Planogrames } from "../../../assets/icons/Planogrames.svg";
import { ReactComponent as SkuProi } from "../../../assets/icons/SkuProi.svg";
import LogoutIcon from '@mui/icons-material/Logout';


import { useStyles } from "./SideBarStyles";
import { useNavigate } from "react-router-dom";
import { useMsal } from "@azure/msal-react";
import { processUserRoles } from "../../helpers/roles";
interface SideBarProps {
  setIsOpen: (value: boolean) => void;
}
const Sidebar: React.FC<SideBarProps> = ({ setIsOpen }) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const { instance, accounts , inProgress} = useMsal();
  // Extract first and last initials from the account's name
  const fullName = accounts[0]?.name || "";
  const firstInitial = fullName.split(" ")[0]?.[0]?.toUpperCase() || "";
  const lastInitial = fullName.split(" ")[1]?.[0]?.toUpperCase() || "";
  const initials = `${firstInitial}${lastInitial}`;
  const userRoles = accounts[0]?.idTokenClaims?.roles || [];
  const currentRole =processUserRoles(userRoles)
   const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  
    const handlePopoverOpen = (event: React.MouseEvent<HTMLDivElement>) => {
      setAnchorEl(event.currentTarget);
    };
    
    const handlePopoverClose = () => {
      setAnchorEl(null);
    };
    
    const open = Boolean(anchorEl);
  return (
    <Box className={classes.container}>
      <img src={Logo} alt="Logo"  onClick={()=>navigate('/')} className={classes.logo} />
      <div className={classes.buttonContainer}>
        <div style={{ display: "flex", flexDirection: "column" }}>
          <Button
            color="primary"
            variant="contained"
            onClick={() => {
              setIsOpen(true);
            }}
            sx={{
              marginBottom: "10px !important",
              "background-color": "#0179FF",
              color: "white",
              width: "260px",
              justifyContent: "start",
              "&:hover": {
                color: "black", // Replace with your desired color
              },
            }}
            startIcon={
              <Box component="span" className={classes.iconButton}>
                <AddIcon />
              </Box>
            }
          >
            Add New Store
          </Button>
          <Button
            color="secondary"
            variant="contained"
            onClick={() => {
              navigate("/");
            }}
            startIcon={
              <Box component="span" className={classes.iconButton}>
                <Home />
              </Box>
            }
          >
            Home Page
          </Button>
          <Button
            color="secondary"
            variant="contained"
            onClick={() => {
              navigate("/skulist");
            }}
            startIcon={
              <Box component="span" className={classes.iconButton}>
                <SKUS />
              </Box>
            }
          >
            My SKUs
          </Button>
          <Button
            color="secondary"
            variant="contained"
            onClick={() => {
              navigate("/pos");
            }}
            sx={{fontWeight:"800"}}
            startIcon={
              <Box component="span" className={classes.iconButton}>
                <Stores  className={classes.svgIcon}  />
              </Box>
            }
          >
            My Stores
          </Button>
                      <Button
                        color="secondary"
                        variant="contained"
                        onClick={() => {
                          navigate("/sku-priority/3");
                        }}
                        startIcon={
                          <Box component="span" className={classes.iconButton}>
                            <RRPIcon />
                          </Box>
                        }
                      >
                        My RRP's
                      </Button>
                    
          {/* <Button
            color="secondary"
            variant="contained"
            onClick={() => {
              navigate("/");
            }}
            startIcon={
              <Box component="span" className={classes.iconButton}>
                <Planogrames />
              </Box>
            }
          >
            My Planograms
          </Button> */}
          {/* <Button
            color="secondary"
            variant="contained"
            startIcon={
              <Box component="span" className={classes.iconButton}>
                <Stores />
              </Box>
            }
          >
            My Stores
          </Button> */}
        </div>

    
        <div style={{ display: "flex", flexDirection: "column", marginTop: "20px" }}>
          {/* Display user initials */}
          {(
            <Button
              color="secondary"
              variant="contained"
              onClick={() => {
                navigate("/sku-priority");
              }}
              sx={{
                marginBottom: "30px",
              }}
              startIcon={
                <Box component="span" className={classes.iconButton}>
                  <SkuProi />
                </Box>
              }
            >
              <div
                style={{
                  color: "#383A49",
                  fontSize: "14px",
                  fontWeight: "600",
                  textAlign: "start",
                }}
              >
                <div
                  style={{
                    color: "#9CA0AB",
                    fontSize: "12px",
                    fontWeight: "600",
                  }}
                >
                  Allocation is done based upon
                </div>
                SKU Priority List
              </div>
            </Button>
          )}
                <div>
    <div
      style={{ display: "flex", alignItems: "center", marginBottom: "10px", cursor: "pointer" }}
      onClick={handlePopoverOpen}
    >
      <Avatar sx={{ bgcolor: "#0179FF", color: "#fff", marginRight: "10px" }}>
        {initials}
      </Avatar>
      <span style={{ fontSize: "14px", fontWeight: "600", color: "#383A49", textTransform: 'capitalize' }}>
        Logged in as {firstInitial}.{lastInitial} {currentRole.userType}.
      </span>
    </div>

    <Popover
      open={open}
      anchorEl={anchorEl}
      onClose={handlePopoverClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
    >
      <Box sx={{ p: 2, minWidth: 200 }}>
        <Typography variant="body1" fontWeight="600">User Details</Typography>
        <Typography variant="body2">Name: {fullName}</Typography>
        <Typography variant="body2" sx={{textTransform:'capitalize'}}>Role: {currentRole.userType}</Typography>
        <Typography variant="body2" sx={{textTransform:'capitalize'}}>Key accounts:</Typography>

        {currentRole.keyAccounts.map((value)=>{
          return         <Typography variant="body2" sx={{marginLeft:'2px', textTransform:'capitalize'}}>{value}</Typography>

        })}
      </Box>
    </Popover>
  </div>

          <Button
            variant="contained"
            color="primary"
            sx={{
              maxHeight: "36px",
              fontSize: "14px",
              fontWeight: "600",
              padding: "10px 40px",
              background: "#7180961A",
              color: "#718096",
              display: "flex",
              justifyContent: "space-between",
              alignSelf: "flex-start",
              alignItems: "center",
              "&:hover": {
                color: "black",
                background: "#0179FF",
              },
            }}
            onClick={() => instance.logoutRedirect()}
          >
            <LogoutIcon />
            <Box sx={{ marginLeft: "10px" }}>Log out</Box>
          </Button>
        </div>
      </div>
    </Box>
  );
};

export default Sidebar;
