import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { Theme } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import CloseIcon from '@mui/icons-material/Close'; // Import CloseIcon
import { getFromJson, saveToJson } from "../../helpers/localData";
import { format } from 'date-fns';
import Tooltip from '@mui/material/Tooltip';

import { LeakRemoveTwoTone } from '@mui/icons-material';
// import { decreaseOrder, decreaseOrderChester, increaseOrder, increaseOrderChester, minchester, minimumFacing, packOrder, packOrderChester } from './data';
import { fetchSkusOrderedChesterfield, fetchSkusOrderedMarlboro, getAllSkus, getSkusOrdered, getSkusOrderedByBrand, getSkusOrderedByDecreaseOrder, getSkusOrderedByDecreaseOrderChesterfield, getSkusOrderedByDecreaseOrderMarlboro, getSkusOrderedByIncreaseOrder, getSkusOrderedByIncreaseOrderChesterfield, getSkusOrderedByIncreaseOrderMarlboro, getSkusWithMinFacingsChesterfield, getSkusWithMinFacingsMarlboro } from '../../../services/skuService';
import { getConfiguration, getOrderedBrands } from '../../../services/configurationService';
import Viewer from '../../RrpPreview/Viewer';
import domtoimage from 'dom-to-image';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import { read, utils, WorkBook, write, writeFile } from 'xlsx-js-style';
import ExcelJS from 'exceljs';
import { useParams } from 'react-router-dom';
import { getSkusWithPriority } from '../../../services/skuPriorityService';
import { exportToExcelForBluk, insertBase64ImageIntoExcelBlob } from './utils/exportFunctions';
import { fetchShelfImage, getShelfConfigById } from '../../../services/shelfConfigurationService';

type Array3D = boolean[][][];

// Define your brand mapping
const brandMapping: Record<number, string> = {
  1: 'RGD',
  4: 'Marlboro',
  2: 'Petra',
  3: 'L&M',
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    customBox: {
      borderRadius: '5.134px',
      background: 'transparent',
      width: '186.103px',
      //height: '390.994px',
      display: 'flex',
      justifyContent: 'center',
      flexDirection: 'column',
      alignItems: 'center',
      gap: '2px',
      position: 'relative',
    },
    line: {
      borderRight: '2px solid #000',
      height: '100%',
      position: 'relative',
    },
    subBox: {
      width: '176.143px',
      height: '51.5px',
      background: '#EFEFEF',
      borderRadius: '4px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'relative',
    },
    secondBox: {
      width: '177.103px',
      height: '82.142px',
      flexShrink: 0,
      borderRadius: '3.85px',
      background: '#EFEFEF',
    },
    selectedBox: {
      backgroundColor: 'white !important',
    },
    selectionBox: {
      position: 'absolute',
      border: '2px solid #0179FF',
      zIndex: 1000,
      pointerEvents: 'none',
      cursor: 'pointer',
      backgroundColor: 'rgba(1, 121, 255, 0.50)',
      '&:active': {
        cursor: 'pointer',
      },
    },
    whiteBox: {
      backgroundColor: 'lightgray',
      borderRadius: '4px',
      height: '41.5px',
      margin: '2px 2px',
      userSelect: 'none',
      //width: 'calc(75% / 10)',
      position: 'relative',
    },
    pinkSquare: {
      position: 'absolute',
      backgroundColor: '#F95252',
      cursor: 'grab',
      zIndex: 500,
      '&:active': {
        cursor: 'grabbing',
      },
    },
    whiteBoxSelected: {
      backgroundColor: 'transparent !important',

      '&::before': {
        content: '""',
        position: 'absolute',
        top: 0,
        left: 0,
        borderRadius: '4px',
        right: 0,
        height: 'calc(50% - 1.5px)',
        backgroundColor: '#63D259',
      },
      '&::after': {
        content: '""',
        position: 'absolute',
        bottom: 0,
        left: 0,
        right: 0,
        borderRadius: '4px',
        height: 'calc(50% - 1.5px)',
        backgroundColor: '#63D259',
      },
      
    },
    OverlayBox: {
      backgroundColor: 'red !important',
      opacity:0.5

     
      
    },
    closeIcon: {
      position: 'absolute',
      width: "16px !important",
      height:"16px !important",
      top: 0,
      right: 0,
      cursor: 'pointer',
      zIndex: 600, // Ensure it's above the squares
      color: 'black', // Adjust color as needed
      '&:hover': {
        opacity: 0.4, // Change color on hover
      },
    },
    yellowSquare: {
      position: 'absolute',
      backgroundColor: '#FFD700', // Yellow color
      cursor: 'grab',
      zIndex: 400, // Ensure it's below the pink square if they overlap
      '&:active': {
        cursor: 'grabbing',
      },
    },
    blueSquare: {
      position: 'absolute',
      //backgroundColor: 'tr', // Yellow color
      cursor: 'grab',
      zIndex: 400, // Ensure it's below the pink square if they overlap
      '&:active': {
        cursor: 'grabbing',
      },
    },
    mergedBox: {
      position: 'absolute',
      backgroundColor: '#FF6C01',
      zIndex: 900,
    },
  })
);
type Square = {
  title?:string;
  width: number;
  height: number;
  x:number;
  y:number;
};
type Box = {
  col: number;
  row: number;
  z: number;
};
interface GridDemoProps {
  selectionMode?: number;
    squares:Square[]
    squaresYellow:Square[]
    squaresBlue:Square[]
    shelfDuplex: any[][][];
    setShelfDuplex: React.Dispatch<React.SetStateAction<any[][][]>>;
    pinkSquares: {
      width: number;
      height: number;
      x: number;
      y: number;
      title?: string | undefined;
      positions?: any;
  }[]
  setPinkSquares:React.Dispatch<React.SetStateAction<{
    title?: string | undefined;
    width: number;
    height: number;
    x: number;
    y: number;
}[]>>
yellowSquares:{
  width: number;
  height: number;
  x: number;
  y: number;
  title?: string | undefined;
  positions?: any;
}[]
setYellowSquares:React.Dispatch<React.SetStateAction<{
  title?: string | undefined;
  width: number;
  height: number;
  x: number;
  y: number;
}[]>>
blueSquares:{
  width: number;
  height: number;
  x: number;
  y: number;
  title?: string | undefined;
  positions?: any;
}[]
setBlueSquares:React.Dispatch<React.SetStateAction<{
  title?: string | undefined;
  width: number;
  height: number;
  x: number;
  y: number;
}[]>>
selectedSquare:{ col: number; row: number,z:number } | null;
squaresWithImages:Array<Array<Array<string>>>;
squaresWithNames:Array<Array<Array<string>>>;
squaresWithEan:Array<Array<Array<string>>>;
squaresWithWithds: Array<Array<Array<string>>>;
squaresWithHeights:Array<Array<Array<string>>>;
setSelectedSquare: React.Dispatch<React.SetStateAction<{
  col: number;
  row: number;
  z: number;
  isDuplex?:boolean

} | null>>;
col: number;
row: number;
rowDistance: number;
colDistance: number;
widths: number[];
selectedBoxesPmi: boolean[][][];
setIsLoading: React.Dispatch<React.SetStateAction<any >>
isAutoConfiguration:boolean;  // New prop for reset function
hiddencells: never[];
keyAccountId: number | null;
exportOptions: {
  compositionName: boolean;
  eanCode: boolean;
  validityDate: boolean;
}
validFrom:Date | null;
validTo:Date | null;
name:any;
isBulk?:boolean;
scale?:number;
}
type WhiteBox = Square;

export interface ChildRef {
  triggerChildFunction: () => void;
  triggerSecondChildFunction: () => void;

}
const GridDemo = forwardRef<ChildRef, GridDemoProps>((props: GridDemoProps, ref) => {

const  {
  selectionMode = 1 ,squares,isBulk=false,squaresYellow,pinkSquares,setPinkSquares,yellowSquares,setYellowSquares,blueSquares,squaresWithImages,
  setSelectedSquare,
  selectedSquare,
  col,
  row,
  rowDistance,
  colDistance,
  widths,
  selectedBoxesPmi,
  setIsLoading,
  shelfDuplex,
  setShelfDuplex,
  squaresWithNames,
  isAutoConfiguration,
  squaresWithEan,
  hiddencells,
  keyAccountId,
  exportOptions,
  validFrom,
  validTo,
  name,
  squaresWithWithds,
  squaresWithHeights,
  scale
} = props
const classes = useStyles();
const [exportArray, setExportArray] = useState<any[]>(
  []
);
const [exportArrayEan, setExportArrayEan] = useState<any[]>(
  []
);
console.log('exportArray',exportArray)
const { id } = useParams<{ id: string }>();

const handleExportToPDF = async () => {
  setIsLoading(true); // Set loading state to true

  const input = containerRef.current;
  if (input) {
    const scale = 4;
    const width = input.scrollWidth;
    const height = input.scrollHeight;

    const fetchImageFromServer = async (url:any) => {
        try {
        const response = await fetchShelfImage(url)
        return response.data;
        } catch {
          console.log('empty image')
          return '-'
        }
  
    };

    const embedBackgroundImagesAsBase64 = async (node:any) => {
      // Handle div background images
      const divs = Array.from(node.getElementsByTagName('div'));
      const divPromises = divs.map(async (div:any) => {
        const style = window.getComputedStyle(div);
        const backgroundImage = style.getPropertyValue('background-image');
        const urlMatch = backgroundImage.match(/url\("(.*)"\)/);
        if (urlMatch && urlMatch[1]) {
          const originalUrl = urlMatch[1];
          const dataUrl = await fetchImageFromServer(originalUrl);
          div.style.backgroundImage = `url(${dataUrl})`;
        }
      });
    
      // Handle img elements
      const imgs = Array.from(node.getElementsByTagName('img'));
      const imgPromises = imgs.map(async (img:any) => {
        const originalUrl = img.src;
        const dataUrl = await fetchImageFromServer(originalUrl);
        img.src = dataUrl;
      });
    
      await Promise.all([...divPromises, ...imgPromises]);
    };

    await embedBackgroundImagesAsBase64(input);
    const style = {
      transform: `scale(${scale})`,
      transformOrigin: 'top left',
      width: `${width}px`,
      height: `${height}px`,
      position: 'absolute',
      left: '0',
      top: '0'
    };
    domtoimage.toPng(input, { width: width * scale, height: height * scale, style })
    .then((dataUrl) => {
      // Create a PDF document
      const pdf = new jsPDF('landscape', 'mm', 'a4');
      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = pdf.internal.pageSize.getHeight();
      const img = new Image();
      img.src = dataUrl;
      
      img.onload = () => {
        const imgProps = pdf.getImageProperties(img);
        const imgRatio = imgProps.height / imgProps.width;
        const pdfImgHeight = pdfWidth * imgRatio;

        // If the image fits within one page, add it directly
        if (pdfImgHeight <= pdfHeight) {
          const yOffset = (pdfHeight - pdfImgHeight) / 2; // Center vertically
          pdf.addImage(dataUrl, 'PNG', 0, yOffset, pdfWidth, pdfImgHeight);
        } else {
          // If the image is taller than one page, split it across pages
          let position = 0;
          while (position < imgProps.height) {
            const canvasPage = document.createElement('canvas');
            canvasPage.width = imgProps.width;
            const pageHeight = Math.min(imgProps.height - position, imgProps.width * pdfHeight / pdfWidth);
            canvasPage.height = pageHeight;

            const ctx = canvasPage.getContext('2d');
            if (ctx) {
              ctx.drawImage(img, 0, position, imgProps.width, pageHeight, 0, 0, imgProps.width, pageHeight);
            }

            const imgPageData = canvasPage.toDataURL('image/png');
            const yOffset = (pdfHeight - (pdfWidth * pageHeight / imgProps.width)) / 2; // Center vertically
            pdf.addImage(imgPageData, 'PNG', 0, yOffset, pdfWidth, pdfWidth * pageHeight / imgProps.width);

            position += pageHeight;
            if (position < imgProps.height) {
              pdf.addPage();
            }
          }
        }
        if(name != null && name != '') {
          pdf.save(`${name}.pdf`);
      
        }else {
          pdf.save(`Shelf-export-${id}.pdf`);

      
        }
        setIsLoading(false); // Set loading state to false after completion

      };
    })
    .catch((error) => {
      console.error('Error generating PDF:', error);
      setIsLoading(false); // Set loading state to false after completion

    });
  }
};
const handleExportToPNG = async () => {
  setIsLoading(true); // Set loading state to true

  const input = containerRef.current;
  if (input) {
    const scale = 4;
    const width = input.scrollWidth;
    const height = input.scrollHeight;

    const fetchImageFromServer = async (url:any) => {
      if(url != undefined) {
        try {
          // const response = await fetch(`https://shelf-creation-mk.stage.pmi.zenown.com/shelf-configs/fetch-image?url=${encodeURIComponent(url.replace('/sku_images','/sku_resized'))}`);
          // const data = await response.json();
          // return data.data;
          const response = await fetchShelfImage(url)
          return  response.data;
        }catch {
          return ''
        }
       
      }else 
      {
        return ''
      }

    };

    const embedBackgroundImagesAsBase64 = async (node:any) => {
      const divs = Array.from(node.getElementsByTagName('div'));
      const divPromises = divs.map(async (div:any) => {
        const style = window.getComputedStyle(div);
        const backgroundImage = style.getPropertyValue('background-image');
        const urlMatch = backgroundImage.match(/url\("(.*)"\)/);
        if (urlMatch && urlMatch[1]) {
          const originalUrl = urlMatch[1];
          const dataUrl = await fetchImageFromServer(originalUrl);
          div.style.backgroundImage = `url(${dataUrl})`;
        }
      });
    
      const imgs = Array.from(node.getElementsByTagName('img'));
      const imgPromises = imgs.map(async (img:any) => {
        const originalUrl = img.src;
        const dataUrl = await fetchImageFromServer(originalUrl);
        img.src = dataUrl;
      });
    
      await Promise.all([...divPromises, ...imgPromises]);
    };

    await embedBackgroundImagesAsBase64(input);

    const style = {
      transform: `scale(${scale})`,
      transformOrigin: 'top left',
      width: `${width}px`,
      height: `${height}px`,
      position: 'absolute',
      left: '0',
      top: '0'
    };

    // Generate the PNG image
    const dataUrl = await domtoimage.toPng(input, { width: width * scale, height: height * scale, style });

    setIsLoading(false); // Set loading state to false after completion

    return dataUrl; // Return the PNG data URL for later use
  }
};
const fetchImageAsBase64 = async (url: string): Promise<string> => {
  const response = await fetch(`https://shelf-creation-mk.stage.pmi.zenown.com/shelf-configs/fetch-image?url=${encodeURIComponent(url)}`);
  console.log(response)
  const blob = response;
  const data = await response.json();
  console.log('Base64 Image:', data);

  return data.data.replace('application/octet-stream','image/png');
};

const insertBase64ImageIntoExcel = async (blob: Blob, imageUrl: string, shelfName:string, dateString:string) => {
  const buffer = await blob.arrayBuffer();
  const workbook = new ExcelJS.Workbook();
  await workbook.xlsx.load(buffer);
   console.log('buffer loadig done')
  // Get the first worksheet
  const sheet = workbook.getWorksheet(1);
  if (sheet && sheet.properties) {
    sheet.properties.defaultRowHeight = 15; // Set default row height to 15 points
  } else {
    console.error("Sheet or sheet properties are undefined.");
  }
  const safelyMergeCells = (sheet: any, startRow: number, startCol: number, endRow: number, endCol: number) => {
    try {
      // Attempt to merge the specified range
      sheet.mergeCells(startRow, startCol, endRow, endCol);
    } catch (error) {
      console.warn(`Merge failed for range ${startRow}:${startCol} to ${endRow}:${endCol}`);
      // Handle the error if needed (e.g., log or ignore)
    }
  };
  const shelfNameRow = sheet?.getRow(1);
if (shelfNameRow) {
  shelfNameRow.getCell(1).value = `Shelf Name: ${shelfName || 'Unnamed Shelf'}`;
  shelfNameRow.getCell(1).alignment = { horizontal: 'center', vertical: 'middle' };
  shelfNameRow.getCell(1).font = { bold: true, size: 18 };
  shelfNameRow.height = 16;

  sheet && safelyMergeCells(sheet, 1, 1, 1, sheet.actualColumnCount);
}

// Add Validity in the second row
const validityRow = sheet?.getRow(2);
if (validityRow) {
  validityRow.getCell(1).value = ` ${dateString}`;
  validityRow.getCell(1).alignment = { horizontal: 'center', vertical: 'middle' };
  validityRow.getCell(1).font = { bold: true, size: 10 };
  validityRow.height = 16;
 
  sheet && safelyMergeCells(sheet, 2, 1, 2, sheet.actualColumnCount);
}

// // Add an empty highlighted row in the third row
// const highlightedRow = sheet?.getRow(3);
// if (highlightedRow) {
//   highlightedRow.getCell(1).value = ''; // Leave it empty
//   highlightedRow.getCell(1).fill = {
//     type: 'pattern',
//     pattern: 'solid',
//     fgColor: { argb: 'FFFFE0' }, // Light yellow background
//   };
//   highlightedRow.height = 15;
//   sheet &&  safelyMergeCells(sheet, 3, 1, 3, sheet.actualColumnCount);
// }
  
  let rowTotalHeight = 0
  sheet?.eachRow({ includeEmpty: true }, (row) => {
    row.height = 17;
    rowTotalHeight++ // Set a small height for the row
    row.eachCell((cell) => {
      cell.alignment = { wrapText: false }; // Prevent text wrapping

      // Add a black border to the cell
      cell.border = {
        top: { style: 'thin', color: { argb: 'FF000000' } },
        left: { style: 'thin', color: { argb: 'FF000000' } },
        bottom: { style: 'thin', color: { argb: 'FF000000' } },
        right: { style: 'thin', color: { argb: 'FF000000' } },
      };
    });
  });

  // Fetch the image as a base64 string
  const base64Image = imageUrl;

  // Log the base64 image data (for debugging)
  console.log('Base64 Image:', base64Image);

  // Ensure the base64 string is in the correct format
  if (!base64Image.startsWith('data:image')) {
    throw new Error('Invalid image data');
  }

  // Add the image to the workbook
  const imageId = workbook.addImage({
    base64: base64Image.split(',')[1], // Strip off the "data:image/png;base64," part
    extension: 'png',
  });
  console.log('add image done')
  let totalWidth = 0;
  sheet?.columns.forEach(column => {
    totalWidth += column.width ? column.width * 7.5 : 8.43 * 13.2; // Approximate width in pixels, assuming default font size
  });
  console.log('add image done 2')

  // Calculate the total height of the content
  let totalHeight = 0;

  sheet?.eachRow({ includeEmpty: true }, (excelRow, rowNumber) => {
    let maxLines = 1; // At least one line per cell/row
    excelRow.eachCell((cell) => {
      if (cell.value && typeof cell.value === 'string') {
        // Count how many lines are in this cell
        const cellLines = cell.value.split('\n').length;
        if (cellLines > maxLines) {
          maxLines = cellLines;
        }
      }
      // Enable text wrapping for this cell
      cell.alignment = { 
        horizontal: 'center', 
        vertical: 'middle', 
        wrapText: true 
      };
    });
  
    // Adjust the row height based on the maximum number of lines found
    // Approximately 15 points per line, adjust as needed for your font/sizing
    excelRow.height = maxLines * 45;
  
    totalHeight += excelRow.height;
  });
  sheet?.columns.forEach((col:any) => {
    let hasText = false;
    col?.eachCell((cell:any) => {
      if (cell.value && typeof cell.value === 'string' && cell.value.trim() !== '') {
        hasText = true;
      }
    });
    if (hasText) {
      col.width = 16; // set a certain width if this column contains text
    }
  });
  console.log('add image done 3')
  if(shelfNameRow) {
    shelfNameRow.height = 30;
    const firstCell = shelfNameRow.getCell(1); // Access the first cell explicitly
    firstCell.border = {
      top: { style: 'thin', color: { argb: 'FF000000' } },
      left: { style: 'thin', color: { argb: 'FF000000' } },
      bottom: { style: 'thin', color: { argb: 'FF000000' } },
      right: { style: 'thin', color: { argb: 'FF000000' } },
    };
  }
  if(validityRow) {
    validityRow.height = 20;

    const firstCell = validityRow.getCell(1); // Access the first cell explicitly
    firstCell.border = {
      top: { style: 'thin', color: { argb: 'FF000000' } },
      left: { style: 'thin', color: { argb: 'FF000000' } },
      bottom: { style: 'thin', color: { argb: 'FF000000' } },
      right: { style: 'thin', color: { argb: 'FF000000' } },
    };
  }
  const emptyRow = sheet?.getRow(3);
  if(emptyRow) {
    emptyRow.height = 20;

  }

  // Insert the image at row 20, column 1
  sheet?.addImage(imageId, {
    tl: { col: 0, row: row +6 }, // Top-left position (row 20, column 1, zero-indexed)
    ext: { width: totalWidth, height: 14*8 * rowTotalHeight }, // Adjust the size of the image as needed
  }); 
  console.log('add image done4 ')


  // Export the updated workbook as a Blob
  const updatedBuffer = await workbook.xlsx.writeBuffer();
  const updatedBlob = new Blob([updatedBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

  // Create a download link to download the updated Excel file
  const link = document.createElement('a');
  link.href = URL.createObjectURL(updatedBlob);
  if(name != null && name != '') {
    link.download =  `${name}.xlsx`;

  }else {
    link.download =  `shelf-export-sheet-${id}.xlsx`;

  }
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

   const insertBase64ImageIntoExcelBlob2 = async (blob: Blob, imageUrl: string, shelfName:string, dateString:string) => {
  const buffer = await blob.arrayBuffer();
  const workbook = new ExcelJS.Workbook();
  await workbook.xlsx.load(buffer);
   console.log('buffer loadig done')
  // Get the first worksheet
  const sheet = workbook.getWorksheet(1);
  if (sheet && sheet.properties) {
    sheet.properties.defaultRowHeight = 15; // Set default row height to 15 points
  } else {
    console.error("Sheet or sheet properties are undefined.");
  }
  const safelyMergeCells = (sheet: any, startRow: number, startCol: number, endRow: number, endCol: number) => {
    try {
      // Attempt to merge the specified range
      sheet.mergeCells(startRow, startCol, endRow, endCol);
    } catch (error) {
      console.warn(`Merge failed for range ${startRow}:${startCol} to ${endRow}:${endCol}`);
      // Handle the error if needed (e.g., log or ignore)
    }
  };
  const shelfNameRow = sheet?.getRow(1);
if (shelfNameRow) {
  shelfNameRow.getCell(1).value = ` ${shelfName || `Shelf ${id}`}`;
  shelfNameRow.getCell(1).alignment = { horizontal: 'center', vertical: 'middle' };
  shelfNameRow.getCell(1).font = { bold: true, size: 18 };
  shelfNameRow.height = 16;

  sheet && safelyMergeCells(sheet, 1, 1, 1, sheet.actualColumnCount);
}

// Add Validity in the second row
const validityRow = sheet?.getRow(2);
if (validityRow) {
  validityRow.getCell(1).value = ` ${dateString}`;
  validityRow.getCell(1).alignment = { horizontal: 'center', vertical: 'middle' };
  validityRow.getCell(1).font = { bold: true, size: 10 };
  validityRow.height = 16;
 
  sheet && safelyMergeCells(sheet, 2, 1, 2, sheet.actualColumnCount);
}

// // Add an empty highlighted row in the third row
// const highlightedRow = sheet?.getRow(3);
// if (highlightedRow) {
//   highlightedRow.getCell(1).value = ''; // Leave it empty
//   highlightedRow.getCell(1).fill = {
//     type: 'pattern',
//     pattern: 'solid',
//     fgColor: { argb: 'FFFFE0' }, // Light yellow background
//   };
//   highlightedRow.height = 15;
//   sheet &&  safelyMergeCells(sheet, 3, 1, 3, sheet.actualColumnCount);
// }
  
  let rowTotalNumber = 0
  sheet?.eachRow({ includeEmpty: true }, (row) => {
    row.height = 17; 
    rowTotalNumber++// Set a small height for the row
    row.eachCell((cell) => {
      cell.alignment = { wrapText: false }; // Prevent text wrapping

      // Add a black border to the cell
      cell.border = {
        top: { style: 'thin', color: { argb: 'FF000000' } },
        left: { style: 'thin', color: { argb: 'FF000000' } },
        bottom: { style: 'thin', color: { argb: 'FF000000' } },
        right: { style: 'thin', color: { argb: 'FF000000' } },
      };
    });
  });

  // Fetch the image as a base64 string
  const base64Image = imageUrl;

  // Log the base64 image data (for debugging)
  console.log('Base64 Image:', base64Image);

  // Ensure the base64 string is in the correct format
  if (!base64Image.startsWith('data:image')) {
    throw new Error('Invalid image data');
  }

  // Add the image to the workbook
  const imageId = workbook.addImage({
    base64: base64Image.split(',')[1], // Strip off the "data:image/png;base64," part
    extension: 'png',
  });
  console.log('add image done')
  let totalWidth = 0;
  sheet?.columns.forEach(column => {
    totalWidth += column.width ? column.width * 7.5 : 8.43 * 13.2; // Approximate width in pixels, assuming default font size
  });
  console.log('add image done 2')

  // Calculate the total height of the content
  let totalHeight = 0;

  sheet?.eachRow({ includeEmpty: true }, (excelRow, rowNumber) => {
    let maxLines = 1; // At least one line per cell/row
    excelRow.eachCell((cell) => {
      if (cell.value && typeof cell.value === 'string') {
        // Count how many lines are in this cell
        const cellLines = cell.value.split('\n').length;
        if (cellLines > maxLines) {
          maxLines = cellLines;
        }
      }
      // Enable text wrapping for this cell
      cell.alignment = { 
        horizontal: 'center', 
        vertical: 'middle', 
        wrapText: true 
      };
    });
  
    // Adjust the row height based on the maximum number of lines found
    // Approximately 15 points per line, adjust as needed for your font/sizing
    excelRow.height = maxLines * 45;
  
    totalHeight += excelRow.height;
  });
  sheet?.columns.forEach((col:any) => {
    let hasText = false;
    col?.eachCell((cell:any) => {
      if (cell.value && typeof cell.value === 'string' && cell.value.trim() !== '') {
        hasText = true;
      }
    });
    if (hasText) {
      col.width = 16; // set a certain width if this column contains text
    }
  });
  console.log('add image done 3')
  if(shelfNameRow) {
    shelfNameRow.height = 30;
    const firstCell = shelfNameRow.getCell(1); // Access the first cell explicitly
    firstCell.border = {
      top: { style: 'thin', color: { argb: 'FF000000' } },
      left: { style: 'thin', color: { argb: 'FF000000' } },
      bottom: { style: 'thin', color: { argb: 'FF000000' } },
      right: { style: 'thin', color: { argb: 'FF000000' } },
    };
  }
  if(validityRow) {
    validityRow.height = 20;

    const firstCell = validityRow.getCell(1); // Access the first cell explicitly
    firstCell.border = {
      top: { style: 'thin', color: { argb: 'FF000000' } },
      left: { style: 'thin', color: { argb: 'FF000000' } },
      bottom: { style: 'thin', color: { argb: 'FF000000' } },
      right: { style: 'thin', color: { argb: 'FF000000' } },
    };
  }
  const emptyRow = sheet?.getRow(3);
  if(emptyRow) {
    emptyRow.height = 20;

  }

  // Insert the image at row 20, column 1
  sheet?.addImage(imageId, {
    tl: { col: 0, row: row +6 }, // Top-left position (row 20, column 1, zero-indexed)
    ext: { width: totalWidth, height: 14*8 *rowTotalNumber }, // Adjust the size of the image as needed
  }); 
  console.log('add image done4 ')


  // Export the updated workbook as a Blob
  const updatedBuffer = await workbook.xlsx.writeBuffer();
  const updatedBlob = new Blob([updatedBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

  return updatedBlob
};



const exportToExcel =async  (
  images: string[][][],
  imagesLaying: string[][][],
  columnWidths: number[],
  row: number,
  col: number,
  shelfDuplex: any[][][],
  colorConditionArrays: boolean[][][][], // Multi-dimensional boolean arrays for various conditions
  highlightConditionArrays: boolean[][][][], // Multi-dimensional boolean array for Marketing Material
  ledScreenConditionArrays: boolean[][][][], // Multi-dimensional boolean array for LED Screens
  fileName = 'grid_data.xlsx'
) => {
  //alert(exportOptions.toString())
  const ws = utils.aoa_to_sheet([]);
  let rrpRowDetection = -1;

  console.log('eanF',exportArray)
  // Step 1: Generate the worksheet content
  for (let customBoxIndex = 0; customBoxIndex < col; customBoxIndex++) {
    const dynamicBoxCount = Math.floor(columnWidths[customBoxIndex] / 30.5);

    let cumulativeBoxCount = 0;

    for (let i = 0; i < customBoxIndex; i++) {
      cumulativeBoxCount += Math.floor(columnWidths[i] / 30.5);
    }
    for (let setIndex = 0; setIndex < row; setIndex++) {
      let mergeStartColumnMarketing = null;
      let mergeEndColumnMarketing = null;
      let mergeStartColumnLED = null;
      let mergeEndColumnLED = null;
      let duplexIndex =0
      for (let boxIndex = 0; boxIndex < dynamicBoxCount; boxIndex++) {
        const actualRow = setIndex;
        const actualColumn = cumulativeBoxCount + boxIndex;


        const isDuplexPusher = shelfDuplex[customBoxIndex]?.[actualRow]?.[boxIndex];
       
        const imageSrc = naming[customBoxIndex]?.[actualRow]?.[isDuplexPusher ? duplexIndex  :boxIndex] || '';

        const imageSrcLaying = naming[customBoxIndex]?.[actualRow]?.[duplexIndex+1] || '';
        const eancode = ean[customBoxIndex]?.[actualRow]?.[isDuplexPusher ? duplexIndex  :boxIndex] || '';
        const eancodeLaying = ean[customBoxIndex]?.[actualRow]?.[duplexIndex+1] || '';

        if(isDuplexPusher) {
          duplexIndex +=2
        }else {
          duplexIndex +=1

        }
        const shouldColorRed = colorConditionArrays.some(array => array[customBoxIndex]?.[actualRow]?.[boxIndex]);

        // Generate the cell address in Excel format
        const cellAddress = utils.encode_cell({
          c: actualColumn,
          r: actualRow +3,
        });
        console.log('exportOptions',exportOptions)
        // Determine cell value
        let cellValue = imageSrc +'\n' + eancode;
        if(exportOptions.compositionName && exportOptions.eanCode) {
          cellValue = imageSrc +'\n' + eancode
        }else if (exportOptions.compositionName && !exportOptions.eanCode) {
          cellValue = imageSrc 
        }else if (!exportOptions.compositionName && exportOptions.eanCode) {
          cellValue = eancode 
        }else {
          cellValue = '-'
        }
        if (isDuplexPusher && imageSrcLaying &&  exportOptions.compositionName && exportOptions.eanCode) {
          cellValue = `${imageSrc} // ${imageSrcLaying} \n  ${eancode}  // ${eancodeLaying}`;
        }else if (isDuplexPusher && imageSrcLaying &&  exportOptions.compositionName && !exportOptions.eanCode) {
          cellValue = `${imageSrc} // ${imageSrcLaying} `;

        }else if (isDuplexPusher && imageSrcLaying &&  !exportOptions.compositionName && exportOptions.eanCode) {
          cellValue = `${eancode} //  ${eancodeLaying}`;
        }

        ws[cellAddress] = { v: cellValue };

        // Track RRP row for exportArray adjustments
        if (shouldColorRed) {
          if (rrpRowDetection === -1) {
            rrpRowDetection = setIndex;
          }
          for (let i = 0; i < exportArray.length; i++) {
            const exportData = exportArray[i]?.[setIndex - rrpRowDetection]?.[boxIndex];
            if (exportData) {
              cellValue = `${exportData.top} // ${exportData.bottom}`;
            }
          }
          let cellValueean =''
          for (let i = 0; i < exportArray.length; i++) {
            const exportDataEan = exportArrayEan[i]?.[setIndex - rrpRowDetection]?.[boxIndex];
            if (exportDataEan) {
              cellValueean = `${exportDataEan.top} // ${exportDataEan.bottom}`;
            }
          }
          ws[cellAddress] = { v: cellValue +'\n' + cellValueean};
        }

        // Check highlightConditionArrays for Marketing Material
        let shouldHighlightYellow = false;
        for (let i = 0; i < highlightConditionArrays.length; i++) {
          if (highlightConditionArrays[i][customBoxIndex]?.[actualRow]?.[boxIndex]) {
            shouldHighlightYellow = true;
            break;
          }
        }

        // Check ledScreenConditionArrays for LED Screens
        let shouldHighlightRed = false;
        for (let i = 0; i < ledScreenConditionArrays.length; i++) {
          if (ledScreenConditionArrays[i][customBoxIndex]?.[actualRow]?.[boxIndex]) {
            shouldHighlightRed = true;
            break;
          }
        }

        // Handle merging cells for Marketing Material
        if (shouldHighlightYellow) {
          if (mergeStartColumnMarketing === null) {
            mergeStartColumnMarketing = actualColumn;
          }
          mergeEndColumnMarketing = actualColumn;

          // If it's the last cell in the row or the next cell doesn't satisfy the condition, merge and apply style
          if (boxIndex === dynamicBoxCount - 1 || !highlightConditionArrays.some(
              array => array[customBoxIndex]?.[actualRow]?.[boxIndex + 1])) {

            ws['!merges'] = ws['!merges'] || [];
            ws['!merges'].push({
              s: { c: mergeStartColumnMarketing, r: actualRow +3 },
              e: { c: mergeEndColumnMarketing, r: actualRow +3 },
            });

            const mergedCellAddress = utils.encode_cell({ c: mergeStartColumnMarketing, r: actualRow + 3});
            ws[mergedCellAddress] = {
              v: "Marketing Material",
              s: {
                fill: {
                  patternType: 'solid',
                  fgColor: { rgb: 'FFFF00' }, // Yellow background
                },
                alignment: { horizontal: 'center', vertical: 'center', wrapText: true },
                font: { name: 'Arial', bold: true, sz: 10 }, // Bold and Arial
              },
            };

            // Reset for next potential merge
            mergeStartColumnMarketing = null;
            mergeEndColumnMarketing = null;
          }
        } else {
          // If the current cell doesn't satisfy the condition, reset merging variables
          mergeStartColumnMarketing = null;
          mergeEndColumnMarketing = null;
        }

        // Handle merging cells for LED Screens
        if (shouldHighlightRed) {
          if (mergeStartColumnLED === null) {
            mergeStartColumnLED = actualColumn;
          }
          mergeEndColumnLED = actualColumn;

          // If it's the last cell in the row or the next cell doesn't satisfy the condition, merge and apply style
          if (boxIndex === dynamicBoxCount - 1 || !ledScreenConditionArrays.some(
              array => array[customBoxIndex]?.[actualRow]?.[boxIndex + 1])) {

            ws['!merges'] = ws['!merges'] || [];
            ws['!merges'].push({
              s: { c: mergeStartColumnLED, r: actualRow +3 },
              e: { c: mergeEndColumnLED, r: actualRow +3 },
            });

            const mergedCellAddress = utils.encode_cell({ c: mergeStartColumnLED, r: actualRow +3 });
            ws[mergedCellAddress] = {
              v: "LED Screens",
              s: {
                fill: {
                  patternType: 'solid',
                  fgColor: { rgb: 'FFCCCC' }, // Light red background
                },
                alignment: { horizontal: 'center', vertical: 'center', wrapText: true },
                font: { name: 'Arial', bold: true, sz: 10 }, // Bold and Arial
              },
            };

            // Reset for next potential merge
            mergeStartColumnLED = null;
            mergeEndColumnLED = null;
          }
        } else {
          // If the current cell doesn't satisfy the condition, reset merging variables
          mergeStartColumnLED = null;
          mergeEndColumnLED = null;
        }
      }
    }
  }

  // Step 2: Handle merging for colorConditionArrays separately
  for (let i = 0; i < colorConditionArrays.length; i++) {
    for (let setIndex = 0; setIndex < row; setIndex++) {
      let mergeStartColumn = null;
      let mergeEndColumn = null;
      let mergedValue = ''; // Variable to accumulate merged cell value
      let valueName = ''; // Variable to accumulate merged cell value
      let valueEan = ''; // Variable to accumulate merged cell value


      for (let customBoxIndex = 0; customBoxIndex < col; customBoxIndex++) {
        const dynamicBoxCount = Math.floor(columnWidths[customBoxIndex] / 30.5);
        let cumulativeBoxCount = 0;

        for (let i = 0; i < customBoxIndex; i++) {
          cumulativeBoxCount += Math.floor(columnWidths[i] / 30.5);
        }
        for (let boxIndex = 0; boxIndex < dynamicBoxCount; boxIndex++) {
          const shouldColorRed = colorConditionArrays[i]?.[customBoxIndex]?.[setIndex]?.[boxIndex];

          if (shouldColorRed) {
            const actualColumn = cumulativeBoxCount  + boxIndex;
            if (mergeStartColumn === null) {
              mergeStartColumn = actualColumn;
            }
            mergeEndColumn = actualColumn;

            // Add to the merged value if colorConditionArray is met
            const exportData = exportArray[i]?.[setIndex - rrpRowDetection]?.[boxIndex];
            const exportDataean = exportArrayEan[i]?.[setIndex - rrpRowDetection]?.[boxIndex];
            const name = exportArray[i]?.map((row:any, rowIndex:any) => {
              if(rowIndex===setIndex - rrpRowDetection){
                return row.map((box:any, boxIndex:any) => {
                  const exportData = exportArray[i]?.[rowIndex]?.[boxIndex];
                  const exportDataean = exportArrayEan[i]?.[rowIndex]?.[boxIndex];
              
                  if (exportData) {
                    let valueName = "";
                    let valueEan = "";
                    let mergedValue = "";
              
                    if (exportData.bottom === "") {
                      valueName += `(${exportData.top}) | `;
                      valueEan += `(${exportDataean.top}) | `;
                    } else if (exportData.top === "") {
                      valueName += `(${exportData.bottom}) | `;
                      valueEan += `(${exportDataean.bottom}) | `;
                    } else {
                      valueName += `(${exportData.top} // ${exportData.bottom}) | `;
                      valueEan += `(${exportDataean.top} // ${exportDataean.bottom}) | `;
                    }
              
                    if (exportOptions.compositionName && exportOptions.eanCode) {
                      mergedValue = `${valueName}`;
                    } else if (exportOptions.compositionName && !exportOptions.eanCode) {
                      mergedValue = `${valueName}`;
                    } else if (!exportOptions.compositionName && exportOptions.eanCode) {
                      mergedValue = ``;
                    } else if (!exportOptions.compositionName && !exportOptions.eanCode) {
                      mergedValue = `-`;
                    }
              
                    return mergedValue;
                  }
              
                  return "-"; // Default value if no exportData exists
                });
              }
             
            }).flat(2).join(" ");
            const eans = exportArray[i]?.map((row:any, rowIndex:any) => {
              if(rowIndex===setIndex - rrpRowDetection){
                return row.map((box:any, boxIndex:any) => {
                  const exportData = exportArray[i]?.[rowIndex]?.[boxIndex];
                  const exportDataean = exportArrayEan[i]?.[rowIndex]?.[boxIndex];
              
                  if (exportData) {
                    let valueName = "";
                    let valueEan = "";
                    let mergedValue = "";
              
                    if (exportData.bottom === "") {
                      valueName += `(${exportData.top}) | `;
                      valueEan += `(${exportDataean.top}) | `;
                    } else if (exportData.top === "") {
                      valueName += `(${exportData.bottom}) | `;
                      valueEan += `(${exportDataean.bottom}) | `;
                    } else {
                      valueName += `(${exportData.top} // ${exportData.bottom}) | `;
                      valueEan += `(${exportDataean.top} // ${exportDataean.bottom}) | `;
                    }
              
                    if (exportOptions.compositionName && exportOptions.eanCode) {
                      mergedValue = `${valueEan}`;
                    } else if (exportOptions.compositionName && !exportOptions.eanCode) {
                      mergedValue = ``;
                    } else if (!exportOptions.compositionName && exportOptions.eanCode) {
                      mergedValue = `${valueEan}`;
                    } else if (!exportOptions.compositionName && !exportOptions.eanCode) {
                      mergedValue = `-`;
                    }
              
                    return mergedValue;
                  }
              
                  return "-"; // Default value if no exportData exists
                });
              }
            
            }).flat(2).join(" ");
            console.log('mergedValuett',mergedValue)
            mergedValue= name+'\n '+eans
//             if (exportData) {
//               if(exportData.bottom ===''){
//                valueName +=`(${exportData.top} ) | `
//               valueEan += `(${exportDataean.top} ) | `
//               }else if(exportData.top ==='') {
//      valueName +=`(${exportData.bottom}) | `
//               valueEan += `( ${exportDataean.bottom}) | `
//               }else {
//  valueName +=`(${exportData.top} // ${exportData.bottom}) | `
//               valueEan += `(${exportDataean.top} // ${exportDataean.bottom}) | `
//               }
//               if(exportOptions.compositionName && exportOptions.eanCode) {
//                 mergedValue = `${valueName} \n  ${valueEan}`;
//               }
//               else if(exportOptions.compositionName && !exportOptions.eanCode) {
//                 mergedValue = `${valueName} `;
//               }else if (!exportOptions.compositionName && exportOptions.eanCode){
//                 mergedValue = ` ${valueEan}`;

//               }else if (!exportOptions.compositionName && !exportOptions.eanCode) {
//                 mergedValue = `-`;
//               }

           

//             }
          }
        }
      }

      if (mergeStartColumn !== null && mergeEndColumn !== null && mergeStartColumn !== mergeEndColumn) {
        ws['!merges'] = ws['!merges'] || [];
        ws['!merges'].push({
          s: { c: mergeStartColumn, r: setIndex + 3},
          e: { c: mergeEndColumn, r: setIndex +3 },
        });

        // Set the merged cell value
        const mergedCellAddress = utils.encode_cell({ c: mergeStartColumn, r: setIndex +3 });

        if (mergedValue.trim()) {
          ws[mergedCellAddress] = {
            v: mergedValue.trim(),
            s: {
              fill: {
                patternType: 'solid',
                fgColor: { rgb: 'ADD8E6' }, // Light blue background
              },
              alignment: { horizontal: 'center', vertical: 'center', wrapText: true },
              font: { name: 'Arial', bold: false, sz: 10 }, // Bold and Arial
            },
          };
        }
      }
    }
  }

  if(exportOptions.validityDate) {
 // Format validFrom and validTo as 'dd-MM-yyyy' or another format of choice
 
 const formattedValidFrom = validFrom ? format(new Date(validFrom), 'dd-MM-yyyy')==='01-01-1970' ?'N/A' :  format(new Date(validFrom), 'dd-MM-yyyy') : 'N/A';
 const formattedValidTo = validTo ? format(new Date(validTo), 'dd-MM-yyyy')==='01-01-1970' ?'N/A' :  format(new Date(validTo), 'dd-MM-yyyy') : 'N/A';
 const cellAddress = utils.encode_cell({
   c: 0 ,
   r: row + 1 +3,
 });
 ws['!merges'] = ws['!merges'] || [];
 ws['!merges'].push({
   s: { c: 0, r: row + 1 +3},
   e: { c: 6, r: row + 1 +3 },
 });

//  ws[cellAddress] = { v: `Valid From: ${formattedValidFrom} => Valid To ${formattedValidTo} `,  s: {
//    fill: {
//      patternType: 'solid',
//      fgColor: { rgb: 'FFFF00' }, // Yellow background
//    },
//    alignment: { horizontal: 'center', vertical: 'center', wrapText: true },
//    font: { name: 'Arial', bold: true, sz: 10 }, // Bold and Arial
//  }, };
  }
 
 // ws[`A${lastRow + 1}`] = { v: `Valid To: ${formattedValidTo}`, t: 's' };
  // Define the range of the worksheet
  const range = {
    s: { c: 0, r: 3 },
    e: { c: col * Math.floor(columnWidths[0] / 30.5) , r: row +1 +3 },
  };
  ws['!ref'] = utils.encode_range(range);

  // Create a workbook and add the worksheet
  const wb = utils.book_new();
  if(name != null && name != '') {
    utils.book_append_sheet(wb, ws, `Shelf  ${id}`);

  }else {
    utils.book_append_sheet(wb, ws, `Shelf  ${id}`);

  }

   // Write the Excel file to a blob
   const buffer = write(wb, { type: 'array' });
   const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

   return blob;
  // writeFile(wb, fileName);

  // Export the Excel file
};

 const mergeExcelBlobs = async (blobs: Blob[]) => {
  const combinedWorkbook = new ExcelJS.Workbook();

  for (let index = 0; index < blobs.length; index++) {
    const blob = blobs[index];

    // Load the blob into a temporary workbook
    const buffer = await blob.arrayBuffer();
    const tempWorkbook = new ExcelJS.Workbook();
    await tempWorkbook.xlsx.load(buffer);
    const tempSheet = tempWorkbook.getWorksheet(1);

    if (!tempSheet) {
      console.warn(`Sheet not found in blob ${index + 1}.`);
      continue;
    }

    // Create a new sheet in the combined workbook for each blob
    const sheetName = `Sheet ${index + 1}`;
    const newSheet = combinedWorkbook.addWorksheet(sheetName);

    // Copy rows, values, and styles
    tempSheet.eachRow({ includeEmpty: true }, (row, rowIndex) => {
      const newRow = newSheet.getRow(rowIndex);
      newRow.height = row.height || 15; // Set row height
      row.eachCell((cell, colNumber) => {
        const newCell = newRow.getCell(colNumber);
        newCell.value = cell.value;
        newCell.style = cell.style;
        newCell.border = cell.border;
        newCell.alignment = cell.alignment;
        newCell.fill = cell.fill; // Retain cell fills
      });
    });

    // Handle merged cells
    if (tempSheet.model?.merges) {
      for (const mergeRange of tempSheet.model.merges) {
        newSheet.mergeCells(mergeRange);
      }
    }

    // Adjust column widths
    tempSheet.columns.forEach((col, index) => {
      if (col.width) {
        newSheet.getColumn(index + 1).width = col.width;
      }
    });

    // Extract and copy images
    const images = tempSheet.getImages();
    for (const image of images) {
      const imageId = image.imageId;
      console.log('imageInfo',image)
      const img = tempWorkbook.model.media.find((m, mediaIndex) => mediaIndex.toString() === imageId.toString());

      if (img) {
        const newImageId = combinedWorkbook.addImage({
          buffer: img.buffer,
          extension: img.extension as any,
        });

        newSheet.addImage(newImageId, {
          tl: { col: image.range.tl.col, row: image.range.tl.row },
          ext: {
            width: ((image as any).range.ext.width) , // Approx. column width in points
            height: ( (image as any).range.ext.height) , // Approx. row height in points
          },
        });
      } else {
        console.warn(`Image with ID ${imageId} not found in media collection for blob ${index + 1}.`);
      }
    }
  }

  // Export the combined workbook as a new blob
  const buffer = await combinedWorkbook.xlsx.writeBuffer();
  return new Blob([buffer], {
    type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  });
};















useImperativeHandle(ref, () => ({
triggerChildFunction: handleExportToPDF,
triggerSecondChildFunction:async ()=>{
  
        
        
  //  const blob =   await exportToExcel(images,imagesLaying,columnWidths,row,col,shelfDuplex,RRPArea,marketingMaterialArea,ledScreenArea)
    if(id) {
        //  const blob =   await exportToExcel(images,imagesLaying,columnWidths,row,col,shelfDuplex,RRPArea,marketingMaterialArea,ledScreenArea)
        const shelfConfig = await  getShelfConfigById(id);
        let validFrom = null 
        let  validTo =null 
        if (shelfConfig.validFrom) {
          const validFromDate = new Date(shelfConfig.validFrom);
          validFromDate.setMinutes(validFromDate.getMinutes() + validFromDate.getTimezoneOffset());
          validFrom = (validFromDate);
        }
    
        if (shelfConfig.validTo) {
          const validToDate = new Date(shelfConfig.validTo);
          validToDate.setMinutes(validToDate.getMinutes() + validToDate.getTimezoneOffset());
          validTo = validToDate 
        }
      const blob =   await exportToExcelForBluk(
        shelfConfig,
        exportOptions,id)
      const formattedValidFrom = validFrom ? format(new Date(validFrom), 'dd-MM-yyyy')==='01-01-1970' ?'N/A' :  format(new Date(validFrom), 'dd-MM-yyyy') : 'N/A';
 const formattedValidTo = validTo ? format(new Date(validTo), 'dd-MM-yyyy')==='01-01-1970' ?'N/A' :  format(new Date(validTo), 'dd-MM-yyyy') : 'N/A';
      const pngDataUrl = await handleExportToPNG();
          console.log('png export done')
     const blob2 =  pngDataUrl &&    await insertBase64ImageIntoExcelBlob(blob, pngDataUrl,shelfConfig.name,`Valid From: ${formattedValidFrom} => Valid To ${formattedValidTo} `,shelfConfig.id,shelfConfig.row_position);
     const mergedBlob = await mergeExcelBlobs([ blob2 as Blob]);

     // To download the merged file
     const link = document.createElement('a');
     link.href = URL.createObjectURL(mergedBlob);
     link.download = `${shelfConfig?.name}-${shelfConfig?.id}.xlsx`;
     document.body.appendChild(link);
     link.click();
     document.body.removeChild(link);  
    
    }
  
  
  
}
}));
  
  const handleSquareClick = (colIndex: number, rowIndex: number,boxIndex:number,isDuplex?:boolean) => {
    console.log('isDuplex',isDuplex)
    setSelectedSquare({ col: colIndex, row: rowIndex, z:boxIndex,isDuplex});
  };

  const [facedArray, setFacedArray] = useState<string[]>([]);
  const [facedArrayNaming, setFacedArrayNaming] = useState<string[]>([]);
  const [facedArrayWidths, setFacedArrayWidths] = useState<string[]>([]);
  const [facedArrayHeights, setFacedArrayHeights] = useState<string[]>([]);

  const [facedArrayEan, setFacedArrayEan] = useState<string[]>([]);

  const [facedArrayLaying, setFacedArrayLaying] = useState<string[]>([]);



   const columnWidths = widths

  const initializeSelectedBoxes = () => {
    const savedBoxes = ([] as any ) ;


    if (savedBoxes.length > 0) {
      if(savedBoxes.length < (col || 2)){
           let diffrence= (col || 2)-savedBoxes.length 
           let diffrenceColumnWidths = columnWidths.slice(-diffrence);
           let newArray = diffrenceColumnWidths.map(columnWidth => {
            const boxesInColumn = Math.floor(columnWidth / 30.5);
            return new Array((row || 2))
              .fill(null)
              .map(() => new Array(boxesInColumn).fill(false));
          });
          saveToJson('selectedBoxesDuplex', [...savedBoxes,...newArray]);

          return [...savedBoxes,...newArray]
      }else if (savedBoxes.length > (col || 2)){
        let diffrence= savedBoxes.length - (col || 2)
        console.log("the console ")
        console.log(savedBoxes)


        saveToJson('selectedBoxesDuplex', [...savedBoxes.slice(0, -diffrence)]);

       return [...savedBoxes.slice(0, -diffrence)]
      }else {
        const rowNumber = row || 2; // Default to 2 if not specified
        return columnWidths.map((columnWidth, columnIndex) => {
          const boxesInColumn = Math.floor(columnWidth / 30.5);
          return new Array(rowNumber)
            .fill(null)
            .map((_, rowIndex) => {
              // Check if the oldSavedBoxes has a row at this index and column
              const oldRow = savedBoxes[columnIndex] && savedBoxes[columnIndex][rowIndex];
              return new Array(boxesInColumn)
                .fill(null)
                .map((_, boxIndex) => {
                  // Preserve the state of existing boxes, initialize new ones as false
                  return oldRow && oldRow[boxIndex] !== undefined ? oldRow[boxIndex] : false;
                });
            });
        });
      }
      return savedBoxes;
    } else {
      return columnWidths.map(columnWidth => {
        const boxesInColumn = Math.floor(columnWidth / 30.5);
        return new Array(row || 2)
          .fill(null)
          .map(() => new Array(boxesInColumn).fill(false));
      });
    }
  };
  const [selectedBoxes, setSelectedBoxes] = useState(() => initializeSelectedBoxes());

  useEffect(() => {
    saveToJson('selectedBoxesDuplex', selectedBoxes);
  }, [selectedBoxes]);
  
  const initializeSelectedBoxesPMI = () => {
    const savedBoxes = selectedBoxesPmi || [] ;

    console.log(col )
    console.log(savedBoxes)
    if (savedBoxes.length > 0) {
      if(savedBoxes.length < (col || 2)){
           let diffrence= (col || 2)-savedBoxes.length 
           let diffrenceColumnWidths = columnWidths.slice(-diffrence);
           let newArray = diffrenceColumnWidths.map(columnWidth => {
            const boxesInColumn = Math.floor(columnWidth / 30.5);
            return new Array((row || 2))
              .fill(null)
              .map(() => new Array(boxesInColumn).fill(false));
          });
          saveToJson('selectedBoxes', [...savedBoxes,...newArray]);

          return [...savedBoxes,...newArray]
      }else if (savedBoxes.length > (col || 2)){
        let diffrence= savedBoxes.length - (col || 2)
        console.log("the console ")
        console.log(savedBoxes)


        saveToJson('selectedBoxes', [...savedBoxes.slice(0, -diffrence)]);

       return [...savedBoxes.slice(0, -diffrence)]
      }else {
        const rowNumber = row || 2; // Default to 2 if not specified
        return columnWidths.map((columnWidth, columnIndex) => {
          const boxesInColumn = Math.floor(columnWidth / 30.5);
          return new Array(rowNumber)
            .fill(null)
            .map((_, rowIndex) => {
              // Check if the oldSavedBoxes has a row at this index and column
              const oldRow = savedBoxes[columnIndex] && savedBoxes[columnIndex][rowIndex];
              return new Array(boxesInColumn)
                .fill(null)
                .map((_, boxIndex) => {
                  // Preserve the state of existing boxes, initialize new ones as false
                  return oldRow && oldRow[boxIndex] !== undefined ? oldRow[boxIndex] : false;
                });
            });
        });
      }
      return savedBoxes;
    } else {
      return columnWidths.map(columnWidth => {
        const boxesInColumn = Math.floor(columnWidth / 30.5);
        return new Array(row|| 2)
          .fill(null)
          .map(() => new Array(boxesInColumn).fill(false));
      });
    }
  };
 
  const [selectedBoxesPMI, setSelectedBoxesPMI] = useState(() => initializeSelectedBoxesPMI());

  const [selectionBox, setSelectionBox] = useState({ x: 0, y: 0, width: 0, height: 0 });
  const [isSelecting, setIsSelecting] = useState(false);
  const containerRef = useRef<HTMLDivElement>(null);
  const [mergedBoxes, setMergedBoxes] = useState<
    Array<{ x: number; y: number; width: number; height: number }>
  >([]);


  const handleMouseUp = (width: number,height: number,x:number,y:number) => {
    // Constants
    const boxWidth = 30.5; // Fixed width of each box
    const boxHeight = 51.5;


  
    const selectionStartX = x;
    const selectionEndX = selectionStartX + (width)  ;
    console.log(selectionStartX)

    const selectionStartY = y;
    console.log(selectionEndX)
    const selectionEndY = selectionStartY + height;
  
    // Calculate column offsets dynamically based on actual column widths
    let columnOffsets = columnWidths.reduce((acc:any, curr, idx) => {
      if (idx === 0) {
        acc.push(0);
      } else {
        acc.push(acc[idx - 1] + columnWidths[idx - 1]);
      }
      return acc;
    }, []);
    let intersectingBoxCount = 0;
  let wasAlreadySelected = false;
  let indexRow:number = -1 
  let indexCol: number= -1
  let IndexBox :number= -1

    let updatedSelectedBoxes = selectedBoxesPMIOverLay.map((customBox, customBoxIndex) => 
      customBox.map((set, setIndex) => 
        set.map((isSelected, boxIndex) => {
          const columnOffset = columnOffsets[customBoxIndex];
          const dynamicBoxCount = Math.floor(columnWidths[customBoxIndex] / boxWidth); 
          
          // Calculate boxes per column dynamically
          //if (boxIndex >= dynamicBoxCount) return false;
           // Ignore boxes that exceed the dynamic count
  
          const boxStartX = columnOffset + (boxIndex * (columnWidths[customBoxIndex]/dynamicBoxCount  )) +(((rowDistance as number) * 5 || 2)* customBoxIndex) ;
          const boxEndX = boxStartX + (columnWidths[customBoxIndex]/dynamicBoxCount  );
          const boxTopY = (setIndex) * (boxHeight +((colDistance as number) * 5 )) ; // Adjust if your layout differs
          const boxBottomY = boxTopY + (boxHeight  );
  
          // Check if the box intersects with the selection area
          const intersectsSelection = !(
            boxEndX <= selectionStartX ||
            boxStartX >= selectionEndX ||
            boxBottomY <= selectionStartY ||
            boxTopY >= selectionEndY
          );
          if (intersectsSelection ) {
            if(indexRow!=customBoxIndex && indexCol!=setIndex && IndexBox!=boxIndex)
            {
              intersectingBoxCount++;

            }
            if (isSelected) wasAlreadySelected = true;
            indexRow=customBoxIndex
            indexCol=setIndex
            IndexBox=boxIndex
         

          }

  
          return  intersectsSelection;
        })
      )
    );
   
    return updatedSelectedBoxes

  
  };

  const GetAllOverLayBoxs = () => {

      let pink = pinkSquares.map(({width,height,x,y})=>{
         return handleMouseUp((width*5),(height*5),x,y)
      })
     
      let yellow =  yellowSquares.map(({width,height,x,y})=>{
       return handleMouseUp((width*5),(height*5),x,y)

      })
      let blue =  blueSquares.map(({width,height,x,y})=>{
        return  handleMouseUp(width,height,x,y)
       })
    
      if(pink.length > 0 ||yellow.length > 0  || blue.length > 0  ){
        setSelectedBoxesPMIOverLay(combineArrays([...pink,...yellow,...blue]))
        setRRPArea(blue)
        setMarketingMaterialArea(yellow)
        setLedScreenArea(pink)

      }else {
     // setSelectedBoxesPMIOverLay([...selectedBoxesPmi])

      }
    

  }
  const combineArrays = (arrays: Array3D[]): Array3D => {
    if (arrays.length === 0) {
        // Handle the case where no arrays are provided
        return [];
    }

    // Find the maximum depth and rows across all arrays
    const maxDepth = arrays.reduce((acc, curr) => Math.max(acc, curr.length), 0);
    const maxRows = arrays.reduce((acc, curr) => Math.max(acc, curr.reduce((accRow, currRow) => Math.max(accRow, currRow.length), 0)), 0);

    // Initialize the result array with appropriate depth and rows but no columns yet
    const result: Array3D = Array.from({ length: maxDepth }, () =>
        Array.from({ length: maxRows }, () => [])
    );

    // Determine the maximum columns for each specific depth and row and fill with `false`
    for (let d = 0; d < maxDepth; d++) {
        for (let r = 0; r < maxRows; r++) {
            let maxCols = 0;
            // Find the maximum column size for the current depth and row across all arrays
            arrays.forEach(array => {
                if (array.length > d && array[d].length > r) {
                    maxCols = Math.max(maxCols, array[d][r].length);
                }
            });
            // Initialize columns for the current depth and row
            result[d][r] = Array.from({ length: maxCols }, () => false);
        }
    }

    // Iterate over every array and every element to apply the logical OR operation
    arrays.forEach(array => {
        for (let d = 0; d < array.length; d++) {
            for (let r = 0; r < array[d].length; r++) {
                for (let c = 0; c < array[d][r].length; c++) {
                    result[d][r][c] = result[d][r][c] || array[d][r][c];
                }
            }
        }
    });

    return result;
};
 
const [RRPArea, setRRPArea] = useState<any[][][]>([])
const [marketingMaterialArea, setMarketingMaterialArea] = useState<any[][][]>([])
const [ledScreenArea, setLedScreenArea] = useState<any[][][]>([])

  const [selectedBoxesPMIOverLay, setSelectedBoxesPMIOverLay] = useState<any[][][]>(columnWidths.map(columnWidth => {
    const boxesInColumn = Math.floor(columnWidth / 30.5);
    return new Array(row )
      .fill(null)
      .map(() => new Array(boxesInColumn).fill(false));
  }));
  const updatePmiPositions = (shelfDuplex: Array3D, pmiPositions: Array3D): Array3D => {
    // Create a deep copy of pmiPositions to avoid mutating the original array
    const updatedPmiPositions = pmiPositions.map(layer => layer.map(row => [...row]));

    shelfDuplex.forEach((layer, layerIndex) => {
      layer.forEach((row, rowIndex) => {
        row.forEach((hasDuplexPusher, colIndex) => {
          if (hasDuplexPusher) {
            // If the next position is within bounds and not already true, insert true
            if (colIndex + 1 < row.length) {
            
                updatedPmiPositions[layerIndex][rowIndex].splice(colIndex + 1, 0, true);
              
            } else {
              // If the next position is out of bounds, push true to the end
              updatedPmiPositions[layerIndex][rowIndex].push(true);
            }
          }
        });
      });
    });
console.log('updatedPmiPositions',updatedPmiPositions)
  return updatedPmiPositions;
};
const updatePmiPositions2 = (shelfDuplex: Array3D, pmiPositions: Array3D): Array3D => {
  // Create a deep copy of pmiPositions to avoid mutating the original array
  const updatedPmiPositions = pmiPositions.map(layer => layer.map(row => [...row]));

  shelfDuplex.forEach((layer, layerIndex) => {
    layer.forEach((row, rowIndex) => {
      row.forEach((hasDuplexPusher, colIndex) => {
        if (hasDuplexPusher ) {
          // If the next position is within bounds and not already true, insert true
          if (colIndex + 1 < row.length && !selectedBoxesPMIOverLay[layerIndex]?.[rowIndex]?.[colIndex] ) {
          
              updatedPmiPositions[layerIndex][rowIndex].splice(colIndex + 1, 0, false);
            
          } else if( !selectedBoxesPMIOverLay[layerIndex]?.[rowIndex]?.[colIndex] ) {
            // If the next position is out of bounds, push true to the end
            updatedPmiPositions[layerIndex][rowIndex].push(false);
          }else {
            updatedPmiPositions[layerIndex][rowIndex].push(true);

          }
        }
      });
    });
  });
console.log('updatedPmiPositions',updatedPmiPositions)
return updatedPmiPositions;
}; 
  useEffect(()=>{
    console.log('the test',selectedBoxesPMIOverLay)
    GetAllOverLayBoxs()
    updatePmiPositions(shelfDuplex || [],selectedBoxesPmi|| [])

  },[])
  const renderBoxes = (customBoxIndex:number, setIndex:number, totalWidth:number) => {
    // Fixed box width
    const boxWidth = 30.5; // Fixed width of each box
    
    // Calculate the dynamic number of boxes that can fit in the totalWidth of the column
    const dynamicBoxCount = Math.floor(totalWidth / boxWidth);
    console.log(squaresWithImages)
    const duplexPushers = shelfDuplex || [];
    let nextAvailableIndex = 0;
   
    return Array.from({ length: dynamicBoxCount }, (_, boxIndex) => {
      const currentIndex = nextAvailableIndex;
      const nextIndex = currentIndex + 1;
      const imageSrc =  images[customBoxIndex]?.[setIndex]?.[currentIndex]?.toString()?.replace('/sku_images','/sku_resized')?.replace(/\s/g, '%20').replace(/\(/g, '%28').replace(/\)/g, '%29');
      const imageName =  naming[customBoxIndex]?.[setIndex]?.[currentIndex]
      const imageNameLaying = naming[customBoxIndex]?.[setIndex]?.[currentIndex]
      const nextImageNameLaying = naming[customBoxIndex]?.[setIndex]?.[nextIndex]
      const imageWidth= packWidths[customBoxIndex]?.[setIndex]?.[currentIndex]
      const imageHeight= packheights[customBoxIndex]?.[setIndex]?.[currentIndex]

      console.log('imageWidth',imageWidth)
      //  const nextImageSrc = images[customBoxIndex]?.[setIndex]?.[nextIndex]?.replace(/\s/g, '%20').replace(/\(/g, '%28').replace(/\)/g, '%29');
      const imageSrcLaying = imagesLaying[customBoxIndex]?.[setIndex]?.[currentIndex]?.toString()?.replace('/sku_images','/sku_resized')?.replace(/\s/g, '%20').replace(/\(/g, '%28').replace(/\)/g, '%29');
     console.log('imagesLaying22',imagesLaying)
      const nextImageSrcLaying = imagesLaying[customBoxIndex]?.[setIndex]?.[nextIndex]?.toString()?.replace('/sku_images','/sku_resized')?.replace(/\s/g, '%20').replace(/\(/g, '%28').replace(/\)/g, '%29');
     
      const isSelected = selectedSquare && selectedSquare.col === customBoxIndex && selectedSquare.row === setIndex && selectedSquare.z === currentIndex;
      const isSelectedSecond = selectedSquare && selectedSquare.col === customBoxIndex && selectedSquare.row === setIndex && selectedSquare.z === (nextIndex );
      const isDuplexPusher = (duplexPushers[customBoxIndex]?.[setIndex]?.[boxIndex] && !selectedBoxesPMIOverLay[customBoxIndex]?.[setIndex]?.[boxIndex]) || false;
      // Increment the index for the next available box
      if (isDuplexPusher) {
        nextAvailableIndex += 2; // Skip next index as it's used in duplex pusher
      } else {
        nextAvailableIndex += 1;
      }
  
      return    <Tooltip key={`${customBoxIndex}-${setIndex}-${boxIndex}-duplex-1`}  arrow title={!isDuplexPusher ? (imageName ? imageName?.charAt(0).toUpperCase() + imageName.slice(1) : '') : ''}
> 
 <div
        key={`${customBoxIndex}-${setIndex}-${boxIndex}`}
        className={`${classes.whiteBox} ${selectedBoxesPmi[customBoxIndex]?.[setIndex]?.[boxIndex] ? classes.selectedBox : ""} ${
          selectedBoxes[customBoxIndex]?.[setIndex]?.[boxIndex]
            ? classes.whiteBoxSelected
            : ''
        }  ${
          selectedBoxesPMIOverLay[customBoxIndex]?.[setIndex]?.[boxIndex]
            ? classes.OverlayBox
            : ''
        } `} 
        onClick={!isDuplexPusher ? () => handleSquareClick(customBoxIndex, setIndex, currentIndex,isDuplexPusher) : undefined}

        style={{
          width: `${boxWidth}px`,
          backgroundImage: !isDuplexPusher && imageSrc ? `url(${imageSrc})` : '',
          backgroundPosition: "center bottom",
          backgroundRepeat: "no-repeat",
          //backgroundSize: "contain",
          backgroundSize: isNaN(parseFloat(imageWidth)) || isNaN(parseFloat(imageHeight))
          ? 'contain'
          : `${parseFloat(imageWidth) * 4}px ${parseFloat(imageHeight) * 3.6}px`,
          border: !isDuplexPusher && isSelected ? "2px solid #0179FF" : 0,
        }}     
         >
          {isDuplexPusher && (
            <>
                  <Tooltip key={`${customBoxIndex}-${setIndex}-${boxIndex}-duplex-1`} arrow placement='top' title={imageNameLaying ? imageNameLaying.charAt(0).toUpperCase() + imageNameLaying.slice(1) : ''}
                  > 

              <div
                style={{
                  width: '100%',
                  height: '50%',
                  boxSizing:'border-box',
                  border: isSelected ? "2px solid #0179FF" : 0,

                  margin:0

                }}
                className={`${classes.whiteBox} ${selectedBoxesPMI[customBoxIndex]?.[setIndex]?.[currentIndex] ? classes.selectedBox : ""} ${
                  selectedBoxes[customBoxIndex]?.[setIndex]?.[currentIndex]
                    ? classes.whiteBoxSelected
                    : ''
                }  ${
                  selectedBoxesPMIOverLay[customBoxIndex]?.[setIndex]?.[currentIndex]
                    ? classes.OverlayBox
                    : ''
                } `}
                onClick={() => handleSquareClick(customBoxIndex, setIndex, currentIndex,isDuplexPusher)}
              >
                <div style={{ width: '100%', height: '40%', backgroundColor: '#9747FF',borderRadius: '2px', backgroundImage: imageSrc? `url(${imageSrcLaying})` : '',
                  backgroundPosition: "center",
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "contain",
                  marginBottom:'1px',

}} />
            <div style={{ width: '100%', height: '40%', backgroundColor: '#9747FF',borderRadius: '2px' ,marginBottom:'2px', backgroundImage: imageSrc ? `url(${imageSrcLaying})` : '',
                  backgroundPosition: "center",
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "contain",}} />
              </div>
              </Tooltip>
              <Tooltip key={`${customBoxIndex}-${setIndex}-${boxIndex}-duplex`} arrow title={nextImageNameLaying ? nextImageNameLaying.charAt(0).toUpperCase() + nextImageNameLaying.slice(1) : ''}
              > 

              <div
                style={{
                  width: '100%',
                  height: '50%',
                  margin:0,
                  boxSizing:'border-box',

                  border: isSelectedSecond ? "2px solid #0179FF" : 0,
                  backgroundColor:'transparent !important',
                }}
                className={`${classes.whiteBox} ${selectedBoxesPMI[customBoxIndex]?.[setIndex]?.[nextIndex] ? classes.selectedBox : ""} ${
                  selectedBoxes[customBoxIndex]?.[setIndex]?.[nextIndex]
                    ? classes.whiteBoxSelected
                    : ''
                }  ${
                  selectedBoxesPMIOverLay[customBoxIndex]?.[setIndex]?.[nextIndex]
                    ? classes.OverlayBox
                    : ''
                } `}
                onClick={() => handleSquareClick(customBoxIndex, setIndex, nextIndex,isDuplexPusher)}
              >
                 <div style={{ width: '100%', height: '40%', backgroundColor: '#9747FF',borderRadius: '2px',   backgroundImage: nextImageSrcLaying ? `url(${nextImageSrcLaying})` : '',
                  backgroundPosition: "center",
                  backgroundRepeat: "no-repeat",
                  marginBottom:'1px',
                  backgroundSize: "contain", 
                  }} />
                 <div style={{ width: '100%', height: '40%', backgroundColor: '#9747FF' ,borderRadius: '2px',   backgroundImage: nextImageSrcLaying ? `url(${nextImageSrcLaying})` : '',
                  backgroundPosition: "center",
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "contain",}} />

              </div>
              </Tooltip>
            </>
          )} 
         </div>
         </Tooltip>
      });
  };

  function createFacingArray(n:any, minimumFacing:any, packOrder:any, increaseOrder:any, decreaseOrder:any) {
    // Initialize the array without null values initially
    let resultArray = [];

    // Create a map from minimumFacing for quick lookup
    const facingMap = new Map(minimumFacing.map((item:any) => [item.title.trim(), item.minFacing]));

    // Fill the array based on the order and minFacing values
    for (let pack of packOrder) {
        pack = pack.trim(); // Clean any whitespace
        if (facingMap.has(pack)) {
            let count = facingMap.get(pack);
            for (let i = 0; i < (count as any); i++) {
                resultArray.push(pack);
            }
        }
    }

    // Handle the case where the initial array is less than n
    let currentIndex = resultArray.length;
    while (currentIndex < n) {
        for (let pack of increaseOrder) {
            if (currentIndex >= n) break;
            pack = pack.trim();
            resultArray.push(pack);
            currentIndex++;
        }
    }

    // If the total exceeds n, start decreasing according to the decreaseOrder
    currentIndex = resultArray.length;
    if (currentIndex > n) {
        let decreaseIndex = 0;
        while (currentIndex > n) {
            let packToRemove = decreaseOrder[decreaseIndex % decreaseOrder.length].trim();
            let removalIndex = resultArray.lastIndexOf(packToRemove);
            if (removalIndex !== -1) {
                resultArray.splice(removalIndex, 1);
                currentIndex--;
            }
            decreaseIndex++;
        }
    }

    // Fill any remaining 'null' slots if needed (safety check)
    resultArray = resultArray.concat(new Array(n - resultArray.length).fill(null));

    return resultArray;
}



function combineBooleanArrays(array1: boolean[][][], array2: boolean[][][]): boolean[][][] {
  // Check if both arrays have the same dimensions

  if (array1.length !== array2.length) {
    throw new Error('Arrays do not have the same dimensions');
  }

  const resultArray: boolean[][][] = [];

  // Iterate over the first dimension
  for (let i = 0; i < array1.length; i++) {
    if (array1[i].length !== array2[i].length) {
      console.log("dimentions test")
      console.log(array1[i].length)
      console.log(array2[i].length)
      throw new Error('Arrays do not have the same dimensions');
    }

    const layer: boolean[][] = [];

    // Iterate over the second dimension
    for (let j = 0; j < array1[i].length; j++) {
      if (array1[i][j].length !== array2[i][j].length) {
        console.log("dimentions test")
        console.log(array1[i][j].length)
        console.log(array2[i].length)
        throw new Error('Arrays do not have the same dimensions');
      }

      const row: boolean[] = [];

      // Iterate over the third dimension and apply the OR operation
      for (let k = 0; k < array1[i][j].length; k++) {
        row.push(array1[i][j][k] || !array2[i][j][k]);
      }

      layer.push(row);
    }

    resultArray.push(layer);
  }

  return resultArray;
}



const combinedArray =    combineBooleanArrays( updatePmiPositions2(shelfDuplex || [],selectedBoxesPMIOverLay) ,     updatePmiPositions(shelfDuplex || [],selectedBoxesPMI || []))


const flatArray = combinedArray.flat(Infinity);



const [falseCount,setFalseCount] = useState(flatArray.filter((item:any) => item === false).length)
useEffect(()=>{
  console.log('shelfDuplextest',shelfDuplex)
  console.log('shelfDuplextest',selectedBoxesPMI)

  const combinedArray =    combineBooleanArrays( updatePmiPositions2(shelfDuplex || [],selectedBoxesPMIOverLay) ,     updatePmiPositions(shelfDuplex || [],selectedBoxesPMI || []))
  const falseCountGet = flatArray.filter((item:any) => item === false).length;
  setFalseCount(falseCountGet)
},[shelfDuplex,selectedBoxesPMIOverLay,selectedBoxesPMI])

const [images,setImages]  = useState <Array<Array<Array<string>>>>([])
const [naming,setNaming]  = useState <Array<Array<Array<string>>>>([])
const [packWidths,setPackWidths]  = useState <Array<Array<Array<string>>>>([])
const [packheights,setPackHeights]  = useState <Array<Array<Array<string>>>>([])

const [ean,setEan]  = useState <Array<Array<Array<string>>>>([])

const [imagesLaying,setImagesLaying]  = useState <Array<Array<Array<string>>>>([])
const [isAuto,setIsAuto] = useState(false)
useEffect(()=>{

  setIsAuto(isAutoConfiguration)
},[isAutoConfiguration])
useEffect(()=>{
  let cloneSquaresWithImages =replaceWithEmptyString(selectedBoxesPMIOverLay)
  let cloneSquaresWithNaming =replaceWithEmptyString(selectedBoxesPMIOverLay)
  let cloneSquaresWithEan =replaceWithEmptyString(selectedBoxesPMIOverLay)
  let cloneSquaresWithWidths =replaceWithEmptyString(selectedBoxesPMIOverLay)
  let cloneSquaresWithHeights =replaceWithEmptyString(selectedBoxesPMIOverLay)

  let cloneSquaresWithImagesLaying =replaceWithEmptyString(selectedBoxesPMIOverLay)
console.log('facedArrayLaying',facedArrayLaying)
  let ind = 0
  if(selectedBoxesPMIOverLay.length > 0) {
    const numRows = selectedBoxesPMIOverLay.length;
    const numCols = selectedBoxesPMIOverLay[0].length;
    console.log("numRows",selectedBoxesPMIOverLay)
    for (let columnIndex = 0; columnIndex < numCols; columnIndex++) {
      const newRow = [];
      for (let rowIndex = 0; rowIndex < numRows; rowIndex++) {
        combinedArray[rowIndex][columnIndex].forEach((_item, box) => {
          if(!combinedArray[rowIndex][columnIndex][box] && !squaresWithImages[rowIndex]?.[columnIndex]?.[box]) {
            cloneSquaresWithImages[rowIndex][columnIndex][box]= !isAuto ? facedArray[ind] : ''
            ind ++;
          } else if(squaresWithImages[rowIndex]?.[columnIndex]?.[box]) {
            cloneSquaresWithImages[rowIndex][columnIndex][box]=squaresWithImages[rowIndex]?.[columnIndex]?.[box]
            ind ++;
          }else {
            console.log("pk")
          }
        })
     
     
      }
    }
  }
  ind = 0
  if(selectedBoxesPMIOverLay.length > 0) {
    const numRows = selectedBoxesPMIOverLay.length;
    const numCols = selectedBoxesPMIOverLay[0].length;
    console.log("numRows",selectedBoxesPMIOverLay)
    for (let columnIndex = 0; columnIndex < numCols; columnIndex++) {
      const newRow = [];
      for (let rowIndex = 0; rowIndex < numRows; rowIndex++) {
        combinedArray[rowIndex][columnIndex].forEach((_item, box) => {
          if(!combinedArray[rowIndex][columnIndex][box] && !squaresWithImages[rowIndex]?.[columnIndex]?.[box]) {
            cloneSquaresWithImagesLaying[rowIndex][columnIndex][box]= !isAuto ?  facedArrayLaying[ind] : ''
            ind ++;
          } else if(squaresWithImages[rowIndex]?.[columnIndex]?.[box]) {
            cloneSquaresWithImagesLaying[rowIndex][columnIndex][box]=squaresWithImages[rowIndex]?.[columnIndex]?.[box]
            ind ++;
          }else {
            console.log("pk")
          }
        })
     
     
      }
    }
  }
  ind = 0
  if(selectedBoxesPMIOverLay.length > 0) {
    const numRows = selectedBoxesPMIOverLay.length;
    const numCols = selectedBoxesPMIOverLay[0].length;
    console.log("numRows",selectedBoxesPMIOverLay)
    for (let columnIndex = 0; columnIndex < numCols; columnIndex++) {
      const newRow = [];
      for (let rowIndex = 0; rowIndex < numRows; rowIndex++) {
        combinedArray[rowIndex][columnIndex].forEach((_item, box) => {
          if(!combinedArray[rowIndex][columnIndex][box] && !squaresWithImages[rowIndex]?.[columnIndex]?.[box]) {
            cloneSquaresWithNaming[rowIndex][columnIndex][box]= !isAuto ?  facedArrayNaming[ind] : ''
            ind ++;
          } else if(squaresWithImages[rowIndex]?.[columnIndex]?.[box]) {
            cloneSquaresWithNaming[rowIndex][columnIndex][box]=squaresWithNames[rowIndex]?.[columnIndex]?.[box]
            ind ++;
          }else {
            console.log("pk")
          }
        })
     
     
      }
    }
  }
  

  ind = 0
  if(selectedBoxesPMIOverLay.length > 0) {
    const numRows = selectedBoxesPMIOverLay.length;
    const numCols = selectedBoxesPMIOverLay[0].length;
    console.log("numRows",selectedBoxesPMIOverLay)
    for (let columnIndex = 0; columnIndex < numCols; columnIndex++) {
      const newRow = [];
      for (let rowIndex = 0; rowIndex < numRows; rowIndex++) {
        combinedArray[rowIndex][columnIndex].forEach((_item, box) => {
          if(!combinedArray[rowIndex][columnIndex][box] && !squaresWithImages[rowIndex]?.[columnIndex]?.[box]) {
            cloneSquaresWithEan[rowIndex][columnIndex][box]= !isAuto ?  facedArrayEan[ind] : ''
            ind ++;
          } else if(squaresWithImages[rowIndex]?.[columnIndex]?.[box]) {
            cloneSquaresWithEan[rowIndex][columnIndex][box]=squaresWithEan[rowIndex]?.[columnIndex]?.[box]
            ind ++;
          }else {
            console.log("pk")
          }
        })
     
     
      }
    }
  }
  ind = 0
  if(selectedBoxesPMIOverLay.length > 0) {
    const numRows = selectedBoxesPMIOverLay.length;
    const numCols = selectedBoxesPMIOverLay[0].length;
    console.log("numRows",selectedBoxesPMIOverLay)
    for (let columnIndex = 0; columnIndex < numCols; columnIndex++) {
      const newRow = [];
      for (let rowIndex = 0; rowIndex < numRows; rowIndex++) {
        combinedArray[rowIndex][columnIndex].forEach((_item, box) => {
          if(!combinedArray[rowIndex][columnIndex][box] && !squaresWithImages[rowIndex]?.[columnIndex]?.[box]) {
            cloneSquaresWithWidths[rowIndex][columnIndex][box]= !isAuto ?  facedArrayWidths[ind] : ''
            ind ++;
          } else if(squaresWithImages[rowIndex]?.[columnIndex]?.[box]) {
            cloneSquaresWithWidths[rowIndex][columnIndex][box]=squaresWithWithds[rowIndex]?.[columnIndex]?.[box]
            ind ++;
          }else {
            console.log("pk")
          }
        })
     
     
      }
    }
  }
  ind = 0
  if(selectedBoxesPMIOverLay.length > 0) {
    const numRows = selectedBoxesPMIOverLay.length;
    const numCols = selectedBoxesPMIOverLay[0].length;
    console.log("numRows",selectedBoxesPMIOverLay)
    for (let columnIndex = 0; columnIndex < numCols; columnIndex++) {
      const newRow = [];
      for (let rowIndex = 0; rowIndex < numRows; rowIndex++) {
        combinedArray[rowIndex][columnIndex].forEach((_item, box) => {
          if(!combinedArray[rowIndex][columnIndex][box] && !squaresWithImages[rowIndex]?.[columnIndex]?.[box]) {
            cloneSquaresWithHeights[rowIndex][columnIndex][box]= !isAuto ?  facedArrayHeights[ind] : ''
            ind ++;
          } else if(squaresWithImages[rowIndex]?.[columnIndex]?.[box]) {
            cloneSquaresWithHeights[rowIndex][columnIndex][box]=squaresWithHeights[rowIndex]?.[columnIndex]?.[box]
            ind ++;
          }else {
            console.log("pk")
          }
        })
     
     
      }
    }
  }

  selectedBoxesPMIOverLay.forEach((row, rowIndex) => {
    row.forEach((boxs, columnIndex) => {
     
  
     
      // Or do whatever you need with each item in the array
    });
  });
  
  console.log("cloneSquaresWithImages",cloneSquaresWithNaming)

  setImages(cloneSquaresWithImages)
  setImagesLaying(cloneSquaresWithImagesLaying)
  setNaming(cloneSquaresWithNaming)
  setPackWidths(cloneSquaresWithWidths)
  setPackHeights(cloneSquaresWithHeights)

  setEan(cloneSquaresWithEan)

},[squaresWithImages,facedArray,isAuto,falseCount])
function replaceWithEmptyString(arr: any[]): any[] {
  return arr.map(item => {
    // If the item is an array, recurse into it
    if (Array.isArray(item)) {
      return replaceWithEmptyString(item);
    }
    // Otherwise, replace the item with an empty string
    return "";
  });
}
function orderByCustomOrder(stringsArray: string[], orderArray: string[]): string[] {
  return stringsArray.sort((a, b) => orderArray.indexOf(a) - orderArray.indexOf(b));
}


useEffect(() => {
  // Fetch all the data on component mount
  const fetchData = async () => {
    if(keyAccountId) {
      const [packOrderData, packOrderChesterData,getSkusOrderedByIncreaseOrderMarlboro2,getSkusOrderedByDecreaseOrderMarlboro2,getSkusOrderedByIncreaseOrderChesterfield2,getSkusOrderedByDecreaseOrderChesterfield2,getSkusWithMinFacingsMarlboro2,getSkusWithMinFacingsChesterfield2,skusList,configuration,skuOrderByBrand,orderedBrands] = await Promise.all([
        fetchSkusOrderedMarlboro(),
        fetchSkusOrderedChesterfield(),
        getSkusOrderedByIncreaseOrder(),
        getSkusOrderedByDecreaseOrder(),
        getSkusOrderedByIncreaseOrderChesterfield(),
        getSkusOrderedByDecreaseOrderChesterfield(),
        getSkusWithMinFacingsMarlboro(),
        getSkusWithMinFacingsChesterfield(),
        getAllSkus(),
        getConfiguration(),
        getSkusOrdered(),
        getOrderedBrands()
  
      ]);
    
        const MinFacingsKeyAccount = await getSkusWithPriority(keyAccountId, 'CC');
        const marlboroInc = MinFacingsKeyAccount
        .sort((a: any, b: any) => a.increase_order - b.increase_order);
      
      const skuPromises = orderedBrands.map((id) => {
        const brand = brandMapping[id];
        return getSkusOrderedByBrand(brand);
      });
      const results = await Promise.all(skuPromises);
      const combined = results.flat();
  
    
      console.log('combined',combined)
  
      console.log('skuOrderByBrand',orderedBrands)
      console.log('packOrderData',packOrderData.map((item:any) => item.pack_image.split('.')[0]))
      console.log('packOrderChesterData',packOrderChesterData.map((item:any) => item.pack_image.split('.')[0]))
      console.log('getSkusOrderedByIncreaseOrderMarlboro2',getSkusOrderedByIncreaseOrderMarlboro2.map((item:any) => item.pack_image.split('.')[0]))
      console.log('getSkusOrderedByDecreaseOrderMarlboro2',getSkusOrderedByDecreaseOrderMarlboro2.map((item:any) => item.pack_image.split('.')[0]))
      console.log('getSkusOrderedByIncreaseOrderChesterfield2',getSkusOrderedByIncreaseOrderChesterfield2.map((item:any) => item.pack_image.split('.')[0]))
      console.log('getSkusOrderedByDecreaseOrderChesterfield2',getSkusOrderedByDecreaseOrderChesterfield2.map((item:any) => item.pack_image.split('.')[0]))
      console.log('getSkusWithMinFacingsMarlboro',getSkusWithMinFacingsMarlboro2.map((item:any) => {return {minFacing:item.min_facings,title:item.pack_image.split('.')[0]}}))
      console.log('getSkusWithMinFacingsChesterfield',getSkusWithMinFacingsChesterfield2.map((item:any) => {return {minFacing:item.min_facings,title:item.pack_image.split('.')[0]}}))
  
      const packOrder = combined
      .map((item: any) => item.id.toString())
      .filter((id: string) => {
        const minFacingItem = MinFacingsKeyAccount.find(
          (facingItem: any) => facingItem.id.toString() === id
        );
        return minFacingItem ? minFacingItem.min_facings > 0 : true;
      }); 
      console.log('MinFacingsKeyAccountPOack',packOrder)
    
      const increaseOrder = marlboroInc
  .map((item: any) => item.id.toString())
  .filter((id: string) => {
    const minFacingItem = MinFacingsKeyAccount.find(
      (facingItem: any) => facingItem.id.toString() === id
    );
    return minFacingItem ? minFacingItem.min_facings > 0 : true;
  });
  console.log('MinFacingsKeyAccountPOack2',increaseOrder)

// Filter decreaseOrder based on min_facings and reverse it
const decreaseOrder = marlboroInc
  .map((item: any) => item.id.toString())
  .filter((id: string) => {
    const minFacingItem = MinFacingsKeyAccount.find(
      (facingItem: any) => facingItem.id.toString() === id
    );
    return minFacingItem ? minFacingItem.min_facings > 0 : true;
  })
  .reverse();
      const minimumFacing = MinFacingsKeyAccount.map((item: any) => {
        return { minFacing: item.min_facings, title: item.id.toString() };
      });
     
      const facedArrayMalboroNOrder =   createFacingArray( Math.ceil(falseCount * (100 * 0.01)), minimumFacing, packOrder, increaseOrder,decreaseOrder) ;
      const facedArrayMalboro =   orderByCustomOrder(facedArrayMalboroNOrder, packOrder) ;
  console.log('facedArrayMalboro',facedArrayMalboro)
     // Create a mapping of IDs to pack_image
     type PackItem = {
      id: string;
      pack_image: string;
    };
     console.log('skulist',skusList)
     const idToPackImageMap: { [key: string]: string } = {};
     (skusList as PackItem[]).forEach((item: PackItem) => {
       idToPackImageMap[item.id.toString()] = item.pack_image;
     });
     const idToPackImageMapNaming: { [key: string]: string } = {};
     (skusList as any[]).forEach((item: any) => {
      idToPackImageMapNaming[item.id.toString()] = (item as any).brandName + ' ' + (item as any).variantName;
     });
     const idToPackImageMapWidths: { [key: string]: string } = {};
     (skusList as any[]).forEach((item: any) => {
      idToPackImageMapWidths[item.id.toString()] = (item as any).width ;
     });
     const idToPackImageMapHeights: { [key: string]: string } = {};
     (skusList as any[]).forEach((item: any) => {
      idToPackImageMapHeights[item.id.toString()] = (item as any).height ;
     });
     const idToPackImageMapEAN: { [key: string]: string } = {};
     (skusList as any[]).forEach((item: any) => {
      idToPackImageMapEAN[item.id.toString()] = (item as any).ean_code ;
     });
     const idToPackImageMapLaying: { [key: string]: string } = {};
     (skusList as PackItem[]).forEach((item: PackItem) => {
      idToPackImageMapLaying[item.id.toString()] = (item as any).pack_image_laying;
     });
     console.log('idToPackImageMapLaying',idToPackImageMapNaming)
      // Map the IDs to their corresponding pack_image
    const facedArrayMalboroWithImages = facedArrayMalboro.map(id => (idToPackImageMap[id]?.toString() || ''));
    const facedArrayMalboroWithNaming = facedArrayMalboro.map(id => (idToPackImageMapNaming[id]?.toString() || ''));
    const facedArrayMalboroWithWidths = facedArrayMalboro.map(id => (idToPackImageMapWidths[id]?.toString() || ''));
    const facedArrayMalboroWithHeights = facedArrayMalboro.map(id => (idToPackImageMapHeights[id]?.toString() || ''));

    const facedArrayMalboroWithean = facedArrayMalboro.map(id => (idToPackImageMapEAN[id]?.toString() || ''));
  
    const facedArrayMalboroWithImagesLaying = facedArrayMalboro.map(id => (idToPackImageMapLaying[id]?.toString() || ''));
    setFacedArray([...facedArrayMalboroWithImages]);
    setFacedArrayNaming([...facedArrayMalboroWithNaming]);
    setFacedArrayWidths([...facedArrayMalboroWithWidths]);
    setFacedArrayHeights([...facedArrayMalboroWithHeights]);

    setFacedArrayEan([...facedArrayMalboroWithean]);
  
    
    console.log('[...facedArrayMalboroWithNaming]',[...facedArrayMalboroWithNaming])
    setFacedArrayLaying([...facedArrayMalboroWithImagesLaying]);
    }
   

  // setFacedArray([...facedArrayMalboro,...facedArrayChester]) 
  };

   fetchData();
  
}, [falseCount,squaresWithImages,selectedSquare]);
const containerRefScroll =  useRef<HTMLDivElement>(null);

useEffect(() => {
  if (containerRefScroll.current) {
    const container = containerRefScroll.current;

    // Center the scroll position
    const scrollX = (container.scrollWidth - container.clientWidth) / 2;
    const scrollY = (container.scrollHeight - container.clientHeight) / 2;

    container.scrollTo({
      left: scrollX,
      top: scrollY,
   //   behavior: "smooth", // Smooth scrolling for better UX
    });
  }
}, [scale]); 
  return (
    <div
    ref={containerRefScroll}
      style={{
      
        display: 'flex',
        justifyContent: 'center',
        backgroundColor: '#F5F9FF',
        width:   '100%',
        height: isBulk ? 'fit-contant' : "calc(100vh - 70px)",
        overflow: isBulk ? 'visible':"auto", // Enable scrolling for scaled content
        position: "relative",
        
      }}
    >  
  
      <div style={{ display: 'flex',
          justifyContent: 'center',
          //padding:` ${2 *50}px ${2 * 120}px`,
          alignItems: 'center', 
          transform: `scale(${ scale ? scale:  1})`,
          transformOrigin:scale &&  scale > 1 ? "top left" : "center center", // Ensure scaling starts at top left
          width: `${100 / 1}%`, // Scale width to match content
          height: `${100 / 1}%`, // Scale height to match content
           }}>
      <div
        ref={containerRef}
        style={{
          display: 'flex',
          //flexDirection: 'column',
          gap:((rowDistance  as number) * 5  || 2),
          justifyContent: 'center',
          alignItems: 'center',
          position: 'relative',
          cursor: isSelecting ? 'pointer' : 'default',
        }}
        onMouseDown={()=>{}}
        onMouseMove={()=>{}}
        onMouseUp={()=>{}}
      >
     {pinkSquares.map((square, index) => (
        <div
          key={index}
          className={classes.pinkSquare}
          style={{
            width: square.width * 5,
            height: square.height * 5 ,
            left: square.x,
            top: square.y,
            position: 'absolute',
          }}
          onMouseDown={(e) => {}}
        >
          {/* <CloseIcon
            className={classes.closeIcon}
            onClick={() => {}}
          /> */}
        </div>
      ))}
{yellowSquares.map((square, index) => (
        <div
          key={index}
          className={classes.yellowSquare}
          style={{
            width: square.width *5 ,
            height: square.height* 5 ,
            left: square.x,
            top: square.y,
            position: 'absolute',
          }}
          onMouseDown={(e) => {}}
        >
              <img  src={(square as any).image} width={square.width * 5} height={square.height * 5} style={{objectFit:'contain'}}>

</img>
          {/* <CloseIcon
            className={classes.closeIcon}
            onClick={() => {}}
          /> */}
        </div>
      ))}
      {blueSquares.map((square, index) => (
        <div
          key={index}
          className={classes.blueSquare}
          style={{
            width: square.width,
            height: square.height,
            left: square.x,
            top: square.y,
            position: 'absolute',
          }}
        >
          <div>
              <Viewer id={(square as any).id} rowSpace={colDistance * 5 } setExportArray={setExportArray} setExportArrayEan={setExportArrayEan}></Viewer>
              </div>
        </div>
      ))}
        {isSelecting && (
          <div
            className={classes.selectionBox}
            style={{
              left: selectionBox.x,
              top: selectionBox.y,
              width: selectionBox.width,
              height: selectionBox.height,
            }}
          />
        )}
         {mergedBoxes.map((box, index) => (
        <div
          key={index}
          className={classes.mergedBox}
          style={{
            left: box.x,
            top: box.y,
            width: box.width,
            height: box.height,
          }}
        />
      ))}
        {Array.from({ length:  col || 2  }).map((_, customBoxIndex) => (
       <div style={{display:"flex",flexDirection:'column', justifyContent:"center",alignItems:"center",gap:((rowDistance as number) * 5 || 2)}}>
       <div key={customBoxIndex} className={classes.customBox} style={{gap: ((colDistance as number) * 5 || 2),width: `${columnWidths[customBoxIndex ]}px` }}>
       
       {Array.from({ length: row || 2 }).map((_, setIndex) => (
            <div
              key={`${customBoxIndex}-${setIndex}`}
              className={classes.subBox}
              style={{ width: `${columnWidths[customBoxIndex ]}px`, visibility:hiddencells[setIndex]&& hiddencells[setIndex][customBoxIndex] ? 'unset':'hidden' }} 
              // Dynamically set width
            >
             { selectedBoxes[customBoxIndex]?.[setIndex]?.[0] &&
              <CloseIcon
            className={classes.closeIcon}
            onClick={() => { 
              
            
            }}
          /> }
              {renderBoxes(customBoxIndex, setIndex,columnWidths[customBoxIndex ])}
            </div>
          ))}
        </div>
        
         {/* <div className={classes.secondBox}></div> */}

       </div> 
      ))}
      </div>
      </div>
      
    </div>
  );
});

export default GridDemo;


