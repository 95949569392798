import React, { useEffect, useState } from "react";
import { Box, Button, IconButton, CircularProgress } from "@mui/material";
import Logo from "../../../assets/images/Logo1.png";
import { ReactComponent as Back } from "../../../assets/icons/Back.svg";
import { useStyles } from "./SideBarStyles";
import { useNavigate } from 'react-router-dom';
import Typography from "@mui/material/Typography";
import YesNoQuestionComponent from "../YesNoQuestion/YesNoquestion";
import MultiCheckboxComponent from "../MultiCheckboxComponent/MultiCheckboxComponent";

interface SidebarProps {
  question1: string[];
  setQuestion1: React.Dispatch<React.SetStateAction<string[]>>;
  partner: string;
  setPartner: React.Dispatch<React.SetStateAction<string>>;
  question2: string[];
  setQuestion2: React.Dispatch<React.SetStateAction<string[]>>;
  onSave: () => void;
  onBack: () => void;
  loadingNext: boolean; // Add button loading prop
  loadingBack: boolean; // Add button loading prop
}

const Sidebar: React.FC<SidebarProps> = ({
  question1,
  setQuestion1,
  question2,
  setQuestion2,
  onSave,
  onBack,
  setPartner,
  partner,
  loadingNext, // Use button loading prop
  loadingBack // Use button loading prop
}) => {
  const classes = useStyles();
  const [isYes, setIsYes] = useState(false);
  const [isPartner, setIsPartner] = useState(partner === 'yes' ? true : false);

  const navigate = useNavigate();

  useEffect(() => {
    setIsPartner(partner === 'yes' ? true : false);
  }, [partner]);

  useEffect(() => {
    if (question1.includes("Do you have a special RRP (IQOS) unit?")) {
      setIsYes(true);
    }
  }, [question1]);

  const handleYesNoChange = (value: string) => {
    setIsYes(value === "yes");
    if (value === "yes") {
      setQuestion1((prev) => Array.from(new Set([...prev, "Do you have a special RRP (IQOS) unit?"])));
    } else {
      setQuestion1((prev) => prev.filter(item => item !== "Do you have a special RRP (IQOS) unit?"));
    }
  };

  const handlePartnerChange = (value: string) => {
    setIsPartner(value === "yes");
    setPartner(value);
  };

  return (
    <Box className={classes.container}>
      <img src={Logo} alt="Logo"  onClick={()=>navigate('/')} className={classes.logo} />
      <div className={classes.buttonContainer}>
        <div style={{ display: "flex", flexDirection: "column" }}>
          <Typography variant="body2" sx={{ marginBottom: "12px" }}>
            STEP 5/10
          </Typography>
          <Typography variant="h4" sx={{ marginBottom: "12px" }}>
            Questionnaire
          </Typography>
          <Typography variant="h6" sx={{ marginBottom: "50px" }}>
            Special RRP unit / CC Area
          </Typography>
          <YesNoQuestionComponent onChange={handleYesNoChange} yes={isYes} label={"Would you like to add a RRP area?"} />
          <div style={{ marginBottom: '20px' }}>
            {/* {isYes && <MultiCheckboxComponent
              options={['Marketing Materials', "LED Screens"]}
              selectedOptions={question1}
              setSelectedOptions={setQuestion1}
            />} */}
          </div>
          <YesNoQuestionComponent onChange={handlePartnerChange} yes={isPartner} label={"Are you an IQOS partner?"} />
          <MultiCheckboxComponent
            label="CC Area has:"
            options={['Marketing Materials', 'LED Screens','Duplex pushers']}
            selectedOptions={question2}
            setSelectedOptions={setQuestion2}
          />
        </div>
        <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
          <IconButton onClick={onBack} disabled={loadingBack}>
            <Box component="span" className={classes.iconButton}>
              {loadingBack ? <CircularProgress size={24} /> : <Back />}
            </Box>
          </IconButton>
          <Button
            variant="contained"
            color="info"
            onClick={onSave}
            sx={{ marginTop: "0px", position: "relative" }}
            disabled={loadingNext}
          >
            {loadingNext ? <CircularProgress size={24} sx={{ position: "absolute" }} /> : "Next"}
          </Button>
        </div>
      </div>
    </Box>
  );
};

export default Sidebar;
