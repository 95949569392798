import axios from "../auth/axiosInstance";

const API_URL = process.env.REACT_APP_API_URL;

if (!API_URL) {
  throw new Error('API URL not defined in environment variables');
}

export const createBrand = async (name: string) => {
  try {
    const response = await axios.post(`${API_URL}/brands`, { name });
    return response.data;
  } catch (error:any) {
    throw error.response.data;
  }
};

export const getAllBrands = async () => {
  try {
    const response = await axios.get(`${API_URL}/brands`);
    return response.data;
  } catch (error:any) {
    console.log('response',error)

    throw error.response.data;
  }
};


export const getBrandByName = async (name: string) => {
  try {
    const response = await axios.get(`${API_URL}/brands/name/${name}`);
    return response.data;
  } catch (error: any) {
    throw error.response.data;
  }
};