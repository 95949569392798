import axios from "../auth/axiosInstance";

const API_URL = process.env.REACT_APP_API_URL;

if (!API_URL) {
  throw new Error('API URL not defined in environment variables');
}

export const createSize = async (name: string) => {
  try {
    const response = await axios.post(`${API_URL}/sizes`, { name });
    console.log(response)
    return response.data;
  } catch (error:any) {
    console.log(error)
    throw error.response.data;
  }
};

export const getAllSizes = async () => {
  try {
    const response = await axios.get(`${API_URL}/sizes`);
    return response.data;
  } catch (error:any) {
    throw error.response.data;
  }
};
