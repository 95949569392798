// AboutPage.tsx
import React, { useEffect, useRef, useState } from "react";
import Grid from "@mui/material/Grid";
import Sidebar from "../components/Step19 copy 2/sideBar/SideBar";
import DemoComponent from "../components/Step19 copy 2/Content/ContentV2";
import DemoComponentOriginal , { ChildRef } from "../components/Step19 copy 2/Content/ContentOriginal";
import ZoomInIcon from "@mui/icons-material/ZoomIn";
import ZoomOutIcon from "@mui/icons-material/ZoomOut";
import SideBarRight from "../components/Step19 copy 2/sideBarRight/SideBarRight";
import SideBarRightYellow from "../components/Step19 copy 2/sideBarRightYellow/SideBarRight";
import { getFromJson, saveToJson } from "../components/helpers/localData";
import {
  getShelfConfigById,
  updateFacings,
} from "../services/shelfConfigurationService";
import { getRRPConfigurationById } from "../services/rrpConfigurationService";
import { getMarketingMaterialById } from "../services/marketingMaterialsService";
import { useParams } from "react-router-dom";
import { getAllSkus, getSkuById } from "../services/skuService";
import { getLedScreenById } from "../services/ledScreensService";
import { getRRPNewConfigById } from "../services/rrpNewConfigurationService";
import { getAllVariants } from "../services/variantService";
import { getAllBrands } from "../services/brandService";
import { Box, Button, ButtonGroup } from "@mui/material";

type Square = {
  width: number;
  height: number;
  x: number;
  y: number;
  title?: string;
  positions?: any;
};
type Array3D = boolean[][][];

const Step3: React.FC = () => {
  const [selectedPanel, setSelectedPanel] = useState(1);
  const [squares, setSquares] = useState<Square[]>(
    getFromJson<Square[]>("LedScreens") || []
  );
  const { id } = useParams<{ id: string }>();

  const [col, setCol] = useState(2);
  const [name, setName] = useState(null);

  const [row, setRow] = useState(2);
  const [rowDistance, setRowDistance] = useState(2);
  const [colDistance, setColDistance] = useState(2);
  const [isAutoConfiguration, setIsAutoConfiguration] = useState(false);
  const [isDrag, setIsDrag] = useState(false);
  const [exportOptions, setExportOptions] = useState({
    compositionName: false,
    eanCode: false,
    validityDate: false,
  });
  const [KeyAccountId, setKeyAccountID] = useState<number|null>(null);

  const [squaresYellow, setSquaresYellow] = useState<Square[]>(
    getFromJson<Square[]>("MarketingList") || []
  );
  const [squaresBlue, setSquaresBlue] = useState<Square[]>(
    getFromJson<Square[]>("RRPList") || []
  );
  const [widths, setWidths] = useState<number[]>([]);
  const [pmiPositions, setPmiPositions] = useState<any[][][]>([]);
  const [shelfDuplex, setShelfDuplex] = useState<any[][][]>([]);

  const [question, setQuestion] = useState<any[]>([]);
  const [question2, setQuestion2] = useState<any[]>([]);
  const [facings, setFacings] = useState<any[]>([]);

  const [validFrom, setValidFrom] = useState<Date | null>(null);
  const [validTo, setValidTo] = useState<Date | null>(null);
  const [pinkSquares, setPinkSquares] = useState(
    getFromJson<Square[]>("LedScreens") || squares
  );
  const [yellowSquares, setYellowSquares] = useState(
    getFromJson<Square[]>("MarketingList") || squaresYellow
  );
  const [blueSquares, setBlueSquares] = useState(
    getFromJson<Square[]>("RRPList") || squaresBlue
  );
  useEffect(() => {
    saveToJson("MarketingList", yellowSquares);
  }, [yellowSquares]);
  useEffect(() => {
    saveToJson("RRPList", blueSquares);
  }, [blueSquares]);
  useEffect(() => {
    saveToJson("LedScreens", pinkSquares);
  }, [pinkSquares]);
  const [squaresWithImages, setSquaresWithImages] = useState<any>(
    []
  );
  const [squaresWithNames, setSquaresWithNames] = useState<any>(
    []
  );
  const [squaresWithEan, setSquaresWithEan] = useState<any>(
    []
  );
  const [squaresWithWithds, setSquaresWithWithds] = useState<any>(
    []
  );
  const [squaresWithHeights, setSquaresWithHeight] = useState<any>(
    []
  );
  // New state for the currently selected square
  const [selectedSquare, setSelectedSquare] = useState<{
    col: number;
    row: number;
    z: number;
  } | null>(null);
  useEffect(() => {
    console.log(selectedSquare);
  }, [selectedSquare]);

  // New state for the selected product card image URL
  const [selectedProductCardImage, setSelectedProductCardImage] =
    useState<string>("");
  useEffect(() => {
    console.log(selectedProductCardImage);
  }, [selectedProductCardImage]);
  // Initialize squaresWithImages based on col and row state
  function replaceWithEmptyString(arr: any[]): any[] {
    return arr.map((item) => {
      // If the item is an array, recurse into it
      if (Array.isArray(item)) {
        return replaceWithEmptyString(item);
      }
      // Otherwise, replace the item with an empty string
      return "";
    });
  }

  useEffect(() => {}, []);
  console.log('duplexShelf22',shelfDuplex)
  console.log('squaresWithImages22',squaresWithImages)


  useEffect( () => {
    console.log('selectedSquare55',selectedSquare)
    // Ensure both selectedSquare and selectedProductCardImage have values before proceeding
    if (selectedSquare && selectedProductCardImage) {

     const skuimage=    getSkuById(parseInt(selectedProductCardImage)).then(async (value)=>{
      console.log('skuimage',value)
      try {
        // Save to JSON
        saveToJson("squaresWithImages", squaresWithImages);

        // Update facings
     // Default id
     const updatedSquares2 = facings.map((row: any) => [...row]);

     updatedSquares2[selectedSquare.col][selectedSquare.row][
       selectedSquare.z
     ] = value.id;
        const facingsList = updatedSquares2; // Assuming squaresWithImages contains skuIds
      id &&   await updateFacings(id, facingsList as any);
      console.log('the second',squaresWithImages,)
      } catch (error) {
        console.error("Error updating facings:", error);
      }
      const savedBoxes = updatePmiPositions(shelfDuplex || [],pmiPositions|| []);
      console.log('savedBoxes',savedBoxes)

      setSquaresWithImages((currentSquares: any) => {
        // Clone the current state to avoid direct mutation
        const updatedSquares = currentSquares.map((row: any) => [...row]);
        const isDp = (selectedSquare as any).isDuplex 
        updatedSquares[selectedSquare.col][selectedSquare.row][
          selectedSquare.z
        ] =  isDp ?value.pack_image_laying :value.pack_image;

        return updatedSquares;
      });
      
      setSquaresWithHeight((currentSquares: any) => {
        // Clone the current state to avoid direct mutation
        const updatedSquares = currentSquares.map((row: any) => [...row]);
        updatedSquares[selectedSquare.col][selectedSquare.row][
          selectedSquare.z
        ] = value.height;

        return updatedSquares;
      });
      setSquaresWithWithds((currentSquares: any) => {
        // Clone the current state to avoid direct mutation
        const updatedSquares = currentSquares.map((row: any) => [...row]);
        updatedSquares[selectedSquare.col][selectedSquare.row][
          selectedSquare.z
        ] = value.width;

        return updatedSquares;
      });
      const variant = await getAllVariants()
      const brands = await getAllBrands()
       const currentvariant = variant.find((v:any)=>v.id===value.variant_id)
       const currentBrand = brands.find((v:any)=>v.id===value.brand_id)

      setSquaresWithNames((currentSquares: any) => {
        // Clone the current state to avoid direct mutation
        const updatedSquares = currentSquares.map((row: any) => [...row]);
        const isDp = (selectedSquare as any).isDuplex 
        updatedSquares[selectedSquare.col][selectedSquare.row][
          selectedSquare.z
        ] =  currentvariant.name+ ' ' + currentBrand.name;

        return updatedSquares;
      });

      

     })
    

      saveToJson("squaresWithImages", squaresWithImages);
      setSelectedSquare(null);
      setSelectedProductCardImage("");
    }
    if (selectedSquare && !selectedProductCardImage) {
      // Add a keydown event listener for handling backspace
      const handleKeyDown = async (event: KeyboardEvent) => {
        if (event.key === "Backspace") {
          const updatedSquares2 = facings.map((row: any) => [...row]);

     updatedSquares2[selectedSquare.col][selectedSquare.row][
       selectedSquare.z
     ] = '9999';
        const facingsList = updatedSquares2; // Assuming squaresWithImages contains skuIds
      id &&   await updateFacings(id, facingsList as any);
          setSquaresWithImages((currentSquares: any) => {
            // Clone the current state to avoid direct mutation
            const updatedSquares = currentSquares.map((row: any) => [...row]);
  
            // Set the value to -1 at the selected position
            updatedSquares[selectedSquare.col][selectedSquare.row][
              selectedSquare.z
            ] = '-';
  
            return updatedSquares;
          });
  
          // Reset the selectedSquare to null after updating
          setSelectedSquare(null);
        }
      };
  
      // Add the event listener
      window.addEventListener("keydown", handleKeyDown);
  
      // Cleanup the event listener when the component unmounts or dependencies change
      return () => {
        window.removeEventListener("keydown", handleKeyDown);
      };
    }
  }, [selectedSquare, selectedProductCardImage]);
  const updatePmiPositions = (shelfDuplex: Array3D, pmiPositions: Array3D): Array3D => {
    // Create a deep copy of pmiPositions to avoid mutating the original array
    const updatedPmiPositions = pmiPositions.map(layer => layer.map(row => [...row]));

    shelfDuplex.forEach((layer, layerIndex) => {
      layer.forEach((row, rowIndex) => {
        row.forEach((hasDuplexPusher, colIndex) => {
          if (hasDuplexPusher) {
            // If the next position is within bounds and not already true, insert true
            if (colIndex + 1 < row.length) {
            
                updatedPmiPositions[layerIndex][rowIndex].splice(colIndex + 1, 0, true);
              
            } else {
              // If the next position is out of bounds, push true to the end
              updatedPmiPositions[layerIndex][rowIndex].push(true);
            }
          }
        });
      });
    });
console.log('updatedPmiPositions',updatedPmiPositions)
  return updatedPmiPositions;
};
const [hiddencells,setHiddencells] = useState([])
function addTrueNextToExistingTrue(array:any) {
  // Create a new array to store the modified structure
  const newArray = array.map((subArray2D:any) => 
      subArray2D.map((subArray1D:any) => {
          const newSubArray = [];
          // Loop through the 1D array and add a duplicate true next to each true
          for (let k = 0; k < subArray1D.length; k++) {
              newSubArray.push(subArray1D[k]); // Add the current element
              if (subArray1D[k] === true) {
                  newSubArray.push(true); // Add an extra true if the current element is true
              }
          }
          return newSubArray;
      })
  );
  return newArray;
}

  useEffect(() => {
    const fetchShelfConfig = async () => {  
      if(id) {
        try {
          const shelfConfig = await  getShelfConfigById(id);
          const marketingMaterialIds = shelfConfig.marketingMaterials || [];
          const ledScreensIds = shelfConfig.ledScreens || [];

        const materialMap = new Map<number, any & { isAdded: number, instances: { x: number, y: number }[] }>();
        const ledMap = new Map<number, any & { isAdded: number, instances: { x: number, y: number }[] }>();

        // Store materials with their respective x and y values
        for (const item of marketingMaterialIds) {
          const material = await getMarketingMaterialById(item.id);
          if (materialMap.has(material.id)) {
            const existingMaterial = materialMap.get(material.id)!;
            existingMaterial.isAdded! += 1;
            existingMaterial.instances.push({ x: item.x, y: item.y });
          } else {
            materialMap.set(material.id, { ...material, isAdded: 1, instances: [{ x: item.x, y: item.y }] });
          }
        }
        for (const item of ledScreensIds) {
          const material = await getLedScreenById(item.id);
          if (ledMap.has(material.id)) {
            const existingMaterial = ledMap.get(material.id)!;
            existingMaterial.isAdded! += 1;
            existingMaterial.instances.push({ x: item.x, y: item.y });
          } else {
            ledMap.set(material.id, { ...material, isAdded: 1, instances: [{ x: item.x, y: item.y }] });
          }
        }
  
  
        // Expand materials with their respective x and y values
        const expandedMaterials = Array.from(materialMap.values()).flatMap(material => 
          material.instances.map((instance:any) => ({
            ...material,
            x: instance.x,
            y: instance.y,
            isAdded: 1 // Set isAdded to 1 for each instance in the expanded list
          }))
        );
        const expandedleds = Array.from(ledMap.values()).flatMap(material => 
          material.instances.map((instance:any) => ({
            ...material,
            x: instance.x,
            y: instance.y,
            isAdded: 1 // Set isAdded to 1 for each instance in the expanded list
          }))
        );
        const rrpConfigurations = await Promise.all(
          (shelfConfig.rrp || []).map(async (rrp: { id: number, x: number, y: number }) => {
            const fullRRP = await getRRPNewConfigById(rrp.id.toString());
            return {
              ...fullRRP,
              x: rrp.x,
              y: rrp.y,
            };
          })
        );
        const newSquares = rrpConfigurations.map((value) => ({
          id: value.id,
          title: value?.name,
          width: value.widths[0] *5  || 250 ,
          height: (value.row_position ) * 51.5+ (value.row_position  - 1) * (colDistance *5),
          x: value.x || 0,
          y: value.y || 0,
        }));
        const skus =await getAllSkus()
        type PackItem = {
          id: string;
          pack_image: string;
          pack_image_laying?: string;
        };
      
        const getPackImageById = (id: string, items: PackItem[]): string | undefined => {
          const item = items.find(item => item.id === id);
          return item ? item.pack_image : undefined;
        };
        const getPackImageByIdLaying = (id: string, items: PackItem[]): string | undefined => {
          const item = items.find(item => item.id === id);
          return item ? item.pack_image_laying : undefined;
        };
        const getPackNameById = (id: string, items: PackItem[]): string | undefined => {
          const item = items.find(item => item.id === id);
          return item ? (item as any).brandName + ' ' + (item as any).variantName: undefined;
        };
        const getPackEanById = (id: string, items: PackItem[]): string | undefined => {
          const item = items.find(item => item.id === id);
          return item ? (item as any).ean_code: undefined;
        };
        const getPackWidthById = (id: string, items: PackItem[]): string | undefined => {
          const item = items.find(item => item.id === id);
          return item ? (item as any).width: undefined;
        };
        const getPackHeightById = (id: string, items: PackItem[]): string | undefined => {
          const item = items.find(item => item.id === id);
          return item ? (item as any).height: undefined;
        };
        const replaceIdsWithPackImages = (idArray: string[][][], items: PackItem[]): string[][][] => {
           const duplexUpdated = shelfConfig?.duplex != null  ? addTrueNextToExistingTrue(shelfConfig.duplex) : null
           console.log('duplexUpdated',duplexUpdated)
          return idArray.map((layer,layerIndex) =>
            layer.map((row,rowIndex) =>
             
            {
              let dx = 0
              
              return  row.map((id,idIndex) => {
               
                if( shelfConfig?.duplex != null && (duplexUpdated[layerIndex][rowIndex][idIndex]  ) ) {
                 
               
                 
                  return getPackImageByIdLaying(id, items) || id
                }else {
                  return getPackImageById(id, items) || id
                }
              
              
              }
            )}
            )
          );
        };
        const replaceIdsWithPackName = (idArray: string[][][], items: PackItem[]): string[][][] => {
          return idArray.map(layer =>
            layer.map(row =>
              row.map(id => getPackNameById(id, items) || id)
            )
          );
        };
        const replaceIdsWithPackEan = (idArray: string[][][], items: PackItem[]): string[][][] => {
          return idArray.map(layer =>
            layer.map(row =>
              row.map(id => getPackEanById(id, items) || id)
            )
          );
        };
        const replaceIdsWithPackWidths= (idArray: string[][][], items: PackItem[]): string[][][] => {
          return idArray.map(layer =>
            layer.map(row =>
              row.map(id => getPackWidthById(id, items) || id)
            )
          );
        };
        const replaceIdsWithPackHeights= (idArray: string[][][], items: PackItem[]): string[][][] => {
          return idArray.map(layer =>
            layer.map(row =>
              row.map(id => getPackHeightById(id, items) || id)
            )
          );
        };
          if (
            shelfConfig &&
            shelfConfig.facings &&
            shelfConfig.facings != null &&
            shelfConfig.facings[0] != null
          ) {
            setSquaresWithImages(replaceIdsWithPackImages(shelfConfig.facings,skus));
            setSquaresWithNames(replaceIdsWithPackName(shelfConfig.facings,skus));
            setSquaresWithEan(replaceIdsWithPackEan(shelfConfig.facings,skus));
            setSquaresWithWithds(replaceIdsWithPackWidths(shelfConfig.facings,skus));
            setSquaresWithHeight(replaceIdsWithPackHeights(shelfConfig.facings,skus));

            setFacings(shelfConfig.facings)

          } else {

            const savedBoxes = updatePmiPositions(shelfConfig.duplex || [],shelfConfig.pmiPositions|| []);
              console.log('the save boxes',savedBoxes)
            setSquaresWithImages(replaceWithEmptyString(savedBoxes));
            setSquaresWithNames(replaceWithEmptyString(savedBoxes));
            setSquaresWithWithds(replaceWithEmptyString(savedBoxes));
            setSquaresWithHeight(replaceWithEmptyString(savedBoxes));

            setFacings(replaceWithEmptyString(savedBoxes))

          }
          setCol(shelfConfig.col_position);
          setRow(shelfConfig.row_position);
          setRowDistance(shelfConfig.rowDistance);
          setColDistance(shelfConfig.colDistance);
          setWidths(shelfConfig.widths.map((width: any) => width * 5));
          setPmiPositions(shelfConfig.pmiPositions || []);
          setBlueSquares(newSquares)
          setYellowSquares(expandedMaterials);
          setSquaresYellow(expandedMaterials);
          setPinkSquares(expandedleds)
          setQuestion(shelfConfig.question1)
          setQuestion2(shelfConfig.question2)
          setKeyAccountID(shelfConfig.key_account_id)
          setShelfDuplex(shelfConfig.duplex || [])
          if (shelfConfig.validFrom) {
            const validFromDate = new Date(shelfConfig.validFrom);
            validFromDate.setMinutes(validFromDate.getMinutes() + validFromDate.getTimezoneOffset());
            setValidFrom(validFromDate);
          }
    
          if (shelfConfig.validTo) {
            const validToDate = new Date(shelfConfig.validTo);
            validToDate.setMinutes(validToDate.getMinutes() + validToDate.getTimezoneOffset());
            setValidTo(validToDate);
          }
          
          setHiddencells(shelfConfig.hiddencells)
          setIsAutoConfiguration(shelfConfig.isAutoConfiguration)
          setName(shelfConfig.name === null ? '' :shelfConfig.name );
        } catch (error) {
          console.error("Error fetching shelf configuration:", error);
        }
      }
    
    };

    fetchShelfConfig();
  }, [isDrag]);
  const childRef = useRef<ChildRef>(null);

  const handleTriggerChildFunction = () => {
    if (childRef.current) {
      childRef.current.triggerChildFunction();
    }
  };
  
  const handleTriggerSecondChildFunction = () => {
    
    if (childRef.current) {
      childRef.current.triggerSecondChildFunction();
    }
  };
  const [isLoading, setIsLoading] = useState(false);
  const handleResetConfiguration = async () => {
    try {
      // Set all squaresWithImages to an empty string
      const emptySquares = squaresWithImages.map((layer: any) =>
        layer.map((row: any) =>
          row.map(() => "")
        )
      );
  

  
      // Update the facings in the backend
      if (id) {
        await updateFacings(id, []);
      }
  
      // Update the state with the reset configuration
      setSquaresWithImages(emptySquares);
      setIsDrag(false)
    } catch (error) {
      console.error("Error resetting configuration:", error);
    }
  };
   const [scale,setScale] = useState(1)
   const handleZoomIn = () => {
    setScale((prev) => Math.min(prev + 0.1, 2.5)); // Limit max zoom to 2x
  };

  const handleZoomOut = () => {
    setScale((prev) => Math.max(prev - 0.1, 0.5)); // Limit min zoom to 0.5x
  };
  return (
   
      <Grid container width={'100%'}>
        <Grid item xs={3}>
          <Sidebar  
          exportOptions={exportOptions}
          setExportOptions={setExportOptions}
          isAutoConfiguration={isAutoConfiguration}
          isDrag={isDrag}
          setIsDrag={setIsDrag}
          setIsAutoConfiguration={setIsAutoConfiguration}
          onResetConfiguration={handleResetConfiguration}  // Pass the reset function as a prop

          question={question} question2={question2} isLoading={isLoading}  setSelectedProductCardImage={setSelectedProductCardImage}  name={name}  exportToPDF={()=>{

handleTriggerChildFunction()              

}}  exportToXSLX={()=>{

  handleTriggerSecondChildFunction()              
  
  }}/>{" "}
        </Grid>
        
        <Grid item xs={9}>
          <div
            style={{
              width:'100%',
              backgroundColor: "#e0e0e0",
            //  transform:'scale(1)'
            }}
          > 
       {!isDrag &&          <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        gap: 1,
        background:'#F5F9FF',
      }}
    >
      <ButtonGroup variant="contained" aria-label="Zoom controls" sx={{boxShadow:'none',gap:'1px'}} >
        <Button onClick={handleZoomOut} color="primary" sx={{background:'white' , borderColor:'#9CA0AB'}} style={{borderColor:'transparent'}}  startIcon={<ZoomOutIcon />}>
          Zoom Out
        </Button>
        <Button onClick={handleZoomIn} color="primary"  sx={{background:'white' , borderColor:'#9CA0AB'}} startIcon={<ZoomInIcon />}>
          Zoom In
        </Button>
      </ButtonGroup>
    </Box>}

            {!isDrag ?pmiPositions.length > 0 &&  <DemoComponentOriginal
           ref={childRef}
              selectionMode={selectedPanel}
              squares={squares}
              exportOptions={exportOptions}
              validFrom={validFrom}
              validTo={validTo}
              scale={scale}
              name={name}
              keyAccountId={KeyAccountId}
              squaresWithImages={squaresWithImages}
              squaresWithNames={squaresWithNames}
              squaresWithWithds={squaresWithWithds}
              squaresWithHeights={squaresWithHeights}

              squaresWithEan={squaresWithEan}
              selectedSquare={selectedSquare}
              setSelectedSquare={setSelectedSquare}
              pinkSquares={pinkSquares}
              setPinkSquares={setPinkSquares}
              yellowSquares={yellowSquares}
              setYellowSquares={setYellowSquares}
              squaresYellow={squaresYellow}
              blueSquares={blueSquares}
              setBlueSquares={setBlueSquares}
              squaresBlue={squaresBlue}
              col={col}
              row={row}
              shelfDuplex={shelfDuplex}
              setShelfDuplex={setShelfDuplex}
              isAutoConfiguration={isAutoConfiguration}
              hiddencells={hiddencells}


              rowDistance={rowDistance}
              colDistance={colDistance}
              widths={widths}
              selectedBoxesPmi={pmiPositions}
              setIsLoading={setIsLoading}
              
            ></DemoComponentOriginal> : pmiPositions.length > 0 &&  <DemoComponent
           ref={childRef}
              selectionMode={selectedPanel}
              squares={squares}
              keyAccountId={KeyAccountId}
              squaresWithWithds={squaresWithWithds}
              squaresWithHeights={squaresWithHeights}
              squaresWithImages={squaresWithImages}
              squaresWithNames={squaresWithNames}
              squaresWithEan={squaresWithEan}
              selectedSquare={selectedSquare}
              setSelectedSquare={setSelectedSquare}
              pinkSquares={pinkSquares}
              setPinkSquares={setPinkSquares}
              yellowSquares={yellowSquares}
              setYellowSquares={setYellowSquares}
              squaresYellow={squaresYellow}
              blueSquares={blueSquares}
              setBlueSquares={setBlueSquares}
              squaresBlue={squaresBlue}
              col={col}
              row={row}
              shelfDuplex={shelfDuplex}
              setShelfDuplex={setShelfDuplex}
              isAutoConfiguration={isAutoConfiguration}
              hiddencells={hiddencells}


              rowDistance={rowDistance}
              colDistance={colDistance}
              widths={widths}
              selectedBoxesPmi={pmiPositions}
              setIsLoading={setIsLoading}
              
            ></DemoComponent>}
     
            {selectedPanel === 3 && (
              <SideBarRight
                onChange={(value) => {
                  setSelectedPanel(value);
                }}
                squares={pinkSquares}
                onAddShelf={(w, h, t) =>
                  setPinkSquares([
                    ...pinkSquares,
                    {
                      width: parseInt(w),
                      height: parseInt(h),
                      title: t,
                      x: 90,
                      y: 90,
                    },
                  ])
                }
                onAddMaterial={(material) => {
                  setSquares([...pinkSquares, material]);
                }}
              ></SideBarRight>
            )}
            {selectedPanel === 4 && (
              <SideBarRightYellow
                onChange={(value) => {
                  setSelectedPanel(value);
                }}
                squaresYellow={yellowSquares}
                onAddShelf={(w, h, t) =>
                  setYellowSquares([
                    ...yellowSquares,
                    {
                      width: parseInt(w),
                      height: parseInt(h),
                      title: t,
                      x: 90,
                      y: 90,
                    },
                  ])
                }
                onAddMaterial={(material) => {
                  setSquaresYellow([...yellowSquares, material]);
                }}
              ></SideBarRightYellow>
            )}
          </div>
        </Grid>
      </Grid>
    
  );
};

export default Step3;