import React, { useEffect, useState } from "react";
import Typography from "@mui/material/Typography";
import NoShelfCard from "../noShelfCard/NoshelfCard";
import { useStyles } from "./ContentStyles";
import CreateShelfCard from "../CreateShelfCard/CreatShelfCard";
import { Grid, CircularProgress, Snackbar, Alert } from "@mui/material";
import { useNavigate } from 'react-router-dom';
import { getAllShelfConfigs, getShelfConfigBySearch } from "../../../services/shelfConfigurationService";
import ExcistShelfCard from "../ExcistSheflCard/ExcistShelfCard";
import SearchInput from "../../SKUList/InputV2/InputV2";
import BrandModal from "../ModalComponent/Modal";
import { getAllKeyAccounts, getGroupedChains } from "../../../services/posuniversService";

interface ContentComponentProps {
  title: string;
  subtitle: string;
}

const ContentComponent: React.FC<ContentComponentProps> = ({
  title,
  subtitle,
}) => {
  const classes = useStyles();
  const [shelfConfigs, setShelfConfigs] = useState<any[]>([]);
  const [isList, setIsList] = useState(false); 
  const [searchTerms, setSearchTerms] = useState(''); // State for search terms
  const [loading, setLoading] = useState(false); // Add loading state
  const [error, setError] = useState<string | null>(null); // Add error state for snackbar
  const [successMessage, setSuccessMessage] = useState<string | null>(null); // Success Snackbar state
  const [openModal, setOpenModal] = useState(false);
  

  
  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };
  const navigate = useNavigate();
  const [groupedChains, setGroupedChains] = useState<any[]>([]);
  const [indId,setIndId]=useState<number| undefined>()
  useEffect(() => {
    const fetchShelfConfigs = async () => {
      try {
        setLoading(true); // Set loading to true before fetching data
        const data = await getAllShelfConfigs();
        const fetchedGroupedChains = await getGroupedChains();
        setGroupedChains(fetchedGroupedChains);
        const keyAccountsData = await getAllKeyAccounts();
        const Individual = keyAccountsData.find((value:any)=>value.name ==='Individual Store')
        setIndId(Individual.id)
        setShelfConfigs(data);
        setIsList(data.length > 0); // Set isList based on whether there are any shelf configs
      } catch (error) {
        setError("Error fetching shelf configs"); // Set error message
        console.error("Error fetching shelf configs:", error);
      } finally {
        setLoading(false); // Set loading to false after data is fetched
      }
    };

    fetchShelfConfigs();
  }, []);
  const removeShelfConfig = (id: string) => {
    setShelfConfigs((prevConfigs) => prevConfigs.filter((config) => config.id !== id));
    setIsList(shelfConfigs.length > 0);  };
  const handleSearch = async (terms: string) => {
    try {
      setLoading(true); // Set loading to true before searching
      const data = await getShelfConfigBySearch(terms);
      setShelfConfigs(data.data); // Assuming the API returns { data: [...] }
      setIsList(data.data.length > 0); // Update isList based on the search results
    } catch (error) {
      setError("Error searching shelf configs"); // Set error message
      console.error("Error searching shelf configs:", error);
    } finally {
      setLoading(false); // Set loading to false after search is done
    }
  };

  const handleCloseSnackbar = () => {
    setError(null); // Close snackbar by setting error to null
    setSuccessMessage(null); // Close success snackbar as well

  };

  return (
    <div className={classes.root}>
      <div className={classes.contentContainer}>
        <div>
          <Typography variant="h4" gutterBottom>
            {title}
          </Typography>
          <Typography variant="subtitle1" color="textSecondary">
            {subtitle}
          </Typography>
        </div>

        <SearchInput
          width={280}
          placeholder="Search for shelves"
          value={searchTerms}
          onValueChange={(value) => {
            setSearchTerms(value);
            handleSearch(value);
          }}
          label=""
        />
      </div>
      {loading ? (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
          <CircularProgress />
        </div>
      ) : (
        <>
          {isList ? (
            <Grid container spacing={2} style={{ padding: "40px" }}>
              {shelfConfigs.map((config) => (
                <Grid key={config.id} item xs={12} sm={6} md={4} lg={3}>
                  <ExcistShelfCard config={config} 
                    removeShelfConfig={removeShelfConfig} // Pass the refresh function
                    setSuccessMessage={setSuccessMessage}
                    setError={setError} // Pass error handler
                    // Pass succes
                    groupedChains={groupedChains}
                    indId={indId}
                  />
                </Grid>
              ))}
              <Grid key="create-new" item xs={12} sm={6} md={4} lg={3}>
                <CreateShelfCard    groupedChains={groupedChains}
                    indId={indId}  />
              </Grid>
            </Grid>
          ) : (
            <div className={classes.centerContainer}>
              <NoShelfCard
                title="Not created any shelves yet"
                buttonText="+ Create New Shelf"
                onClick={handleOpenModal}
              />
            </div>
          )}
        </>
      )}
      <Snackbar open={!!error} autoHideDuration={6000} onClose={handleCloseSnackbar}>
        <Alert onClose={handleCloseSnackbar} severity={error?.includes('stores') ? "info":"error"}>
          {error}
        </Alert>
      </Snackbar>
      <Snackbar open={!!successMessage} autoHideDuration={6000} onClose={handleCloseSnackbar}>
        <Alert onClose={handleCloseSnackbar} severity="success">
          {successMessage}
        </Alert>
      </Snackbar>
      <BrandModal   groupedChains={groupedChains}
                    indId={indId} open={openModal} handleClose={handleCloseModal} label="Shelf"  />

    </div>
  );
};

export default ContentComponent;
