import React, { useEffect, useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Link,
  Typography,
  Box,
  TextField,
} from "@mui/material";
import Viewer from "../../RrpPreview/Viewer";
import { Add as AddIcon } from "@mui/icons-material";
import { useNavigate, useParams } from "react-router-dom";
import BrandModal from "../ModalComponentKeyAccount/Modal";
import SearchInput from "../InputSearch/Input";

interface ModalProps {
  open: boolean;
  handleClose: () => void;
  label: string;
  onValueSelected(values: any[]): void;
  configurations: any[];
  initialSelectedValues: number[];
}

const Modal: React.FC<ModalProps> = ({ open, handleClose, label, onValueSelected, configurations, initialSelectedValues }) => {
  const [selectedValues, setSelectedValues] = useState<string[]>([]);
  const [previewOpen, setPreviewOpen] = useState<boolean>(false);
  const [currentPreviewConfig, setCurrentPreviewConfig] = useState<any>(null);
  const [searchQuery, setSearchQuery] = useState(""); // New state for search query
  const [filteredConfigurations, setFilteredConfigurations] = useState(configurations);

  // Update filtered configurations based on search query
  useEffect(() => {
    const lowercasedQuery = searchQuery.toLowerCase();
    setFilteredConfigurations(
      configurations.filter((config) =>
        config.name.toLowerCase().includes(lowercasedQuery)
      )
    );
  }, [searchQuery, configurations]);
  const { id } = useParams<{ id: string }>();
  const handleSearchChange = (value:string) => {
    setSearchQuery(value);
  };
  useEffect(() => {
    setSelectedValues(initialSelectedValues.map(id => id.toString()));
  }, [initialSelectedValues]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setSelectedValues((prevSelected) =>
      prevSelected.includes(value) ? prevSelected.filter((v) => v !== value) : [...prevSelected, value]
    );
  };
  const [openModal, setOpenModal] = useState(false);

  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };
  const handleSave = () => {
    const selectedConfigurations = configurations.filter(config => selectedValues.includes(config.id.toString()));
    onValueSelected(selectedConfigurations);
    handleClose();
  };

  const handlePreviewOpen = (config:any) => {
    setCurrentPreviewConfig(config);
    setPreviewOpen(true);
  };

  const handlePreviewClose = () => {
    setPreviewOpen(false);
    setCurrentPreviewConfig(null);
  };
const navigate = useNavigate()
  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        sx={{
          "& .MuiDialog-paper": {
            width: "570px",
            borderRadius: "15px !important",
            paddingBottom: "0",
          },
        }}
      >
          <BrandModal open={openModal} handleClose={handleCloseModal} label="Shelf" indexId={parseInt(id || '')} />

        <DialogTitle>
          <Typography variant="h4">Select Configuration {label}</Typography>
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            <Typography variant="h6" sx={{ marginBottom: "12px", color: "#9CA0AB" }}>
              Select which HNB Configuration to apply here
            </Typography>
          </DialogContentText>
          
          <SearchInput
            value={searchQuery}
            width={516}
            unit=""
            onValueChange={(e) => handleSearchChange(e)}
            onUnitChange={() => {}}
            label=""
            type="text"
          />
            <Button
    color="primary"
    variant="contained"
    onClick={() => {  

      handleOpenModal()
    }}
    sx={{
      marginBottom: "10px !important",
      "background-color": "#F2F8FF",
      "color": "#0179FF",
      width: "100%",
      justifyContent: "center",
      height: "32px",
      marginTop: "12px !important",
      "&:hover": {
        color: "black",
      }
    }}
    startIcon={
      <Box component="span" style={{
        width: 12,
        height: 12,
        borderRadius: "50%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        background: "transparent",
        color: "#0179FF",
      }}>
        <AddIcon />
      </Box>
    }
  >
    Add
  </Button>
          <div style={{ maxHeight: "600px", overflow: "auto", padding: "8px" }}>
          <FormGroup>
  {filteredConfigurations.map((option) => (
    option.name.toLowerCase() !== "draft" && (
      <FormControlLabel
        key={option.id}
        control={
          <Checkbox
            checked={selectedValues.includes(option.id.toString())}
            onChange={handleChange}
            value={option.id.toString()}
          />
        }
        label={
          <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
            <div>
              <Typography variant="subtitle1" sx={{ fontSize: "14px", fontWeight: "400", color: "#383A49" }}>
                {option.name} <span style={{ color: "#0179FF" }}>{`${option.row_position} `}</span>
              </Typography>
              <Typography variant="body2" sx={{ fontSize: "12px", fontWeight: "400", color: "#9CA0AB" }} color="textSecondary">
                {new Date(option.created_at).toLocaleDateString()}
              </Typography>
            </div>
            <div>
              <Link
                href="#"
                underline="hover"
                sx={{ marginRight: "8px", fontSize: "14px", fontWeight: "400", color: "#0179FF" }}
                onClick={() => handlePreviewOpen(option)}
              >
                Preview
              </Link>
            </div>
          </div>
        }
        sx={{
          height: "57px",
          width: "100%",
          background: "#F5F9FF",
          marginBottom: "1px",
          '& .MuiTypography-root': { flex: 1 },
          '&:hover': { backgroundColor: "#e1e5ea" },
        }}
      />
    )
  ))}

</FormGroup>

          </div>
        </DialogContent>
        <DialogActions>
          <div style={{ display: "flex", width: "100%", gap: "8px" }}>
            <Button
              color="primary"
              variant="contained"
              onClick={handleClose}
              sx={{
                marginBottom: "10px !important",
                background: "rgba(217, 231, 248, 0.50)",
                justifyContent: "center",
                color: "#383A49",
                flexBasis: "33%",
                "&:hover": { color: "black" },
              }}
            >
              Cancel
            </Button>
            <Button
              color="primary"
              variant="contained"
              onClick={handleSave}
              sx={{
                marginBottom: "10px !important",
                "background-color": "#0179FF",
                color: "white",
                flexGrow: 1,
                justifyContent: "center",
                "&:hover": { color: "white", backgroundColor: "#0179FF !important", opacity: 0.8 },
              }}
            >
              Assign Configuration {label}
            </Button>
          </div>
        </DialogActions>
      </Dialog>

      <Dialog
        open={previewOpen}
        onClose={handlePreviewClose}
        sx={{
          "& .MuiDialog-paper": {
            width: "80%",
            maxHeight: "80%",
            borderRadius: "15px !important",
          },
        }}
      >
        <DialogTitle>
          <Typography variant="h4">Preview Configuration</Typography>
        </DialogTitle>
        <DialogContent>
          {currentPreviewConfig && (
            <Viewer id={currentPreviewConfig.id} rowSpace={2 * 5} />
          )}
        </DialogContent>
        <DialogActions>
          <Button
            color="primary"
            variant="contained"
            onClick={handlePreviewClose}
            sx={{
              background: "rgba(217, 231, 248, 0.50)",
              justifyContent: "center",
              color: "#383A49",
              "&:hover": { color: "black" },
            }}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default Modal;
