import React from "react";
import { Box, Button, IconButton } from "@mui/material";
import { Add as AddIcon } from "@mui/icons-material";
import Logo from "../../../assets/images/Logo1.png";
import { ReactComponent as SKUS } from "../../../assets/icons/SKUS.svg";
import { ReactComponent as Stores } from "../../../assets/icons/Stores.svg";
import { ReactComponent as Account } from "../../../assets/icons/Account.svg";
import { ReactComponent as Back } from "../../../assets/icons/Back.svg";

import { useStyles } from "./SideBarStyles";
import Input from "../Input/Input";
import { ReactComponent as Col } from "../../../assets/icons/Col.svg";
import { ReactComponent as Row } from "../../../assets/icons/Row.svg";
import { ReactComponent as BoxICon } from "../../../assets/icons/Box.svg";
import { ReactComponent as Height } from "../../../assets/icons/Height.svg";
import { useNavigate } from 'react-router-dom';

import Typography from "@mui/material/Typography";

interface SidebarProps {
  onChangeValue:(val: number) => void;
  boxCount:number
}
const Sidebar: React.FC<SidebarProps> = ({onChangeValue,boxCount}) => {
  const classes = useStyles();
  const navigate = useNavigate();

  return (
    <Box className={classes.container}>
      <img src={Logo} alt="Logo" onClick={()=>navigate('/')} className={classes.logo} />
      <div className={classes.buttonContainer}>
        <div style={{ display: "flex", flexDirection: "column" }}>
          <Typography variant="body2" sx={{ marginBottom: "12px" }}>
          STEP 4/10
          </Typography>
          <Typography variant="h4" sx={{ marginBottom: "12px" }}>
          Boxes per column
          </Typography>
          <Typography variant="h6" sx={{ marginBottom: "50px" }}>
          Edit your drawer dimensions
          </Typography>
          <Input
            onChange={(value) => {onChangeValue(value)}}
            value={boxCount}
            icon={<BoxICon />}
            text={"# of boxes / column"}
          />
           <div style={{background:"#F5F9FF",borderRadius: "2px 5px 5px 2px",borderLeft: "2px solid #0179FF",padding:"5px"

}}>          
  <Typography variant="h1" >
  Recommended number of boxes is <strong>10</strong>
          </Typography>
           </div>
        </div>
        <div>
       
          <div style={{ display: "flex", alignItems: "center" }}>
       
       <IconButton
         // color="primary"
         // variant="contained"
         // sx={{marginBottom:"10px !important", padding:"0 !important", margin:"0 !important",background:"transparent", '&:hover':{ background:"transparent !important",boxShadow:"none"}}}
         onClick={() => {}}
         size="large">
         <Box component="span" className={classes.iconButton}>
           <Back />
         </Box>
       </IconButton>
       <Button variant="contained" color="info" onClick={() => {navigate('/step4')}} sx={{marginTop:"0px"}}>
         Next
       </Button>
     </div>
        </div>
        
      </div>
    </Box>
  );
};

export default Sidebar;
