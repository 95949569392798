import domtoimage from 'dom-to-image';
import jsPDF from 'jspdf';
import { utils, WorkBook, write, writeFile } from 'xlsx-js-style';
import ExcelJS from 'exceljs';
import { format } from 'date-fns';
import { fetchShelfImage, getShelfConfigById } from '../../../../services/shelfConfigurationService';
import { getRRPNewConfigById } from '../../../../services/rrpNewConfigurationService';
import { getLedScreenById } from '../../../../services/ledScreensService';
import { getMarketingMaterialById } from '../../../../services/marketingMaterialsService';
import { combineArrays, handleMouseUp, replaceWithEmptyString, updatePmiPositions } from './gridFunctions';
import { fetchSkusOrderedChesterfield, fetchSkusOrderedMarlboro, getAllSkus, getSkusOrdered, getSkusOrderedByBrand, getSkusOrderedByDecreaseOrder, getSkusOrderedByDecreaseOrderChesterfield, getSkusOrderedByIncreaseOrder, getSkusOrderedByIncreaseOrderChesterfield, getSkusWithMinFacingsChesterfield, getSkusWithMinFacingsMarlboro } from '../../../../services/skuService';
import { getConfiguration, getOrderedBrands } from '../../../../services/configurationService';
import { getSkusWithPriority } from '../../../../services/skuPriorityService';
type Array3D = boolean[][][];

export const handleExportToPDF = async ( containerRef: React.RefObject<HTMLDivElement>,
    setIsLoading: React.Dispatch<React.SetStateAction<boolean>>,
    id: string) => {
    setIsLoading(true); // Set loading state to true
  
    const input = containerRef.current;
    if (input) {
      const scale = 4;
      const width = input.scrollWidth;
      const height = input.scrollHeight;
  
      const fetchImageFromServer = async (url:any) => {
  
           const response = await fetchShelfImage(url)
        
        return response.data;
      };
  
      const embedBackgroundImagesAsBase64 = async (node:any) => {
        // Handle div background images
        const divs = Array.from(node.getElementsByTagName('div'));
        const divPromises = divs.map(async (div:any) => {
          const style = window.getComputedStyle(div);
          const backgroundImage = style.getPropertyValue('background-image');
          const urlMatch = backgroundImage.match(/url\("(.*)"\)/);
          if (urlMatch && urlMatch[1]) {
            const originalUrl = urlMatch[1];
            const dataUrl = await fetchImageFromServer(originalUrl);
            div.style.backgroundImage = `url(${dataUrl})`;
          }
        });
      
        // Handle img elements
        const imgs = Array.from(node.getElementsByTagName('img'));
        const imgPromises = imgs.map(async (img:any) => {
          const originalUrl = img.src;
          const dataUrl = await fetchImageFromServer(originalUrl);
          img.src = dataUrl;
        });
      
        await Promise.all([...divPromises, ...imgPromises]);
      };
  
      await embedBackgroundImagesAsBase64(input);
      const style = {
        transform: `scale(${scale})`,
        transformOrigin: 'top left',
        width: `${width}px`,
        height: `${height}px`,
        position: 'absolute',
        left: '0',
        top: '0'
      };
      domtoimage.toPng(input, { width: width * scale, height: height * scale, style })
      .then((dataUrl) => {
        // Create a PDF document
        const pdf = new jsPDF('p', 'mm', 'a4');
        const pdfWidth = pdf.internal.pageSize.getWidth();
        const pdfHeight = pdf.internal.pageSize.getHeight();
        const img = new Image();
        img.src = dataUrl;
        
        img.onload = () => {
          const imgProps = pdf.getImageProperties(img);
          const imgRatio = imgProps.height / imgProps.width;
          const pdfImgHeight = pdfWidth * imgRatio;
  
          // If the image fits within one page, add it directly
          if (pdfImgHeight <= pdfHeight) {
            const yOffset = (pdfHeight - pdfImgHeight) / 2; // Center vertically
            pdf.addImage(dataUrl, 'PNG', 0, yOffset, pdfWidth, pdfImgHeight);
          } else {
            // If the image is taller than one page, split it across pages
            let position = 0;
            while (position < imgProps.height) {
              const canvasPage = document.createElement('canvas');
              canvasPage.width = imgProps.width;
              const pageHeight = Math.min(imgProps.height - position, imgProps.width * pdfHeight / pdfWidth);
              canvasPage.height = pageHeight;
  
              const ctx = canvasPage.getContext('2d');
              if (ctx) {
                ctx.drawImage(img, 0, position, imgProps.width, pageHeight, 0, 0, imgProps.width, pageHeight);
              }
  
              const imgPageData = canvasPage.toDataURL('image/png');
              const yOffset = (pdfHeight - (pdfWidth * pageHeight / imgProps.width)) / 2; // Center vertically
              pdf.addImage(imgPageData, 'PNG', 0, yOffset, pdfWidth, pdfWidth * pageHeight / imgProps.width);
  
              position += pageHeight;
              if (position < imgProps.height) {
                pdf.addPage();
              }
            }
          }
  
          pdf.save(`Shelf-export-${id}.pdf`);
          setIsLoading(false); // Set loading state to false after completion
  
        };
      })
      .catch((error) => {
        console.error('Error generating PDF:', error);
        setIsLoading(false); // Set loading state to false after completion
  
      });
    }
  };


export const handleExportToPNG = async (containerRef: any, setIsLoading: React.Dispatch<React.SetStateAction<any>>) => {
    setIsLoading(true); // Set loading state to true
  
    const input = containerRef.current;
    if (input) {
      const scale = 4;
      const width = input.scrollWidth;
      const height = input.scrollHeight;
  
      const fetchImageFromServer = async (url:any) => {
        console.log('turl',url.replace('/sku_images','/sku_resized'))
        try {
           const response = await fetchShelfImage(url)
        
          return response.data;
        }catch {
          console.log('Empty image')
          return '-'
        }
       
      };
  
      const embedBackgroundImagesAsBase64 = async (node:any) => {
        const divs = Array.from(node.getElementsByTagName('div'));
        const divPromises = divs.map(async (div:any) => {
          const style = window.getComputedStyle(div);
          const backgroundImage = style.getPropertyValue('background-image');
          const urlMatch = backgroundImage.match(/url\("(.*)"\)/);
          if (urlMatch && urlMatch[1]) {
            const originalUrl = urlMatch[1];
            const dataUrl = await fetchImageFromServer(originalUrl);
            console.log(`FoundOne`)
            div.style.backgroundImage = `url(${dataUrl})`;
          }
        });
      
        const imgs = Array.from(node.getElementsByTagName('img'));
        const imgPromises = imgs.map(async (img:any) => {
          const originalUrl = img.src;
          console.log(`Found2`)

          const dataUrl = await fetchImageFromServer(originalUrl);
          img.src = dataUrl;
        });
      
        await Promise.all([...divPromises, ...imgPromises]);
      };
  
      await embedBackgroundImagesAsBase64(input);
  
      const style = {
        transform: `scale(${scale})`,
        transformOrigin: 'top left',
        width: `${width}px`,
        height: `${height}px`,
        position: 'absolute',
        left: '0',
        top: '0'
      };
  
      // Generate the PNG image
      const dataUrl = await domtoimage.toPng(input, { width: width * scale, height: height * scale, style });
  
      setIsLoading(false); // Set loading state to false after completion
  
      return dataUrl; // Return the PNG data URL for later use
    }
  };


export const insertBase64ImageIntoExcel = async (blob: Blob, imageUrl: string, id: string, col: number, row:number) => {
    const buffer = await blob.arrayBuffer();
    const workbook = new ExcelJS.Workbook();
    await workbook.xlsx.load(buffer);
    // Get the first worksheet
    const sheet = workbook.getWorksheet(1);
    if (sheet && sheet.properties) {
      sheet.properties.defaultRowHeight = 15; // Set default row height to 15 points
    } else {
      console.error("Sheet or sheet properties are undefined.");
    }
    sheet?.eachRow({ includeEmpty: true }, (row, rowNumber) => {
      row.height = 17; // Set a small height for the row
      row.eachCell((cell) => {
        cell.alignment = { wrapText: false }; // Prevent text wrapping
      });
    });
    // Fetch the image as a base64 string
    const base64Image = imageUrl;
  
    
  
    // Ensure the base64 string is in the correct format
    if (!base64Image.startsWith('data:image')) {
      throw new Error('Invalid image data');
    }
  
    // Add the image to the workbook
    const imageId = workbook.addImage({
      base64: base64Image.split(',')[1], // Strip off the "data:image/png;base64," part
      extension: 'png',
    });
    let totalWidth = 0;
    sheet?.columns.forEach(column => {
      totalWidth += column.width ? column.width * 7.5 : 8.43 * 7.5; // Approximate width in pixels, assuming default font size
    });
  
    // Calculate the total height of the content
    let totalHeight = 0;
    sheet?.eachRow({ includeEmpty: true }, (row, rowNumber) => {
      totalHeight += row.height ? row.height : 15; // Approximate height in pixels, assuming default font size
    });
  
    // Insert the image at row 20, column 1
    sheet?.addImage(imageId, {
      tl: { col: 0, row: row +3 }, // Top-left position (row 20, column 1, zero-indexed)
      ext: { width: totalWidth, height: 14*15 *col }, // Adjust the size of the image as needed
    }); 
  
  
    // Export the updated workbook as a Blob
    const updatedBuffer = await workbook.xlsx.writeBuffer();
    const updatedBlob = new Blob([updatedBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
  
    // Create a download link to download the updated Excel file
    const link = document.createElement('a');
    link.href = URL.createObjectURL(updatedBlob);
    link.download =  `shelf-export-sheet-${id}.xlsx`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  export const exportToExcel =async  (
    images: string[][][],
    imagesLaying: string[][][],
    columnWidths: number[],
    row: number,
    col: number,
    shelfDuplex: any[][][],
    colorConditionArrays: boolean[][][][], // Multi-dimensional boolean arrays for various conditions
    highlightConditionArrays: boolean[][][][], // Multi-dimensional boolean array for Marketing Material
    ledScreenConditionArrays: boolean[][][][], // Multi-dimensional boolean array for LED Screens
    fileName = 'grid_data.xlsx',
    naming:any,
    ean:any,
    exportArray:any,
    exportArrayEan:any
  ) => {
    const ws = utils.aoa_to_sheet([]);
    let rrpRowDetection = -1;
    // Step 1: Generate the worksheet content
    for (let customBoxIndex = 0; customBoxIndex < col; customBoxIndex++) {
      const dynamicBoxCount = Math.floor(columnWidths[customBoxIndex] / 30.5);
  
      for (let setIndex = 0; setIndex < row; setIndex++) {
        let mergeStartColumnMarketing = null;
        let mergeEndColumnMarketing = null;
        let mergeStartColumnLED = null;
        let mergeEndColumnLED = null;
  
        for (let boxIndex = 0; boxIndex < dynamicBoxCount; boxIndex++) {
          const actualRow = setIndex;
          const actualColumn = customBoxIndex * dynamicBoxCount + boxIndex;
  
          const imageSrc = naming[customBoxIndex]?.[actualRow]?.[boxIndex] || '';
          const eancode = ean[customBoxIndex]?.[actualRow]?.[boxIndex] || '';
          const imageSrcLaying = naming[customBoxIndex]?.[actualRow]?.[boxIndex] || '';
  
          const isDuplexPusher = shelfDuplex[customBoxIndex]?.[actualRow]?.[boxIndex];
          const shouldColorRed = colorConditionArrays.some(array => array[customBoxIndex]?.[actualRow]?.[boxIndex]);
  
          // Generate the cell address in Excel format
          const cellAddress = utils.encode_cell({
            c: actualColumn,
            r: actualRow + 3,
          });
  
          // Determine cell value
          let cellValue = imageSrc +'\n' + eancode;
          if (isDuplexPusher && imageSrcLaying) {
            cellValue = `${imageSrc} // ${imageSrcLaying} \n  ${eancode}`;
          }
  
          ws[cellAddress] = { v: cellValue };
  
          // Track RRP row for exportArray adjustments
          if (shouldColorRed) {
            if (rrpRowDetection === -1) {
              rrpRowDetection = setIndex;
            }
            for (let i = 0; i < exportArray.length; i++) {
              const exportData = exportArray[i]?.[setIndex - rrpRowDetection]?.[boxIndex];
              if (exportData) {
                cellValue = `${exportData.top} // ${exportData.bottom}`;
              }
            }
            let cellValueean =''
            for (let i = 0; i < exportArray.length; i++) {
              const exportDataEan = exportArrayEan[i]?.[setIndex - rrpRowDetection]?.[boxIndex];
              if (exportDataEan) {
                cellValueean = `${exportDataEan.top} // ${exportDataEan.bottom}`;
              }
            }
            ws[cellAddress] = { v: cellValue +'\n' + cellValueean};
          }
  
          // Check highlightConditionArrays for Marketing Material
          let shouldHighlightYellow = false;
          for (let i = 0; i < highlightConditionArrays.length; i++) {
            if (highlightConditionArrays[i][customBoxIndex]?.[actualRow]?.[boxIndex]) {
              shouldHighlightYellow = true;
              break;
            }
          }
  
          // Check ledScreenConditionArrays for LED Screens
          let shouldHighlightRed = false;
          for (let i = 0; i < ledScreenConditionArrays.length; i++) {
            if (ledScreenConditionArrays[i][customBoxIndex]?.[actualRow]?.[boxIndex]) {
              shouldHighlightRed = true;
              break;
            }
          }
  
          // Handle merging cells for Marketing Material
          if (shouldHighlightYellow) {
            if (mergeStartColumnMarketing === null) {
              mergeStartColumnMarketing = actualColumn;
            }
            mergeEndColumnMarketing = actualColumn;
  
            // If it's the last cell in the row or the next cell doesn't satisfy the condition, merge and apply style
            if (boxIndex === dynamicBoxCount - 1 || !highlightConditionArrays.some(
                array => array[customBoxIndex]?.[actualRow]?.[boxIndex + 1])) {
  
              ws['!merges'] = ws['!merges'] || [];
              ws['!merges'].push({
                s: { c: mergeStartColumnMarketing, r: actualRow + 3 },
                e: { c: mergeEndColumnMarketing, r: actualRow + 3 },
              });
  
              const mergedCellAddress = utils.encode_cell({ c: mergeStartColumnMarketing, r: actualRow + 3 });
              ws[mergedCellAddress] = {
                v: "Marketing Material",
                s: {
                  fill: {
                    patternType: 'solid',
                    fgColor: { rgb: 'FFFF00' }, // Yellow background
                  },
                  alignment: { horizontal: 'center', vertical: 'center', wrapText: true },
                  font: { name: 'Arial', bold: true, sz: 10 }, // Bold and Arial
                },
              };
  
              // Reset for next potential merge
              mergeStartColumnMarketing = null;
              mergeEndColumnMarketing = null;
            }
          } else {
            // If the current cell doesn't satisfy the condition, reset merging variables
            mergeStartColumnMarketing = null;
            mergeEndColumnMarketing = null;
          }
  
          // Handle merging cells for LED Screens
          if (shouldHighlightRed) {
            if (mergeStartColumnLED === null) {
              mergeStartColumnLED = actualColumn;
            }
            mergeEndColumnLED = actualColumn;
  
            // If it's the last cell in the row or the next cell doesn't satisfy the condition, merge and apply style
            if (boxIndex === dynamicBoxCount - 1 || !ledScreenConditionArrays.some(
                array => array[customBoxIndex]?.[actualRow]?.[boxIndex + 1])) {
  
              ws['!merges'] = ws['!merges'] || [];
              ws['!merges'].push({
                s: { c: mergeStartColumnLED, r: actualRow + 3 },
                e: { c: mergeEndColumnLED, r: actualRow  + 3 },
              });
  
              const mergedCellAddress = utils.encode_cell({ c: mergeStartColumnLED, r: actualRow + 3 });
              ws[mergedCellAddress] = {
                v: "LED Screens",
                s: {
                  fill: {
                    patternType: 'solid',
                    fgColor: { rgb: 'FFCCCC' }, // Light red background
                  },
                  alignment: { horizontal: 'center', vertical: 'center', wrapText: true },
                  font: { name: 'Arial', bold: true, sz: 10 }, // Bold and Arial
                },
              };
  
              // Reset for next potential merge
              mergeStartColumnLED = null;
              mergeEndColumnLED = null;
            }
          } else {
            // If the current cell doesn't satisfy the condition, reset merging variables
            mergeStartColumnLED = null;
            mergeEndColumnLED = null;
          }
        }
      }
    }
  
    // Step 2: Handle merging for colorConditionArrays separately
    for (let i = 0; i < colorConditionArrays.length; i++) {
      for (let setIndex = 0; setIndex < row; setIndex++) {
        let mergeStartColumn = null;
        let mergeEndColumn = null;
        let mergedValue = ''; // Variable to accumulate merged cell value
        let valueName = ''; // Variable to accumulate merged cell value
        let valueEan = ''; // Variable to accumulate merged cell value
  
  
        for (let customBoxIndex = 0; customBoxIndex < col; customBoxIndex++) {
          const dynamicBoxCount = Math.floor(columnWidths[customBoxIndex] / 30.5);
          for (let boxIndex = 0; boxIndex < dynamicBoxCount; boxIndex++) {
            const shouldColorRed = colorConditionArrays[i]?.[customBoxIndex]?.[setIndex]?.[boxIndex];
  
            if (shouldColorRed) {
              const actualColumn = customBoxIndex * dynamicBoxCount + boxIndex;
              if (mergeStartColumn === null) {
                mergeStartColumn = actualColumn;
              }
              mergeEndColumn = actualColumn;
  
              // Add to the merged value if colorConditionArray is met
              const exportData = exportArray[i]?.[setIndex - rrpRowDetection]?.[boxIndex];
              const exportDataean = exportArrayEan[i]?.[setIndex - rrpRowDetection]?.[boxIndex];
  
              if (exportData) {
                if(exportData.bottom ===''){
                 valueName +=`(${exportData.top} ) | `
                valueEan += `(${exportDataean.top} ) | `
                }else if(exportData.top ==='') {
       valueName +=`(${exportData.bottom}) | `
                valueEan += `( ${exportDataean.bottom}) | `
                }else {
   valueName +=`(${exportData.top} // ${exportData.bottom}) | `
                valueEan += `(${exportDataean.top} // ${exportDataean.bottom}) | `
                }
               
  
                mergedValue = `${valueName} \n  ${valueEan}`;
  
              }
            }
          }
        }
  
        if (mergeStartColumn !== null && mergeEndColumn !== null && mergeStartColumn !== mergeEndColumn) {
          ws['!merges'] = ws['!merges'] || [];
          ws['!merges'].push({
            s: { c: mergeStartColumn, r: setIndex + 3 },
            e: { c: mergeEndColumn, r: setIndex  + 3},
          });
  
          // Set the merged cell value
          const mergedCellAddress = utils.encode_cell({ c: mergeStartColumn, r: setIndex + 3 });
  
          if (mergedValue.trim()) {
            ws[mergedCellAddress] = {
              v: mergedValue.trim(),
              s: {
                fill: {
                  patternType: 'solid',
                  fgColor: { rgb: 'ADD8E6' }, // Light blue background
                },
                alignment: { horizontal: 'center', vertical: 'center', wrapText: true },
                font: { name: 'Arial', bold: false, sz: 10 }, // Bold and Arial
              },
            };
          }
        }
      }
    }
  
    // Define the range of the worksheet
    const range = {
      s: { c: 0, r: 3 },
      e: { c: col * Math.floor(columnWidths[0] / 30.5) - 1, r: row - 1 + 3 },
    };
    ws['!ref'] = utils.encode_range(range);
  
    // Create a workbook and add the worksheet
    const wb = utils.book_new();
    utils.book_append_sheet(wb, ws, 'Grid Data');
  
     // Write the Excel file to a blob
     const buffer = write(wb, { type: 'array' });
     const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
  
     return blob;
    // writeFile(wb, fileName);
  
    // Export the Excel file
  };



  export const exportToExcelForBluk =async  (
    shelfConfigData:any,
    exportOptions: {
      compositionName: boolean;
      eanCode: boolean;
      validityDate: boolean;
    },
  
id:string,
  ) => {
    //alert(exportOptions.toString())
    const shelfConfig = shelfConfigData
    const columnWidths = shelfConfig.widths.map((width: any) => width * 5)
    const col = shelfConfig.col_position
    const row = shelfConfig.row_position
    const keyAccountId= shelfConfig.key_account_id
    const shelfDuplex = shelfConfig.duplex || []
    const colDistance = shelfConfig.colDistance
    const  rowDistance= shelfConfig.rowDistance
    const pmiPositions= shelfConfig.pmiPositions
    const marketingMaterialIds = shelfConfig.marketingMaterials || [];
    const ledScreensIds = shelfConfig.ledScreens || [];
    const name = shelfConfig.ledScreens;
    let validFrom = null 
    let  validTo =null 
    if (shelfConfig.validFrom) {
      const validFromDate = new Date(shelfConfig.validFrom);
      validFromDate.setMinutes(validFromDate.getMinutes() + validFromDate.getTimezoneOffset());
      validFrom = (validFromDate);
    }

    if (shelfConfig.validTo) {
      const validToDate = new Date(shelfConfig.validTo);
      validToDate.setMinutes(validToDate.getMinutes() + validToDate.getTimezoneOffset());
      validTo = validToDate 
    }
    const skus =await getAllSkus()

    type PackItem = {
      id: string;
      pack_image: string;
      pack_image_laying?: string;
    };
    const getPackImageById = (id: string, items: PackItem[]): string | undefined => {
      const item = items.find(item => item.id === id);
      return item ? item.pack_image : undefined;
    };
    const getPackImageByIdLaying = (id: string, items: PackItem[]): string | undefined => {
      const item = items.find(item => item.id === id);
      return item ? item.pack_image_laying : undefined;
    };
    const getPackNameById = (id: string, items: PackItem[]): string | undefined => {
      const item = items.find(item => item.id === id);
      return item ? (item as any).brandName + ' ' + (item as any).variantName: undefined;
    };
    const replaceIdsWithPackName = (idArray: string[][][], items: PackItem[]): string[][][] => {
      return idArray.map(layer =>
        layer.map(row =>
          row.map(id => getPackNameById(id, items) || id)
        )
      );
    };
    const replaceIdsWithPackImages = (idArray: string[][][], items: PackItem[]): string[][][] => {
      const duplexUpdated = shelfConfig?.duplex != null  ? addTrueNextToExistingTrue(shelfConfig.duplex) : null
      console.log('duplexUpdated',duplexUpdated)
     return idArray.map((layer,layerIndex) =>
       layer.map((row,rowIndex) =>
        
       {
         let dx = 0
         
         return  row.map((id,idIndex) => {
          
           if( shelfConfig?.duplex != null && (duplexUpdated[layerIndex][rowIndex][idIndex]  ) ) {
            
          
            
             return getPackImageByIdLaying(id, items) || id
           }else {
             return getPackImageById(id, items) || id
           }
         
         
         }
       )}
       )
     );
   };
   const getPackEanById = (id: string, items: PackItem[]): string | undefined => {
    const item = items.find(item => item.id === id);
    return item ? (item as any).ean_code: undefined;
  };
   const replaceIdsWithPackEan = (idArray: string[][][], items: PackItem[]): string[][][] => {
    return idArray.map(layer =>
      layer.map(row =>
        row.map(id => getPackEanById(id, items) || id)
      )
    );
  };
    let squaresWithImages: any[][][] =[]
    let squaresWithNames: any[][][] =[]
    let squaresWithEan: any[][][] =[]


    if (
      shelfConfig &&
      shelfConfig.facings &&
      shelfConfig.facings != null &&
      shelfConfig.facings[0] != null
    ) {
      squaresWithImages = replaceIdsWithPackImages(shelfConfig.facings,skus)
      squaresWithNames = (replaceIdsWithPackName(shelfConfig.facings,skus));
      squaresWithEan = (replaceIdsWithPackEan(shelfConfig.facings,skus));



    } else {

      const savedBoxes = updatePmiPositions(shelfConfig.duplex || [],shelfConfig.pmiPositions|| []);
        console.log('the save boxes',savedBoxes)
        squaresWithImages = replaceWithEmptyString(savedBoxes)
        squaresWithNames = replaceWithEmptyString(savedBoxes)
        squaresWithEan = replaceWithEmptyString(savedBoxes)



    }
    const materialMap = new Map<number, any & { isAdded: number, instances: { x: number, y: number }[] }>();
    const ledMap = new Map<number, any & { isAdded: number, instances: { x: number, y: number }[] }>();

    // Store materials with their respective x and y values
    for (const item of marketingMaterialIds) {
      const material = await getMarketingMaterialById(item.id);
      if (materialMap.has(material.id)) {
        const existingMaterial = materialMap.get(material.id)!;
        existingMaterial.isAdded! += 1;
        existingMaterial.instances.push({ x: item.x, y: item.y });
      } else {
        materialMap.set(material.id, { ...material, isAdded: 1, instances: [{ x: item.x, y: item.y }] });
      }
    }
    for (const item of ledScreensIds) {
      const material = await getLedScreenById(item.id);
      if (ledMap.has(material.id)) {
        const existingMaterial = ledMap.get(material.id)!;
        existingMaterial.isAdded! += 1;
        existingMaterial.instances.push({ x: item.x, y: item.y });
      } else {
        ledMap.set(material.id, { ...material, isAdded: 1, instances: [{ x: item.x, y: item.y }] });
      }
    }


    // Expand materials with their respective x and y values
    const yellowSquares = Array.from(materialMap.values()).flatMap(material => 
      material.instances.map((instance:any) => ({
        ...material,
        x: instance.x,
        y: instance.y,
        isAdded: 1 // Set isAdded to 1 for each instance in the expanded list
      }))
    );
    const pinkSquares = Array.from(ledMap.values()).flatMap(material => 
      material.instances.map((instance:any) => ({
        ...material,
        x: instance.x,
        y: instance.y,
        isAdded: 1 // Set isAdded to 1 for each instance in the expanded list
      }))
    );
    const rrpConfigurations = await Promise.all(
      (shelfConfig.rrp || []).map(async (rrp: { id: number, x: number, y: number }) => {
        const fullRRP = await getRRPNewConfigById(rrp.id.toString());
        return {
          ...fullRRP,
          x: rrp.x,
          y: rrp.y,
        };
      })
    );
    const blueSquares = rrpConfigurations.map((value) => ({
      id: value.id,
      title: value?.name,
      width: value.widths[0] *5  || 250 ,
      height: (value.row_position ) * 51.5+ (value.row_position  - 1) * (colDistance *5),
      x: value.x || 0,
      y: value.y || 0,
    }));
    let selectedBoxesPMIOverLay =columnWidths.map((columnWidth:any) => {
      const boxesInColumn = Math.floor(columnWidth / 30.5);
      return new Array(row )
        .fill(null)
        .map(() => new Array(boxesInColumn).fill(false));
    })
    let pink = pinkSquares.map(({width,height,x,y})=>{
      return handleMouseUp((width*5),(height*5),x,y,columnWidths,selectedBoxesPMIOverLay,rowDistance,colDistance)
   })
  
   let yellow =  yellowSquares.map(({width,height,x,y})=>{
    return handleMouseUp((width*5),(height*5),x,y,columnWidths,selectedBoxesPMIOverLay,rowDistance,colDistance)

   })
   let blue =  blueSquares.map(({width,height,x,y})=>{
     return  handleMouseUp(width,height,x,y,columnWidths,selectedBoxesPMIOverLay,rowDistance,colDistance)
    })
    if(pink.length > 0 ||yellow.length > 0  || blue.length > 0  ){
      selectedBoxesPMIOverLay = combineArrays([...pink,...yellow,...blue])
    

    }
      const rrpArea = blue
      const marketingMaterialArea = yellow
      const ledScreenArea = pink

      const colorConditionArrays = rrpArea
      const highlightConditionArrays = marketingMaterialArea
      const ledScreenConditionArrays = ledScreenArea
      let cloneSquaresWithImages =replaceWithEmptyString(selectedBoxesPMIOverLay)
      let cloneSquaresWithNaming =replaceWithEmptyString(selectedBoxesPMIOverLay)
      let cloneSquaresWithEan =replaceWithEmptyString(selectedBoxesPMIOverLay)
     let cloneSquaresWithWidths =replaceWithEmptyString(selectedBoxesPMIOverLay)
      let cloneSquaresWithHeights =replaceWithEmptyString(selectedBoxesPMIOverLay)
      let cloneSquaresWithImagesLaying =replaceWithEmptyString(selectedBoxesPMIOverLay)
      console.log('shelfDuplex',shelfDuplex)
      console.log('pmiPositions',pmiPositions)
      console.log('selectedBoxesPMIOverLay',selectedBoxesPMIOverLay)


      const resultData = await fetchData(keyAccountId,shelfDuplex,pmiPositions,selectedBoxesPMIOverLay)
      console.log('resultData',resultData)
       //***************************************** */
       let ind = 0
       if(selectedBoxesPMIOverLay.length > 0) {
         const numRows = selectedBoxesPMIOverLay.length;
         const numCols = selectedBoxesPMIOverLay[0].length;
         console.log("numRows",selectedBoxesPMIOverLay)
         for (let columnIndex = 0; columnIndex < numCols; columnIndex++) {
           const newRow = [];
           for (let rowIndex = 0; rowIndex < numRows; rowIndex++) {
            resultData?.combinedArray[rowIndex][columnIndex].forEach((_item, box) => {
               if(!resultData.combinedArray[rowIndex][columnIndex][box] && !squaresWithImages[rowIndex]?.[columnIndex]?.[box]) {
                cloneSquaresWithNaming[rowIndex][columnIndex][box] = 
                resultData.facedArrayNaming[ind]
                    ? resultData.facedArrayNaming[ind]?.charAt(0).toUpperCase() + resultData.facedArrayNaming[ind].slice(1)
                    : "";
                             ind ++;
               } else if(squaresWithImages[rowIndex]?.[columnIndex]?.[box]) {
                cloneSquaresWithNaming[rowIndex][columnIndex][box] = 
                squaresWithNames[rowIndex]?.[columnIndex]?.[box]
                    ? squaresWithNames[rowIndex][columnIndex][box]?.charAt(0).toUpperCase() + squaresWithNames[rowIndex][columnIndex][box].slice(1)
                    : "";
                             ind ++;
               }else {
                 console.log("pk")
               }
             })
          
          
           }
         }
       }
       ind = 0
  if(selectedBoxesPMIOverLay.length > 0) {
    const numRows = selectedBoxesPMIOverLay.length;
    const numCols = selectedBoxesPMIOverLay[0].length;
    console.log("numRows",selectedBoxesPMIOverLay)
    for (let columnIndex = 0; columnIndex < numCols; columnIndex++) {
      const newRow = [];
      for (let rowIndex = 0; rowIndex < numRows; rowIndex++) {
        resultData?.combinedArray[rowIndex][columnIndex].forEach((_item, box) => {
          if(!resultData.combinedArray[rowIndex][columnIndex][box] && !squaresWithImages[rowIndex]?.[columnIndex]?.[box]) {
            cloneSquaresWithEan[rowIndex][columnIndex][box]=  resultData.facedArrayEan[ind] 
            ind ++;
          } else if(squaresWithImages[rowIndex]?.[columnIndex]?.[box]) {
            cloneSquaresWithEan[rowIndex][columnIndex][box]=squaresWithEan[rowIndex]?.[columnIndex]?.[box]
            ind ++;
          }else {
            console.log("pk")
          }
        })
     
     
      }
    }
  }
       const naming = cloneSquaresWithNaming
       const ean = cloneSquaresWithEan

        const skuImages = await fetchSkuImages()
        const exportArray: any = await Promise.all(
          blueSquares.map(async (value) => {
            const shelfConfig = await getRRPNewConfigById(value.id);
            const squaresData = shelfConfig.squaresData;
            const keyAccount = shelfConfig.key_account_id;
            const squaresDataFacing = await fetchSkusList(squaresData, keyAccount);
            const imagearr = createImageArray(squaresDataFacing, skuImages);
            return imagearr;
          })
        );
        const exportArrayEan: any = await Promise.all(
          blueSquares.map(async (value) => {
            const shelfConfig = await getRRPNewConfigById(value.id);
            const squaresData = shelfConfig.squaresData;
            const keyAccount = shelfConfig.key_account_id;
            const squaresDataFacing = await fetchSkusList(squaresData, keyAccount);
            const imagearr = createEanArray(squaresDataFacing, skuImages);
            return imagearr;
          })
        );
       console.log('exportArraytest',exportArray)

       //************************************** */
  const ws = utils.aoa_to_sheet([]);
  let rrpRowDetection = -1;

  console.log('ean',exportArrayEan)
  // Step 1: Generate the worksheet content
  for (let customBoxIndex = 0; customBoxIndex < col; customBoxIndex++) {
    const dynamicBoxCount = Math.floor(columnWidths[customBoxIndex] / 30.5);

    let cumulativeBoxCount = 0;

    for (let i = 0; i < customBoxIndex; i++) {
      cumulativeBoxCount += Math.floor(columnWidths[i] / 30.5);
    }
    for (let setIndex = 0; setIndex < row; setIndex++) {
      let mergeStartColumnMarketing = null;
      let mergeEndColumnMarketing = null;
      let mergeStartColumnLED = null;
      let mergeEndColumnLED = null;
      let duplexIndex =0
      for (let boxIndex = 0; boxIndex < dynamicBoxCount; boxIndex++) {
        const actualRow = setIndex;
        const actualColumn = cumulativeBoxCount + boxIndex;


        const isDuplexPusher = shelfDuplex[customBoxIndex]?.[actualRow]?.[boxIndex];
       
        const imageSrc = naming[customBoxIndex]?.[actualRow]?.[isDuplexPusher ? duplexIndex  :boxIndex] || '';

        const imageSrcLaying = naming[customBoxIndex]?.[actualRow]?.[duplexIndex+1] || '';
        const eancode = ean[customBoxIndex]?.[actualRow]?.[isDuplexPusher ? duplexIndex  :boxIndex] || '';
        const eancodeLaying = ean[customBoxIndex]?.[actualRow]?.[duplexIndex+1] || '';

        if(isDuplexPusher) {
          duplexIndex +=2
        }else {
          duplexIndex +=1

        }
        const shouldColorRed = colorConditionArrays.some(array => array[customBoxIndex]?.[actualRow]?.[boxIndex]);
       
        // Generate the cell address in Excel format
        const cellAddress = utils.encode_cell({
          c: actualColumn,
          r: actualRow + 3,
        });
        console.log('exportOptions',exportOptions)
        // Determine cell value
        let cellValue = imageSrc +'\n' + eancode;
        if(exportOptions.compositionName && exportOptions.eanCode) {
          cellValue = imageSrc +'\n' + eancode
        }else if (exportOptions.compositionName && !exportOptions.eanCode) {
          cellValue = imageSrc 
        }else if (!exportOptions.compositionName && exportOptions.eanCode) {
          cellValue = eancode 
        }else {
          cellValue = '-'
        }
        if (isDuplexPusher && imageSrcLaying &&  exportOptions.compositionName && exportOptions.eanCode) {
          cellValue = `${imageSrc} // ${imageSrcLaying} \n  ${eancode}  // ${eancodeLaying}`;
        }else if (isDuplexPusher && imageSrcLaying &&  exportOptions.compositionName && !exportOptions.eanCode) {
          cellValue = `${imageSrc} // ${imageSrcLaying} `;

        }else if (isDuplexPusher && imageSrcLaying &&  !exportOptions.compositionName && exportOptions.eanCode) {
          cellValue = `${eancode} //  ${eancodeLaying}`;
        }

        ws[cellAddress] = { v: cellValue };

        // Track RRP row for exportArray adjustments
        if (shouldColorRed) {
          if (rrpRowDetection === -1) {
            rrpRowDetection = setIndex;
          }
          
          for (let i = 0; i < exportArray.length; i++) {
            const exportData = exportArray[i]?.[setIndex - rrpRowDetection]?.[boxIndex];
            if (exportData) {
              cellValue = `${exportData.top} // ${exportData.bottom}`;
            }
          }
          let cellValueean =''
          for (let i = 0; i < exportArray.length; i++) {
            const exportDataEan = exportArrayEan[i]?.[setIndex - rrpRowDetection]?.[boxIndex];
            if (exportDataEan) {
              cellValueean = `${exportDataEan.top} // ${exportDataEan.bottom}`;
            }
          }
          ws[cellAddress] = { v: cellValue +'\n' + cellValueean};
        }

        // Check highlightConditionArrays for Marketing Material
        let shouldHighlightYellow = false;
        for (let i = 0; i < highlightConditionArrays.length; i++) {
          if (highlightConditionArrays[i][customBoxIndex]?.[actualRow]?.[boxIndex]) {
            shouldHighlightYellow = true;
            break;
          }
        }

        // Check ledScreenConditionArrays for LED Screens
        let shouldHighlightRed = false;
        for (let i = 0; i < ledScreenConditionArrays.length; i++) {
          if (ledScreenConditionArrays[i][customBoxIndex]?.[actualRow]?.[boxIndex]) {
            shouldHighlightRed = true;
            break;
          }
        }

        // Handle merging cells for Marketing Material
        if (shouldHighlightYellow) {
          if (mergeStartColumnMarketing === null) {
            mergeStartColumnMarketing = actualColumn;
          }
          mergeEndColumnMarketing = actualColumn;

          // If it's the last cell in the row or the next cell doesn't satisfy the condition, merge and apply style
          if (boxIndex === dynamicBoxCount - 1 || !highlightConditionArrays.some(
              array => array[customBoxIndex]?.[actualRow]?.[boxIndex + 1])) {

            ws['!merges'] = ws['!merges'] || [];
            ws['!merges'].push({
              s: { c: mergeStartColumnMarketing, r: actualRow+ 3 },
              e: { c: mergeEndColumnMarketing, r: actualRow + 3 },
            });

            const mergedCellAddress = utils.encode_cell({ c: mergeStartColumnMarketing, r: actualRow + 3 });
            ws[mergedCellAddress] = {
              v: "Marketing Material",
              s: {
                fill: {
                  patternType: 'solid',
                  fgColor: { rgb: 'FFFF00' }, // Yellow background
                },
                alignment: { horizontal: 'center', vertical: 'center', wrapText: true },
                font: { name: 'Arial', bold: true, sz: 10 }, // Bold and Arial
              },
            };

            // Reset for next potential merge
            mergeStartColumnMarketing = null;
            mergeEndColumnMarketing = null;
          }
        } else {
          // If the current cell doesn't satisfy the condition, reset merging variables
          mergeStartColumnMarketing = null;
          mergeEndColumnMarketing = null;
        }

        // Handle merging cells for LED Screens
        if (shouldHighlightRed) {
          if (mergeStartColumnLED === null) {
            mergeStartColumnLED = actualColumn;
          }
          mergeEndColumnLED = actualColumn;

          // If it's the last cell in the row or the next cell doesn't satisfy the condition, merge and apply style
          if (boxIndex === dynamicBoxCount - 1 || !ledScreenConditionArrays.some(
              array => array[customBoxIndex]?.[actualRow]?.[boxIndex + 1])) {

            ws['!merges'] = ws['!merges'] || [];
            ws['!merges'].push({
              s: { c: mergeStartColumnLED, r: actualRow + 3},
              e: { c: mergeEndColumnLED, r: actualRow + 3 },
            });

            const mergedCellAddress = utils.encode_cell({ c: mergeStartColumnLED, r: actualRow + 3 });
            ws[mergedCellAddress] = {
              v: "LED Screens",
              s: {
                fill: {
                  patternType: 'solid',
                  fgColor: { rgb: 'FFCCCC' }, // Light red background
                },
                alignment: { horizontal: 'center', vertical: 'center', wrapText: true },
                font: { name: 'Arial', bold: true, sz: 10 }, // Bold and Arial
              },
            };

            // Reset for next potential merge
            mergeStartColumnLED = null;
            mergeEndColumnLED = null;
          }
        } else {
          // If the current cell doesn't satisfy the condition, reset merging variables
          mergeStartColumnLED = null;
          mergeEndColumnLED = null;
        }
      }
    }
  }

  // Step 2: Handle merging for colorConditionArrays separately
  for (let i = 0; i < colorConditionArrays.length; i++) {
    for (let setIndex = 0; setIndex < row; setIndex++) {
      let mergeStartColumn = null;
      let mergeEndColumn = null;
      let mergedValue = ''; // Variable to accumulate merged cell value
      let valueName = ''; // Variable to accumulate merged cell value
      let valueEan = ''; // Variable to accumulate merged cell value


      for (let customBoxIndex = 0; customBoxIndex < col; customBoxIndex++) {
        const dynamicBoxCount = Math.floor(columnWidths[customBoxIndex] / 30.5);
        let cumulativeBoxCount = 0;

        for (let i = 0; i < customBoxIndex; i++) {
          cumulativeBoxCount += Math.floor(columnWidths[i] / 30.5);
        }
        for (let boxIndex = 0; boxIndex < dynamicBoxCount; boxIndex++) {
          const shouldColorRed = colorConditionArrays[i]?.[customBoxIndex]?.[setIndex]?.[boxIndex];

          if (shouldColorRed) {
            const actualColumn = cumulativeBoxCount  + boxIndex;

            if (mergeStartColumn === null) {
              mergeStartColumn = actualColumn;
            }
            mergeEndColumn = actualColumn;

            // Add to the merged value if colorConditionArray is met
            const exportData = exportArray[i]?.[setIndex - rrpRowDetection]?.[boxIndex];
            const exportDataean = exportArrayEan[i]?.[setIndex - rrpRowDetection]?.[boxIndex];
            const name = exportArray[i]?.map((row:any, rowIndex:any) => {
              if(i===setIndex - rrpRowDetection){
                return row.map((box:any, boxIndex:any) => {
                  const exportData = exportArray[i]?.[rowIndex]?.[boxIndex];
                  const exportDataean = exportArrayEan[i]?.[rowIndex]?.[boxIndex];
              
                  if (exportData) {
                    let valueName = "";
                    let valueEan = "";
                    let mergedValue = "";
              
                    if (exportData.bottom === "") {
                      valueName += `(${exportData.top}) | `;
                      valueEan += `(${exportDataean.top}) | `;
                    } else if (exportData.top === "") {
                      valueName += `(${exportData.bottom}) | `;
                      valueEan += `(${exportDataean.bottom}) | `;
                    } else {
                      valueName += `(${exportData.top} // ${exportData.bottom}) | `;
                      valueEan += `(${exportDataean.top} // ${exportDataean.bottom}) | `;
                    }
              
                    if (exportOptions.compositionName && exportOptions.eanCode) {
                      mergedValue = `${valueName}`;
                    } else if (exportOptions.compositionName && !exportOptions.eanCode) {
                      mergedValue = `${valueName}`;
                    } else if (!exportOptions.compositionName && exportOptions.eanCode) {
                      mergedValue = ``;
                    } else if (!exportOptions.compositionName && !exportOptions.eanCode) {
                      mergedValue = `-`;
                    }
              
                    return mergedValue;
                  }
              
                  return "-"; // Default value if no exportData exists
                });
              }
             
            }).flat(2).join(" ");
            const eans = exportArray[i]?.map((row:any, rowIndex:any) => {
              if(i===setIndex - rrpRowDetection){
                return row.map((box:any, boxIndex:any) => {
                  const exportData = exportArray[i]?.[rowIndex]?.[boxIndex];
                  const exportDataean = exportArrayEan[i]?.[rowIndex]?.[boxIndex];
              
                  if (exportData) {
                    let valueName = "";
                    let valueEan = "";
                    let mergedValue = "";
              
                    if (exportData.bottom === "") {
                      valueName += `(${exportData.top}) | `;
                      valueEan += `(${exportDataean.top}) | `;
                    } else if (exportData.top === "") {
                      valueName += `(${exportData.bottom}) | `;
                      valueEan += `(${exportDataean.bottom}) | `;
                    } else {
                      valueName += `(${exportData.top} // ${exportData.bottom}) | `;
                      valueEan += `(${exportDataean.top} // ${exportDataean.bottom}) | `;
                    }
              
                    if (exportOptions.compositionName && exportOptions.eanCode) {
                      mergedValue = `${valueEan}`;
                    } else if (exportOptions.compositionName && !exportOptions.eanCode) {
                      mergedValue = ``;
                    } else if (!exportOptions.compositionName && exportOptions.eanCode) {
                      mergedValue = `${valueEan}`;
                    } else if (!exportOptions.compositionName && !exportOptions.eanCode) {
                      mergedValue = `-`;
                    }
              
                    return mergedValue;
                  }
              
                  return "-"; // Default value if no exportData exists
                });
              }
            
            }).flat(2).join(" ");
         

            mergedValue= name+'\n '+eans
//             if (exportData) {
//               if(exportData.bottom ===''){
//                valueName +=`(${exportData.top} ) | `
//               valueEan += `(${exportDataean.top} ) | `
//               }else if(exportData.top ==='') {
//      valueName +=`(${exportData.bottom}) | `
//               valueEan += `( ${exportDataean.bottom}) | `
//               }else {
//  valueName +=`(${exportData.top} // ${exportData.bottom}) | `
//               valueEan += `(${exportDataean.top} // ${exportDataean.bottom}) | `
//               }
//               if(exportOptions.compositionName && exportOptions.eanCode) {
//                 mergedValue = `${valueName} \n  ${valueEan}`;
//               }
//               else if(exportOptions.compositionName && !exportOptions.eanCode) {
//                 mergedValue = `${valueName} `;
//               }else if (!exportOptions.compositionName && exportOptions.eanCode){
//                 mergedValue = ` ${valueEan}`;

//               }else if (!exportOptions.compositionName && !exportOptions.eanCode) {
//                 mergedValue = `-`;
//               }

           

//             }
          }
        }
      }

      if (mergeStartColumn !== null && mergeEndColumn !== null && mergeStartColumn !== mergeEndColumn) {
        ws['!merges'] = ws['!merges'] || [];
        ws['!merges'].push({
          s: { c: mergeStartColumn, r: setIndex + 3 },
          e: { c: mergeEndColumn, r: setIndex  + 3},
        });

        // Set the merged cell value
        const mergedCellAddress = utils.encode_cell({ c: mergeStartColumn, r: setIndex + 3 });

        if (mergedValue.trim()) {
          ws[mergedCellAddress] = {
            v: mergedValue.trim(),
            s: {
              fill: {
                patternType: 'solid',
                fgColor: { rgb: 'ADD8E6' }, // Light blue background
              },
              alignment: { horizontal: 'center', vertical: 'center', wrapText: true },
              font: { name: 'Arial', bold: false, sz: 10 }, // Bold and Arial
            },
          };
        }
      }
    }
  }

  if(exportOptions.validityDate) {
 // Format validFrom and validTo as 'dd-MM-yyyy' or another format of choice
 
 const formattedValidFrom = validFrom ? format(new Date(validFrom), 'dd-MM-yyyy')==='01-01-1970' ?'N/A' :  format(new Date(validFrom), 'dd-MM-yyyy') : 'N/A';
 const formattedValidTo = validTo ? format(new Date(validTo), 'dd-MM-yyyy')==='01-01-1970' ?'N/A' :  format(new Date(validTo), 'dd-MM-yyyy') : 'N/A';
 const cellAddress = utils.encode_cell({
   c: 0 ,
   r: row + 1 + 3,
 });
 ws['!merges'] = ws['!merges'] || [];
 ws['!merges'].push({
   s: { c: 0, r: row + 1+ 3 },
   e: { c: 6, r: row + 1  + 3},
 });

//  ws[cellAddress] = { v: `Valid From: ${formattedValidFrom} => Valid To ${formattedValidTo} `,  s: {
//    fill: {
//      patternType: 'solid',
//      fgColor: { rgb: 'FFFF00' }, // Yellow background
//    },
//    alignment: { horizontal: 'center', vertical: 'center', wrapText: true },
//    font: { name: 'Arial', bold: true, sz: 10 }, // Bold and Arial
//  }, };
  }
 
 // ws[`A${lastRow + 1}`] = { v: `Valid To: ${formattedValidTo}`, t: 's' };
  // Define the range of the worksheet
  const range = {
    s: { c: 0, r: 3 },
    e: { c: col * Math.floor(columnWidths[0] / 30.5) , r: row +1 + 3 },
  };
  ws['!ref'] = utils.encode_range(range);

  // Create a workbook and add the worksheet
  const wb = utils.book_new();
  if(name != null && name != '') {
    utils.book_append_sheet(wb, ws, `Shelf  ${id}`);

  }else {
    utils.book_append_sheet(wb, ws, `Shelf  ${id}`);

  }

   // Write the Excel file to a blob
   const buffer = write(wb, { type: 'array' });
   const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

   return blob;
  // writeFile(wb, fileName);
    // Export the Excel file
  };

  


  const fetchData = async (keyAccountId:any,shelfDuplex:Array3D,pmiPositions:Array3D,selectedBoxesPMIOverLay:Array3D) => {
    console.log('theCom',shelfDuplex)
    console.log('theCom',pmiPositions)
    console.log('theCom',selectedBoxesPMIOverLay)
    console.log('theCom',shelfDuplex)


    const combinedArray =    combineBooleanArrays( updatePmiPositions2(shelfDuplex || [],selectedBoxesPMIOverLay,selectedBoxesPMIOverLay) ,     updatePmiPositions(shelfDuplex || [],pmiPositions || []))

    const falseCount = combinedArray.flat(Infinity).filter((item:any) => item === false).length;
    const brandMapping: Record<number, string> = {
      1: 'RGD',
      4: 'Marlboro',
      2: 'Petra',
      3: 'L&M',
    };
    if(keyAccountId) {
      const [packOrderData, packOrderChesterData,getSkusOrderedByIncreaseOrderMarlboro2,getSkusOrderedByDecreaseOrderMarlboro2,getSkusOrderedByIncreaseOrderChesterfield2,getSkusOrderedByDecreaseOrderChesterfield2,getSkusWithMinFacingsMarlboro2,getSkusWithMinFacingsChesterfield2,skusList,configuration,skuOrderByBrand,orderedBrands] = await Promise.all([
        fetchSkusOrderedMarlboro(),
        fetchSkusOrderedChesterfield(),
        getSkusOrderedByIncreaseOrder(),
        getSkusOrderedByDecreaseOrder(),
        getSkusOrderedByIncreaseOrderChesterfield(),
        getSkusOrderedByDecreaseOrderChesterfield(),
        getSkusWithMinFacingsMarlboro(),
        getSkusWithMinFacingsChesterfield(),
        getAllSkus(),
        getConfiguration(),
        getSkusOrdered(),
        getOrderedBrands()
  
      ]);
    
        const MinFacingsKeyAccount = await getSkusWithPriority(keyAccountId, 'CC');
        const marlboroInc = MinFacingsKeyAccount
        .sort((a: any, b: any) => a.increase_order - b.increase_order);
      
      const skuPromises = orderedBrands.map((id) => {
        const brand = brandMapping[id];
        return getSkusOrderedByBrand(brand);
      });
      const results = await Promise.all(skuPromises);
      const combined = results.flat();
  
    
      console.log('combined',combined)
  
      console.log('skuOrderByBrand',orderedBrands)
      console.log('packOrderData',packOrderData.map((item:any) => item.pack_image.split('.')[0]))
      console.log('packOrderChesterData',packOrderChesterData.map((item:any) => item.pack_image.split('.')[0]))
      console.log('getSkusOrderedByIncreaseOrderMarlboro2',getSkusOrderedByIncreaseOrderMarlboro2.map((item:any) => item.pack_image.split('.')[0]))
      console.log('getSkusOrderedByDecreaseOrderMarlboro2',getSkusOrderedByDecreaseOrderMarlboro2.map((item:any) => item.pack_image.split('.')[0]))
      console.log('getSkusOrderedByIncreaseOrderChesterfield2',getSkusOrderedByIncreaseOrderChesterfield2.map((item:any) => item.pack_image.split('.')[0]))
      console.log('getSkusOrderedByDecreaseOrderChesterfield2',getSkusOrderedByDecreaseOrderChesterfield2.map((item:any) => item.pack_image.split('.')[0]))
      console.log('getSkusWithMinFacingsMarlboro',getSkusWithMinFacingsMarlboro2.map((item:any) => {return {minFacing:item.min_facings,title:item.pack_image.split('.')[0]}}))
      console.log('getSkusWithMinFacingsChesterfield',getSkusWithMinFacingsChesterfield2.map((item:any) => {return {minFacing:item.min_facings,title:item.pack_image.split('.')[0]}}))
  
      const packOrder = combined
      .map((item: any) => item.id.toString())
      .filter((id: string) => {
        const minFacingItem = MinFacingsKeyAccount.find(
          (facingItem: any) => facingItem.id.toString() === id
        );
        return minFacingItem ? minFacingItem.min_facings > 0 : true;
      }); 
      console.log('MinFacingsKeyAccountPOack',packOrder)
    
      const increaseOrder = marlboroInc
  .map((item: any) => item.id.toString())
  .filter((id: string) => {
    const minFacingItem = MinFacingsKeyAccount.find(
      (facingItem: any) => facingItem.id.toString() === id
    );
    return minFacingItem ? minFacingItem.min_facings > 0 : true;
  });
  console.log('MinFacingsKeyAccountPOack2',increaseOrder)

// Filter decreaseOrder based on min_facings and reverse it
const decreaseOrder = marlboroInc
  .map((item: any) => item.id.toString())
  .filter((id: string) => {
    const minFacingItem = MinFacingsKeyAccount.find(
      (facingItem: any) => facingItem.id.toString() === id
    );
    return minFacingItem ? minFacingItem.min_facings > 0 : true;
  })
  .reverse();
      const minimumFacing = MinFacingsKeyAccount.map((item: any) => {
        return { minFacing: item.min_facings, title: item.id.toString() };
      });
     
      const facedArrayMalboroNOrder =   createFacingArray( Math.ceil(falseCount * (100 * 0.01)), minimumFacing, packOrder, increaseOrder,decreaseOrder) ;
      const facedArrayMalboro =   orderByCustomOrder(facedArrayMalboroNOrder, packOrder) ;
  console.log('facedArrayMalboro',facedArrayMalboro)
     // Create a mapping of IDs to pack_image
     type PackItem = {
      id: string;
      pack_image: string;
    };
     console.log('skulist',skusList)
     const idToPackImageMap: { [key: string]: string } = {};
     (skusList as PackItem[]).forEach((item: PackItem) => {
       idToPackImageMap[item.id.toString()] = item.pack_image;
     });
     const idToPackImageMapNaming: { [key: string]: string } = {};
     (skusList as any[]).forEach((item: any) => {
      idToPackImageMapNaming[item.id.toString()] = (item as any).brandName + ' ' + (item as any).variantName;
     });
     const idToPackImageMapWidths: { [key: string]: string } = {};
     (skusList as any[]).forEach((item: any) => {
      idToPackImageMapWidths[item.id.toString()] = (item as any).width ;
     });
     const idToPackImageMapHeights: { [key: string]: string } = {};
     (skusList as any[]).forEach((item: any) => {
      idToPackImageMapHeights[item.id.toString()] = (item as any).height ;
     });
     const idToPackImageMapEAN: { [key: string]: string } = {};
     (skusList as any[]).forEach((item: any) => {
      idToPackImageMapEAN[item.id.toString()] = (item as any).ean_code ;
     });
     const idToPackImageMapLaying: { [key: string]: string } = {};
     (skusList as PackItem[]).forEach((item: PackItem) => {
      idToPackImageMapLaying[item.id.toString()] = (item as any).pack_image_laying;
     });
     console.log('idToPackImageMapLaying',idToPackImageMapNaming)
      // Map the IDs to their corresponding pack_image
    const facedArrayMalboroWithImages = facedArrayMalboro.map(id => (idToPackImageMap[id]?.toString() || ''));
    const facedArrayMalboroWithNaming = facedArrayMalboro.map(id => (idToPackImageMapNaming[id]?.toString() || ''));
    const facedArrayMalboroWithWidths = facedArrayMalboro.map(id => (idToPackImageMapWidths[id]?.toString() || ''));
    const facedArrayMalboroWithHeights = facedArrayMalboro.map(id => (idToPackImageMapHeights[id]?.toString() || ''));

    const facedArrayMalboroWithean = facedArrayMalboro.map(id => (idToPackImageMapEAN[id]?.toString() || ''));
  
    const facedArrayMalboroWithImagesLaying = facedArrayMalboro.map(id => (idToPackImageMapLaying[id]?.toString() || ''));
    // setFacedArray([...facedArrayMalboroWithImages]);
    // setFacedArrayNaming([...facedArrayMalboroWithNaming]);
    // setFacedArrayWidths([...facedArrayMalboroWithWidths]);
    // setFacedArrayHeights([...facedArrayMalboroWithHeights]);

    // setFacedArrayEan([...facedArrayMalboroWithean]);
  
    
    // console.log('[...facedArrayMalboroWithNaming]',[...facedArrayMalboroWithNaming])
    // setFacedArrayLaying([...facedArrayMalboroWithImagesLaying]);
    return {
      facedArray:[...facedArrayMalboroWithImages],
      facedArrayNaming:[...facedArrayMalboroWithNaming],
      facedArrayWidths:[...facedArrayMalboroWithWidths],
      facedArrayHeights:[...facedArrayMalboroWithHeights],
      facedArrayEan:[...facedArrayMalboroWithean],
      facedArrayLaying:[...facedArrayMalboroWithImagesLaying],
      combinedArray:combinedArray
    }
    }
   

  // setFacedArray([...facedArrayMalboro,...facedArrayChester]) 
  };


  function createFacingArray(n:any, minimumFacing:any, packOrder:any, increaseOrder:any, decreaseOrder:any) {
    // Initialize the array without null values initially
    let resultArray = [];

    // Create a map from minimumFacing for quick lookup
    const facingMap = new Map(minimumFacing.map((item:any) => [item.title.trim(), item.minFacing]));

    // Fill the array based on the order and minFacing values
    for (let pack of packOrder) {
        pack = pack.trim(); // Clean any whitespace
        if (facingMap.has(pack)) {
            let count = facingMap.get(pack);
            for (let i = 0; i < (count as any); i++) {
                resultArray.push(pack);
            }
        }
    }

    // Handle the case where the initial array is less than n
    let currentIndex = resultArray.length;
    while (currentIndex < n) {
        for (let pack of increaseOrder) {
            if (currentIndex >= n) break;
            pack = pack.trim();
            resultArray.push(pack);
            currentIndex++;
        }
    }

    // If the total exceeds n, start decreasing according to the decreaseOrder
    currentIndex = resultArray.length;
    if (currentIndex > n) {
        let decreaseIndex = 0;
        while (currentIndex > n) {
            let packToRemove = decreaseOrder[decreaseIndex % decreaseOrder.length].trim();
            let removalIndex = resultArray.lastIndexOf(packToRemove);
            if (removalIndex !== -1) {
                resultArray.splice(removalIndex, 1);
                currentIndex--;
            }
            decreaseIndex++;
        }
    }

    // Fill any remaining 'null' slots if needed (safety check)
    resultArray = resultArray.concat(new Array(n - resultArray.length).fill(null));

    return resultArray;
}

function orderByCustomOrder(stringsArray: string[], orderArray: string[]): string[] {
  return stringsArray.sort((a, b) => orderArray.indexOf(a) - orderArray.indexOf(b));
}

function combineBooleanArrays(array1: boolean[][][], array2: boolean[][][]): boolean[][][] {
  // Check if both arrays have the same dimensions

  if (array1.length !== array2.length) {
    throw new Error('Arrays do not have the same dimensions');
  }

  const resultArray: boolean[][][] = [];

  // Iterate over the first dimension
  for (let i = 0; i < array1.length; i++) {
    if (array1[i].length !== array2[i].length) {
      console.log("dimentions test")
      console.log(array1[i].length)
      console.log(array2[i].length)
      throw new Error('Arrays do not have the same dimensions');
    }

    const layer: boolean[][] = [];

    // Iterate over the second dimension
    for (let j = 0; j < array1[i].length; j++) {
      if (array1[i][j].length !== array2[i][j].length) {
        console.log("dimentions test")
        console.log(array1[i][j].length)
        console.log(array2[i].length)
        throw new Error('Arrays do not have the same dimensions');
      }

      const row: boolean[] = [];

      // Iterate over the third dimension and apply the OR operation
      for (let k = 0; k < array1[i][j].length; k++) {
        row.push(array1[i][j][k] || !array2[i][j][k]);
      }

      layer.push(row);
    }

    resultArray.push(layer);
  }

  return resultArray;
}

export const updatePmiPositions2 = (shelfDuplex: Array3D, pmiPositions: Array3D,selectedBoxesPMIOverLay:any): Array3D => {
  // Create a deep copy of pmiPositions to avoid mutating the original array
  const updatedPmiPositions = pmiPositions.map(layer => layer.map(row => [...row]));

  shelfDuplex.forEach((layer, layerIndex) => {
    layer.forEach((row, rowIndex) => {
      row.forEach((hasDuplexPusher, colIndex) => {
        if (hasDuplexPusher ) {
          // If the next position is within bounds and not already true, insert true
          if (colIndex + 1 < row.length && !selectedBoxesPMIOverLay[layerIndex]?.[rowIndex]?.[colIndex] ) {
          
              updatedPmiPositions[layerIndex][rowIndex].splice(colIndex + 1, 0, false);
            
          } else if( !selectedBoxesPMIOverLay[layerIndex]?.[rowIndex]?.[colIndex] ) {
            // If the next position is out of bounds, push true to the end
            updatedPmiPositions[layerIndex][rowIndex].push(false);
          }else {
            updatedPmiPositions[layerIndex][rowIndex].push(true);

          }
        }
      });
    });
  });
return updatedPmiPositions;
}; 

function addTrueNextToExistingTrue(array:any) {
  // Create a new array to store the modified structure
  const newArray = array.map((subArray2D:any) => 
      subArray2D.map((subArray1D:any) => {
          const newSubArray = [];
          // Loop through the 1D array and add a duplicate true next to each true
          for (let k = 0; k < subArray1D.length; k++) {
              newSubArray.push(subArray1D[k]); // Add the current element
              if (subArray1D[k] === true) {
                  newSubArray.push(true); // Add an extra true if the current element is true
              }
          }
          return newSubArray;
      })
  );
  return newArray;
}


interface SquareData {
  row: number;
  col: number;
  duplexTypes: { id: number; value: number ,color:string,text:string,width:number,images:string[]}[];
}

const createImageArray = (squaresDataFacing:SquareData[],skuImages:Record<number, { packImage: string | null; layingPackImage: string | null }>) => {
  // Initialize the new array
  const newArray: { top: string; bottom: string }[][] = [];

  // Iterate through each row
  squaresDataFacing.forEach((rowData) => {
    let rowArray: { top: string; bottom: string }[] = [];

    // Iterate through each duplexType in the row
    rowData.duplexTypes.forEach((duplexType) => {
      // Iterate through each boxIdx (index for each image pair)
      for (let boxIdx = 0; boxIdx < duplexType.value; boxIdx++) {
        // Extract imageTopSecond and imageBottomSecond
        const imageTopSecond =  duplexType.images?.[boxIdx * 2] && skuImages[parseInt(duplexType.images?.[boxIdx * 2])] ? (skuImages[parseInt(duplexType.images?.[boxIdx * 2])] as any).name : null;
        const imageBottomSecond =  duplexType.images?.[boxIdx * 2 + 1] && skuImages[parseInt(duplexType.images?.[boxIdx * 2 + 1])] ?( skuImages[parseInt(duplexType.images?.[boxIdx * 2 + 1])] as any).name : null;

        // Create an object for the image pair
        const imageObject = {
          top: imageTopSecond || "",
          bottom: imageBottomSecond || "",
        };

        // Only add the object if either top or bottom is not empty
        if (imageObject.top || imageObject.bottom) {
          rowArray.push(imageObject);
        }
      }
    });

    // Add the row to the new array if it has valid objects
    if (rowArray.length > 0) {
      newArray.push(rowArray);
    }
  });

  console.log("Filtered Image Array with Objects: ", newArray);
  return newArray;
};
const createEanArray = (squaresDataFacing:SquareData[],skuImages:Record<number, { packImage: string | null; layingPackImage: string | null }>) => {
  // Initialize the new array
  const newArray: { top: string; bottom: string }[][] = [];

  // Iterate through each row
  squaresDataFacing.forEach((rowData) => {
    let rowArray: { top: string; bottom: string }[] = [];

    // Iterate through each duplexType in the row
    rowData.duplexTypes.forEach((duplexType) => {
      // Iterate through each boxIdx (index for each image pair)
      for (let boxIdx = 0; boxIdx < duplexType.value; boxIdx++) {
        // Extract imageTopSecond and imageBottomSecond
        const imageTopSecond = skuImages[parseInt(duplexType.images?.[boxIdx * 2])] ? (skuImages[parseInt(duplexType.images?.[boxIdx * 2])] as any).ean : null;
        const imageBottomSecond = skuImages[parseInt(duplexType.images?.[boxIdx * 2 + 1])] ?( skuImages[parseInt(duplexType.images?.[boxIdx * 2 + 1])] as any).ean : null;

        // Create an object for the image pair
        const imageObject = {
          top: imageTopSecond || "",
          bottom: imageBottomSecond || "",
        };

        // Only add the object if either top or bottom is not empty
        if (imageObject.top || imageObject.bottom) {
          rowArray.push(imageObject);
        }
      }
    });

    // Add the row to the new array if it has valid objects
    if (rowArray.length > 0) {
      newArray.push(rowArray);
    }
  });

  console.log("Filtered Image Array with Objects: ", newArray);
  return newArray;
};


const fetchOrderedSkus = async (brand: string) => {
  try {
    const data = await getSkusOrderedByBrand(brand, "RRP");
    return data
  } catch (error) {
    console.error("Error fetching ordered SKUs:", error);
  } finally {
  }
};
type ItemProps = {
  id: number;
  pack_image: string;
  variantName: string;
  brandName: string;
  min_facings: number;
};
interface DuplexTypeSummary {
  text: string;
  value: number;
}

const fetchSkusList = async (squaresData: SquareData[],keyAccount:number) => {
  const falseCount = 10
  const aggregatedData = squaresData.reduce<DuplexTypeSummary[]>((acc, current) => {
    current.duplexTypes.forEach((type:any) => {
        const existingType = acc.find((item:any) => item.text === type.text);
        if (existingType) {
            existingType.value += type.value;
        } else {
            acc.push({ text: type.text, value: type.value });
        }
    });
    return acc;
  }, []);
  
  
  const textsToDuplicate = [
    "VEEV ONE Standing & Laying DP",
    "VEEV ONE Laying DP",
    "HEETS",
    "LEVIA",
    "TEREA",
    "Delia",
    "Fiit"
  ];
  
  const updatedData = aggregatedData.map(item => {
    if (textsToDuplicate.includes(item.text)) {
        return { ...item, value: item.value * 2 };
    }
    return item;
  });
  
  const groupMappings: { [key: string]: string } = {
    "VEEV ONE Standing DP": "VEEV ONE",
    "VEEV ONE Standing & Laying DP": "VEEV ONE",
    "VEEV ONE Laying DP": "VEEV ONE",
    "VEEV ONE": "VEEV ONE",
    "VEEV NOW Laying  DP": "VEEV NOW",
    "VEEV Now Standing DP": "VEEV NOW",
    "VEEV NOW": "VEEV NOW"
  };
  
  const groupedData = updatedData.reduce((acc: { [key: string]: number }, item) => {
    const groupKey = groupMappings[item.text] || item.text;
    if (!acc[groupKey]) {
        acc[groupKey] = 0;
    }
    acc[groupKey] += item.value;
    return acc;
  }, {});
  
  const result = Object.keys(groupedData).map(key => ({
    text: key,
    value: groupedData[key]
  }));
  
  console.log(result);
  const veevOneValue = result.find(item => item.text === "VEEV ONE")?.value;
  const veevNowValue = result.find(item => item.text === "VEEV NOW")?.value;
  const ccLaying2DPValue = result.find(item => item.text === " CC Laying 2 DP")?.value;
  const hnbDPValue = result.find(item => item.text === "HNB DP")?.value;
  const fiitValue = result.find(item => item.text === "Fiit")?.value;
  const deliaValue = result.find(item => item.text === "Delia")?.value;
  const leviaValue = result.find(item => item.text === "LEVIA")?.value;
  const heetsValue = result.find(item => item.text === "HEETS")?.value;
  const tereaValue = result.find(item => item.text === "TEREA")?.value;


const data = await getSkusWithPriority(keyAccount, 'RRP');
const dataInc = data.sort((a: any, b: any) => a.increase_order - b.increase_order);
let heetsIncrease: ItemProps[] = [];
let tereaIncrease: ItemProps[] = [];
let fiitIncrease: ItemProps[] = [];
let deliaIncrease: ItemProps[] = [];
let veevOneIncrease: ItemProps[] = [];
let veevNowIncrease: ItemProps[] = [];
let leviaIncrease: ItemProps[] = [];

dataInc.forEach((item: ItemProps) => {
switch (item.brandName.toLowerCase()) {
case 'heets':
  heetsIncrease.push(item);
  break;
case 'terea':
  tereaIncrease.push(item);
  break;
case 'fiit':
  fiitIncrease.push(item);
  break;
case 'delia':
  deliaIncrease.push(item);
  break;
case 'veev one':
  veevOneIncrease.push(item);
  break;
case 'veev now':
  veevNowIncrease.push(item);
  break;
case 'levia':
  leviaIncrease.push(item);
  break;
default:
  break;
}
});
console.log('heetsIncrease',heetsIncrease)
const heets = (await fetchOrderedSkus('HEETS')).filter((item: any) => {
const matchingItem = heetsIncrease.find((incItem: any) => incItem.id === item.id);
return matchingItem ? matchingItem.min_facings > 0 : true;
});

const fiit = (await fetchOrderedSkus('FIIT')).filter((item: any) => {
const matchingItem = fiitIncrease.find((incItem: any) => incItem.id === item.id);
return matchingItem ? matchingItem.min_facings > 0 : true;
});

const terea = (await fetchOrderedSkus('TEREA')).filter((item: any) => {
const matchingItem = tereaIncrease.find((incItem: any) => incItem.id === item.id);
return matchingItem ? matchingItem.min_facings > 0 : true;
});

const delia = (await fetchOrderedSkus('DELIA')).filter((item: any) => {
const matchingItem = deliaIncrease.find((incItem: any) => incItem.id === item.id);
return matchingItem ? matchingItem.min_facings > 0 : true;
});

const VEEV_NOW = (await fetchOrderedSkus('VEEV NOW')).filter((item: any) => {
const matchingItem = veevNowIncrease.find((incItem: any) => incItem.id === item.id);
return matchingItem ? matchingItem.min_facings > 0 : true;
});

const VEEV_ONE = (await fetchOrderedSkus('VEEV ONE')).filter((item: any) => {
const matchingItem = veevOneIncrease.find((incItem: any) => incItem.id === item.id);
return matchingItem ? matchingItem.min_facings > 0 : true;
});

const levia = (await fetchOrderedSkus('LEVIA')).filter((item: any) => {
const matchingItem = leviaIncrease.find((incItem: any) => incItem.id === item.id);
return matchingItem ? matchingItem.min_facings > 0 : true;
});
console.log(heets[0].id)
// Convert to string IDs only for filtered items
const heetsIds = heets.map((item: any) => item.id.toString());
const fiitIds = fiit.map((item: any) => item.id.toString());
const tereaIds = terea.map((item: any) => item.id.toString());
const deliaIds = delia.map((item: any) => item.id.toString());
const veevNowIds = VEEV_NOW.map((item: any) => item.id.toString());
const veevOneIds = VEEV_ONE.map((item: any) => item.id.toString());
const leviaIds = levia.map((item: any) => item.id.toString());

const heetsData = heetsIncrease
.filter((item: any) => item.min_facings > 0)
.map((item: any) => item.id.toString());

const tereaData = tereaIncrease
.filter((item: any) => item.min_facings > 0)
.map((item: any) => item.id.toString());

const fiitData = fiitIncrease
.filter((item: any) => item.min_facings > 0)
.map((item: any) => item.id.toString());

const deliaData = deliaIncrease
.filter((item: any) => item.min_facings > 0)
.map((item: any) => item.id.toString());

const veevOneData = veevOneIncrease
.filter((item: any) => item.min_facings > 0)
.map((item: any) => item.id.toString());

const veevNowData = veevNowIncrease
.filter((item: any) => item.min_facings > 0)
.map((item: any) => item.id.toString());

const leviaData = leviaIncrease
.filter((item: any) => item.min_facings > 0)
.map((item: any) => item.id.toString());


const heetsDataReversed = [...heetsData].reverse();
const tereaDataReversed = [...tereaData].reverse();
const fiitDataReversed = [...fiitData].reverse();
const deliaDataReversed = [...deliaData].reverse();
const veevOneDataReversed = [...veevOneData].reverse();
const veevNowDataReversed = [...veevNowData].reverse();
const leviaDataReversed = [...leviaData].reverse();
const minimumFacingHeets = heetsIncrease
.filter((item: any) => item.min_facings > 0)
.map((item: any) => {
return { minFacing: item.min_facings, title: item.id.toString() };
});

const minimumFacingFiit = fiitIncrease
.filter((item: any) => item.min_facings > 0)
.map((item: any) => {
return { minFacing: item.min_facings, title: item.id.toString() };
});

const minimumFacingTerea = tereaIncrease
.filter((item: any) => item.min_facings > 0)
.map((item: any) => {
return { minFacing: item.min_facings, title: item.id.toString() };
});

const minimumFacingDelia = deliaIncrease
.filter((item: any) => item.min_facings > 0)
.map((item: any) => {
return { minFacing: item.min_facings, title: item.id.toString() };
});

const minimumFacingVeevNow = veevNowIncrease
.filter((item: any) => item.min_facings > 0)
.map((item: any) => {
return { minFacing: item.min_facings, title: item.id.toString() };
});

const minimumFacingVeevOne = veevOneIncrease
.filter((item: any) => item.min_facings > 0)
.map((item: any) => {
return { minFacing: item.min_facings, title: item.id.toString() };
});

const minimumFacingLevia = leviaIncrease
.filter((item: any) => item.min_facings > 0)
.map((item: any) => {
return { minFacing: item.min_facings, title: item.id.toString() };
});

 
  const facedArrayHeetsNOrder = createFacingArrayRRP(Math.ceil((heetsValue || 0) * (100 * 0.01)), minimumFacingHeets, heetsIds, heetsData, heetsDataReversed);
const facedArrayHeets = orderByCustomOrder(facedArrayHeetsNOrder, heetsIds);
console.log('test achivment')

// For FIIT

const facedArrayFiitNOrder = createFacingArrayRRP(Math.ceil((fiitValue || 0)  * (100 * 0.01)), minimumFacingFiit, fiitIds, fiitData, fiitDataReversed);
const facedArrayFiit = orderByCustomOrder(facedArrayFiitNOrder, fiitIds);

// For TEREA
const facedArrayTereaNOrder = createFacingArrayRRP(Math.ceil((tereaValue || 0)  * (100 * 0.01)), minimumFacingTerea, tereaIds, tereaData, tereaDataReversed);
const facedArrayTerea = orderByCustomOrder(facedArrayTereaNOrder, tereaIds);

// For DELIA
const facedArrayDeliaNOrder = createFacingArrayRRP(Math.ceil((deliaValue || 0) * (100 * 0.01)), minimumFacingDelia, deliaIds, deliaData, deliaDataReversed);
const facedArrayDelia = orderByCustomOrder(facedArrayDeliaNOrder, deliaIds);

// For VEEV NOW
const facedArrayVeevNowNOrder = createFacingArrayRRP(Math.ceil((veevNowValue || 0) * (100 * 0.01)), minimumFacingVeevNow, veevNowIds , veevNowData, veevNowDataReversed);
const facedArrayVeevNow = orderByCustomOrder(facedArrayVeevNowNOrder, veevNowIds);

// For VEEV ONE
const facedArrayVeevOneNOrder = createFacingArrayRRP(Math.ceil((veevOneValue || 0) * (100 * 0.01)), minimumFacingVeevOne, veevOneIds , veevOneData, veevOneDataReversed);
const facedArrayVeevOne = orderByCustomOrder(facedArrayVeevOneNOrder, veevOneIds);

// For LEVIA
const facedArrayLeviaNOrder = createFacingArrayRRP(Math.ceil((leviaValue || 0) * (100 * 0.01)), minimumFacingLevia, leviaIds , leviaData, leviaDataReversed);
const facedArrayLevia = orderByCustomOrder(facedArrayLeviaNOrder, leviaIds);

console.log("facedArrayHeets:", facedArrayHeets);
console.log("facedArrayFiit:", facedArrayFiit);
console.log("facedArrayTerea:", facedArrayTerea);
console.log("facedArrayDelia:", facedArrayDelia);
console.log("facedArrayVeevNow:", facedArrayVeevNow);
console.log("facedArrayVeevOne:", facedArrayVeevOne);
console.log("facedArrayLevia:", facedArrayLevia);

let facedArrayIndex = 0; // To keep track of where we are in facedArrayHeets
// Loop through each entry in data
const squaresData2 = JSON.parse(JSON.stringify(squaresData));
// To keep track of where we are in facedArrayHeets

// Loop through each entry in data
// squaresData2.forEach(entry => {
//     entry.duplexTypes.forEach(duplexType => {
//         if (textsToDuplicate.includes(duplexType.text)) {
//             // Double the value if the text is in the duplication list
//             duplexType.value *= 2;
//         }

//         if (duplexType.text === "HEETS" && facedArrayIndex < facedArrayHeets.length) {
//             // Initialize the images array if it doesn't exist
//             duplexType.images = [];

//             // Fill the images array with values from facedArrayHeets
//             while (facedArrayIndex < facedArrayHeets.length  && duplexType.images.length < duplexType.value) {
          
//                 duplexType.images.push(facedArrayHeets[facedArrayIndex]);
       
//                 facedArrayIndex++;
//             }
//         }
//     });
// });
const processFacings = (facedArray:any, text:any, data:any) => {
let facedArrayIndex = 0;

squaresData2.forEach((entry:any) => {
  entry.duplexTypes.forEach((duplexType:any) => {
      const mappedText = groupMappings[duplexType.text] || duplexType.text;

      if (textsToDuplicate.includes(duplexType.text) && mappedText === text) {
          // Double the value if the text is in the duplication list
          duplexType.value *= 2;
      }

      if (mappedText === text && facedArrayIndex < facedArray.length) {
          // Initialize the images array if it doesn't exist
          duplexType.images = [];

          // Fill the images array with values from facedArray
          while (facedArrayIndex < facedArray.length && duplexType.images.filter((image:any) => image !== null).length < duplexType.value) {
            if(duplexType.text==='VEEV ONE Standing DP'){
              duplexType.images.push(facedArray[facedArrayIndex].toString());
              duplexType.images.push(null);

            }else if (duplexType.text==="VEEV NOW Laying  DP"){
              duplexType.images.push(null);
              duplexType.images.push(facedArray[facedArrayIndex].toString());
            }else if (duplexType.text==='VEEV Now Standing DP'){
              duplexType.images.push(facedArray[facedArrayIndex].toString());
              duplexType.images.push(null);

            }else {
              duplexType.images.push(facedArray[facedArrayIndex].toString());

            }
              facedArrayIndex++;
          }
      }
  });
});
};

// Process all the facings
processFacings(facedArrayHeets, "HEETS", squaresData2);
processFacings(facedArrayFiit, "Fiit", squaresData2);
processFacings(facedArrayTerea, "TEREA", squaresData2);
processFacings(facedArrayDelia, "Delia", squaresData2);
processFacings(facedArrayVeevNow, "VEEV NOW", squaresData2);
processFacings(facedArrayVeevOne, "VEEV ONE", squaresData2);
processFacings(facedArrayLevia, "LEVIA", squaresData2);
console.log('squaresData2',squaresData2);

return squaresData2

};


function createFacingArrayRRP(n:any, minimumFacing:any, packOrder:any, increaseOrder:any, decreaseOrder:any) {
  // Initialize the array without null values initially
  if(packOrder.length ===0 ) {
    return []
  }
  let resultArray = [];

  // Create a map from minimumFacing for quick lookup
  const facingMap = new Map(minimumFacing.map((item:any) => [item.title.trim(), item.minFacing]));

  // Fill the array based on the order and minFacing values
  for (let pack of packOrder) {
      pack = pack.trim(); // Clean any whitespace
      if (facingMap.has(pack)) {
          let count = facingMap.get(pack);
          for (let i = 0; i < (count as any); i++) {
              resultArray.push(pack);
          }
      }
  }

  // Handle the case where the initial array is less than n
  let currentIndex = resultArray.length;
  while (currentIndex < n) {
      for (let pack of increaseOrder) {
          if (currentIndex >= n) break;
          pack = pack.trim();
          resultArray.push(pack);
          currentIndex++;
      }
  }

  // If the total exceeds n, start decreasing according to the decreaseOrder
  currentIndex = resultArray.length;
  if (currentIndex > n) {
      let decreaseIndex = 0;
      while (currentIndex > n) {
          let packToRemove = decreaseOrder[decreaseIndex % decreaseOrder.length].trim();
          let removalIndex = resultArray.lastIndexOf(packToRemove);
          if (removalIndex !== -1) {
              resultArray.splice(removalIndex, 1);
              currentIndex--;
          }
          decreaseIndex++;
      }
  }

  // Fill any remaining 'null' slots if needed (safety check)
  resultArray = resultArray.concat(new Array(n - resultArray.length).fill(null));

  return resultArray;
}


const fetchSkuImages = async () => {
  const skus = await getAllSkus();
    console.log('listsku',skus)
// Create a mapping from SKU IDs to their images
const imagesMap = skus.reduce((acc:any, sku:any) => {
  acc[sku.id] = {
    packImage: sku.pack_image || null,
    layingPackImage: sku.pack_image_laying || null,
    name: sku.brandName+ ' '+ sku.variantName || null,
    ean: sku.ean_code,


  };
  return acc;
}, {} as Record<number, { packImage: string | null; layingPackImage: string | null }>);

// Set the images map to state

  return imagesMap ;
};


  export const insertBase64ImageIntoExcelBlob = async (blob: Blob, imageUrl: string, shelfName:string, dateString:string,id:number,row:number) => {
  const buffer = await blob.arrayBuffer();
  const workbook = new ExcelJS.Workbook();
  await workbook.xlsx.load(buffer);
   console.log('buffer loadig done')
  // Get the first worksheet
  const sheet = workbook.getWorksheet(1);
  if (sheet && sheet.properties) {
    sheet.properties.defaultRowHeight = 15; // Set default row height to 15 points
  } else {
    console.error("Sheet or sheet properties are undefined.");
  }
  const safelyMergeCells = (sheet: any, startRow: number, startCol: number, endRow: number, endCol: number) => {
    try {
      // Attempt to merge the specified range
      sheet.mergeCells(startRow, startCol, endRow, endCol);
    } catch (error) {
      console.warn(`Merge failed for range ${startRow}:${startCol} to ${endRow}:${endCol}`);
      // Handle the error if needed (e.g., log or ignore)
    }
  };
  const shelfNameRow = sheet?.getRow(1);
if (shelfNameRow) {
  shelfNameRow.getCell(1).value = ` ${shelfName || `Shelf ${id}`}`;
  shelfNameRow.getCell(1).alignment = { horizontal: 'center', vertical: 'middle' };
  shelfNameRow.getCell(1).font = { bold: true, size: 18 };
  shelfNameRow.height = 16;

  sheet && safelyMergeCells(sheet, 1, 1, 1, sheet.actualColumnCount);
}

// Add Validity in the second row
const validityRow = sheet?.getRow(2);
if (validityRow) {
  validityRow.getCell(1).value = ` ${dateString}`;
  validityRow.getCell(1).alignment = { horizontal: 'center', vertical: 'middle' };
  validityRow.getCell(1).font = { bold: true, size: 10 };
  validityRow.height = 16;
 
  sheet && safelyMergeCells(sheet, 2, 1, 2, sheet.actualColumnCount);
}

// // Add an empty highlighted row in the third row
// const highlightedRow = sheet?.getRow(3);
// if (highlightedRow) {
//   highlightedRow.getCell(1).value = ''; // Leave it empty
//   highlightedRow.getCell(1).fill = {
//     type: 'pattern',
//     pattern: 'solid',
//     fgColor: { argb: 'FFFFE0' }, // Light yellow background
//   };
//   highlightedRow.height = 15;
//   sheet &&  safelyMergeCells(sheet, 3, 1, 3, sheet.actualColumnCount);
// }
  
  let rowTotalNumber = 0
  sheet?.eachRow({ includeEmpty: true }, (row) => {
    row.height = 17; 
    rowTotalNumber++// Set a small height for the row
    row.eachCell((cell) => {
      cell.alignment = { wrapText: false }; // Prevent text wrapping

      // Add a black border to the cell
      cell.border = {
        top: { style: 'thin', color: { argb: 'FF000000' } },
        left: { style: 'thin', color: { argb: 'FF000000' } },
        bottom: { style: 'thin', color: { argb: 'FF000000' } },
        right: { style: 'thin', color: { argb: 'FF000000' } },
      };
    });
  });

  // Fetch the image as a base64 string
  const base64Image = imageUrl;

  // Log the base64 image data (for debugging)
  console.log('Base64 Image:', base64Image);

  // Ensure the base64 string is in the correct format
  if (!base64Image.startsWith('data:image')) {
    throw new Error('Invalid image data');
  }

  // Add the image to the workbook
  const imageId = workbook.addImage({
    base64: base64Image.split(',')[1], // Strip off the "data:image/png;base64," part
    extension: 'png',
  });
  console.log('add image done')
  let totalWidth = 0;
  sheet?.columns.forEach(column => {
    totalWidth += column.width ? column.width * 7.5 : 8.43 * 13.2; // Approximate width in pixels, assuming default font size
  });
  console.log('add image done 2')

  // Calculate the total height of the content
  let totalHeight = 0;

  sheet?.eachRow({ includeEmpty: true }, (excelRow, rowNumber) => {
    let maxLines = 1; // At least one line per cell/row
    excelRow.eachCell((cell) => {
      if (cell.value && typeof cell.value === 'string') {
        // Count how many lines are in this cell
        const cellLines = cell.value.split('\n').length;
        if (cellLines > maxLines) {
          maxLines = cellLines;
        }
      }
      // Enable text wrapping for this cell
      cell.alignment = { 
        horizontal: 'center', 
        vertical: 'middle', 
        wrapText: true 
      };
    });
  
    // Adjust the row height based on the maximum number of lines found
    // Approximately 15 points per line, adjust as needed for your font/sizing
    excelRow.height = maxLines * 45;
  
    totalHeight += excelRow.height;
  });
  sheet?.columns.forEach((col:any) => {
    let hasText = false;
    col?.eachCell((cell:any) => {
      if (cell.value && typeof cell.value === 'string' && cell.value.trim() !== '') {
        hasText = true;
      }
    });
    if (hasText) {
      col.width = 16; // set a certain width if this column contains text
    }
  });
  console.log('add image done 3')
  if(shelfNameRow) {
    shelfNameRow.height = 30;
    const firstCell = shelfNameRow.getCell(1); // Access the first cell explicitly
    firstCell.border = {
      top: { style: 'thin', color: { argb: 'FF000000' } },
      left: { style: 'thin', color: { argb: 'FF000000' } },
      bottom: { style: 'thin', color: { argb: 'FF000000' } },
      right: { style: 'thin', color: { argb: 'FF000000' } },
    };
  }
  if(validityRow) {
    validityRow.height = 20;

    const firstCell = validityRow.getCell(1); // Access the first cell explicitly
    firstCell.border = {
      top: { style: 'thin', color: { argb: 'FF000000' } },
      left: { style: 'thin', color: { argb: 'FF000000' } },
      bottom: { style: 'thin', color: { argb: 'FF000000' } },
      right: { style: 'thin', color: { argb: 'FF000000' } },
    };
  }
  const emptyRow = sheet?.getRow(3);
  if(emptyRow) {
    emptyRow.height = 20;

  }

  // Insert the image at row 20, column 1
  sheet?.addImage(imageId, {
    tl: { col: 0, row: row +6 }, // Top-left position (row 20, column 1, zero-indexed)
    ext: { width: totalWidth, height: 14*8 *rowTotalNumber }, // Adjust the size of the image as needed
  }); 
  console.log('add image done4 ')


  // Export the updated workbook as a Blob
  const updatedBuffer = await workbook.xlsx.writeBuffer();
  const updatedBlob = new Blob([updatedBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

  return updatedBlob
};



export const mergeExcelBlobs = async (blobs: Blob[],planograms:any) => {
  const combinedWorkbook = new ExcelJS.Workbook();

  for (let index = 0; index < blobs.length; index++) {
    const blob = blobs[index];

    // Load the blob into a temporary workbook
    const buffer = await blob.arrayBuffer();
    const tempWorkbook = new ExcelJS.Workbook();
    await tempWorkbook.xlsx.load(buffer);
    const tempSheet = tempWorkbook.getWorksheet(1);

    if (!tempSheet) {
      console.warn(`Sheet not found in blob ${index + 1}.`);
      continue;
    }

    // Create a new sheet in the combined workbook for each blob
    const sheetName = `${planograms?.[index]?.name}`;
    const newSheet = combinedWorkbook.addWorksheet(sheetName);

    // Copy rows, values, and styles
    tempSheet.eachRow({ includeEmpty: true }, (row, rowIndex) => {
      const newRow = newSheet.getRow(rowIndex);
      newRow.height = row.height || 15; // Set row height
      row.eachCell((cell, colNumber) => {
        const newCell = newRow.getCell(colNumber);
        newCell.value = cell.value;
        newCell.style = cell.style;
        newCell.border = cell.border;
        newCell.alignment = cell.alignment;
        newCell.fill = cell.fill; // Retain cell fills
      });
    });

    // Handle merged cells
    if (tempSheet.model?.merges) {
      for (const mergeRange of tempSheet.model.merges) {
        newSheet.mergeCells(mergeRange);
      }
    }

    // Adjust column widths
    tempSheet.columns.forEach((col, index) => {
      if (col.width) {
        newSheet.getColumn(index + 1).width = col.width;
      }
    });

    // Extract and copy images
    const images = tempSheet.getImages();
    for (const image of images) {
      const imageId = image.imageId;
      console.log('imageInfo',image)
      const img = tempWorkbook.model.media.find((m, mediaIndex) => mediaIndex.toString() === imageId.toString());

      if (img) {
        const newImageId = combinedWorkbook.addImage({
          buffer: img.buffer,
          extension: img.extension as any,
        });

        newSheet.addImage(newImageId, {
          tl: { col: image.range.tl.col, row: image.range.tl.row },
          ext: {
            width: ((image as any).range.ext.width) , // Approx. column width in points
            height: ( (image as any).range.ext.height) , // Approx. row height in points
          },
        });
      } else {
        console.warn(`Image with ID ${imageId} not found in media collection for blob ${index + 1}.`);
      }
    }
  }

  // Export the combined workbook as a new blob
  const buffer = await combinedWorkbook.xlsx.writeBuffer();
  return new Blob([buffer], {
    type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  });
};