import axios from "../auth/axiosInstance";

const API_URL = process.env.REACT_APP_API_URL;

if (!API_URL) {
  throw new Error("API URL not defined in environment variables");
}

export const createPosUnivers = async (posUniversData: {
  name: string;
  type: "individual" | "key account";
  posId: number;
  region?: string;
  address?: string;
  iqosPartner?: boolean;
  visibility?: boolean;
  keyAccountId?: number | null;
  channelId?: number;
  size?: string;
}) => {
  try {
    const response = await axios.post(
      `${API_URL}/pos/posunivers`,
      posUniversData
    );
    return response.data;
  } catch (error: any) {
    throw error.response.data;
  }
};

export const getAllPosUniverses = async () => {
  try {
    const response = await axios.get(`${API_URL}/pos/posunivers`);
    return response.data;
  } catch (error: any) {
    throw error.response.data;
  }
};
export const getAllChannels = async () => {
  try {
    const response = await axios.get(`${API_URL}/pos/channels`);
    return response.data;
  } catch (error: any) {
    throw error.response.data;
  }
};

export const updatePosUnivers = async (
  id: number,
  posUniversData: {
    name: string;
    type: "individual" | "key account";
    posId: number;
    region?: string;
    address?: string;
    iqosPartner?: boolean;
    visibility?: boolean;
    keyAccountId?: number | null;
    size?: string;
    channelId?: number;
  }
) => {
  try {
    const response = await axios.put(
      `${API_URL}/pos/posunivers/${id}`,
      posUniversData
    );
    return response.data;
  } catch (error: any) {
    throw error.response.data;
  }
};

export const createKeyAccount = async (name: string) => {
  try {
    const response = await axios.post(`${API_URL}/pos/key_account`, { name });
    return response.data;
  } catch (error: any) {
    throw error.response.data;
  }
};

export const getAllKeyAccounts = async () => {
  try {
    const response = await axios.get(`${API_URL}/pos/key_account`);
    return response.data;
  } catch (error: any) {
    throw error.response.data;
  }
};
export const getPosUniversesWithNotNullKeyAccount = async () => {
  try {
    const response = await axios.get(`${API_URL}/pos/notNullKeyAccount`);
    return response.data;
  } catch (error: any) {
    throw error.response.data;
  }
};

export const getPosUniversById = async (id: number) => {
  try {
    const response = await axios.get(`${API_URL}/pos/${id}`);
    return response.data;
  } catch (error: any) {
    throw error.response.data;
  }
};

export const searchPosUnivers = async (searchTerms: string) => {
  try {
    const response = await axios.get(`${API_URL}/pos/search`, {
      params: {
        search: searchTerms,
      },
    });
    return response.data;
  } catch (error: any) {
    throw error.response.data;
  }
};

export const searchKeyAccountByName = async (name: string) => {
  try {
    const response = await axios.get(`${API_URL}/pos/key-account/search`, {
      params: { search: name },
    });
    return response.data;
  } catch (error: any) {
    throw error.response.data;
  }
};

export const getPosUniversesByKeyAccountId = async (
  keyAccountId: number,
  page: number = 1,
  limit: number = 10
) => {
  try {
    const response = await axios.get(`${API_URL}/pos/key_account/${keyAccountId}`, {
      params: {
        page,
        limit,
      },
    });
    return response.data;
  } catch (error: any) {
    throw error.response?.data || error.message;
  }
};


// Function to get shelf configuration by POS ID
export const getShelfConfigByPosId = async (posId: number) => {
  try {
    const response = await axios.get(
      `${API_URL}/posunivers/${posId}/shelfConfiguration`
    );
    return response.data;
  } catch (error: any) {
    throw error.response.data;
  }
};

// Function to assign a shelf configuration to a POS
export const assignShelfConfigToPos = async (
  posId: number,
  shelfConfigId: number
) => {
  try {
    const response = await axios.post(
      `${API_URL}/pos/posunivers/assignShelfConfiguration`,
      {
        posId,
        shelfConfigId,
      }
    );
    return response.data;
  } catch (error: any) {
    throw error.response.data;
  }
};

export const getShelfConfigsByKeyAccountId = async (keyAccountId: number) => {
  try {
    const response = await axios.get(
      `${API_URL}/pos/key_account/${keyAccountId}/shelfConfigurations`
    );
    return response.data;
  } catch (error: any) {
    throw error.response.data;
  }
};

export const getKeyAccountById = async (id: number) => {
  try {
    const response = await axios.get(`${API_URL}/pos/key_account_info/${id}`);
    return response.data;
  } catch (error: any) {
    throw error.response.data;
  }
};

export const searchPosUniversByKeyAccount = async (
  keyAccountId: number,
  searchTerms: string
) => {
  try {
    const response = await axios.get(
      `${API_URL}/pos/posunivers/searchByKeyAccount/${keyAccountId}`,
      {
        params: { search: searchTerms },
      }
    );
    return response.data;
  } catch (error: any) {
    throw error.response.data;
  }
};

export const getPosUniversesWithNullKeyAccount = async () => {
  try {
    const response = await axios.get(`${API_URL}/pos/nullKeyAccount`);
    return response.data;
  } catch (error: any) {
    throw error.response.data;
  }
};

export const getPosUniversesByShelfConfigId = async (shelfConfigId: number) => {
  try {
    const response = await axios.get(
      `${API_URL}/pos/posunivers/shelfConfig/${shelfConfigId}`
    );
    return response.data;
  } catch (error: any) {
    throw error.response.data;
  }
};

export const getShelfConfigsWithStoreCount = async () => {
  try {
    const response = await axios.get(
      `${API_URL}/pos/posunivers/shelfConfigsWithStoreCount`
    );
    return response.data;
  } catch (error: any) {
    throw error.response.data;
  }
};

export const getGroupedChains = async () => {
  try {
    const response = await axios.get(`${API_URL}/pos/posunivers/grouped-chains`);
    return response.data;
  } catch (error: any) {
    throw error.response?.data || error.message;
  }
};

export const getFilteredItemsChaine = async (activeChannelId: number) => {
  try {
    const response = await axios.get(`${API_URL}/pos/filtered-items/${activeChannelId}`);
    return response.data;
  } catch (error: any) {
    throw error.response?.data || error.message;
  }
};

export const getAccountStoreEntry = async (keyAccountId: number) => {
  try {
    const response = await axios.get(`${API_URL}/pos/account-store/${keyAccountId}`);
    return response.data;
  } catch (error: any) {
    throw error.response?.data || error.message;
  }
};