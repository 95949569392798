import axios from "../auth/axiosInstance";

const API_URL = process.env.REACT_APP_API_URL;

if (!API_URL) {
  throw new Error('API URL not defined in environment variables');
}

export interface Configuration {
  cc: number;
  hnb: number;
  cc_iqos: number;
  hnb_iqos: number;
  Marlboro: number;
  Chesterfield: number;
}

export const getConfiguration = async (): Promise<Configuration> => {
  try {
    const response = await axios.get(`${API_URL}/configuration`);
    return response.data;
  } catch (error: any) {
    throw error.response.data;
  }
};

export const updateConfiguration = async (config: Configuration) => {
  try {
    const response = await axios.put(`${API_URL}/configuration`, config);
    return response.data;
  } catch (error: any) {
    throw error.response.data;
  }
};


// New services for brands
export const getOrderedBrands = async (): Promise<number[]> => {
  try {
    const response = await axios.get(`${API_URL}/configuration/brands`);
    return response.data;
  } catch (error: any) {
    throw error.response.data;
  }
};

export const reorderBrands = async (brandIds: number[]): Promise<void> => {
  try {
    await axios.post(`${API_URL}/configuration/brands/reorder`, { brandIds });
  } catch (error: any) {
    throw error.response.data;
  }
};