import React from "react";
import { Box, Button, IconButton, CircularProgress } from "@mui/material";
import Logo from "../../../assets/images/Logo1.png";
import { ReactComponent as Back } from "../../../assets/icons/Back.svg";
import { useStyles } from "./SideBarStyles";
import Input from "../Input/Input";
import { ReactComponent as Height } from "../../../assets/icons/Height2.svg";
import Typography from "@mui/material/Typography";
import { useNavigate } from "react-router-dom";

interface SideBarProps {
  id?: string;
  height: number;
  onHeightChange: (value: number) => void;
  onSave: () => void;
  onBack: () => void;
  loadingNext: boolean;
  loadingBack: boolean;
}

const Sidebar: React.FC<SideBarProps> = ({
  id, height, onHeightChange, onSave, onBack, loadingNext, loadingBack
}) => {
  const classes = useStyles();
  const navigate = useNavigate()
  return (
    <Box className={classes.container}>
      <img src={Logo} alt="Logo" onClick={()=>navigate('/')} className={classes.logo} />
      <div className={classes.buttonContainer}>
        <div style={{ display: "flex", flexDirection: "column" }}>
          <Typography variant="body2" sx={{ marginBottom: "12px" }}>
            STEP 3/10
          </Typography>
          <Typography variant="h4" sx={{ marginBottom: "12px" }}>
            Set Drawer Dimension
          </Typography>
          <Typography variant="h6" sx={{ marginBottom: "50px" }}>
            Edit your drawer dimensions
          </Typography>
          <Input
            onChange={(value) => onHeightChange(value)}
            value={height}
            icon={<Height />}
            text={"Height"}
            min={10}
            max={60}
          />
        </div>
        <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
          <IconButton onClick={onBack} disabled={loadingBack}>
            <Box component="span" className={classes.iconButton}>
              {loadingBack ? <CircularProgress size={24} /> : <Back />}
            </Box>
          </IconButton>
          <Button
            variant="contained"
            color="info"
            onClick={onSave}
            sx={{ marginTop: "0px", position: "relative" }}
            disabled={loadingNext}
          >
            {loadingNext ? <CircularProgress size={24} sx={{ position: "absolute" }} /> : "Next"}
          </Button>
        </div>
      </div>
    </Box>
  );
};

export default Sidebar;
