import React from "react";
import { Box, Button, IconButton, CircularProgress } from "@mui/material";
import { Add as AddIcon } from "@mui/icons-material";
import Logo from "../../../../assets/images/Logo1.png";
import { ReactComponent as Back } from "../../../../assets/icons/Back.svg";
import { useStyles } from "./SideBarStyles";
import Input from "../Input/Input";
import { useNavigate, useParams } from 'react-router-dom';
import Typography from "@mui/material/Typography";
import { ReactComponent as Row } from "../../../../assets/icons/Col.svg";

interface SidebarProps {
  onColChange: (value: number) => void;
  onRowChange: (value: number) => void;
  onRowDistanceChange: (value: number) => void;
  onColDistanceChange: (value: number) => void;
  colDistance: number;
  rowDistance: number;
  row: number;
  col: number;
  onNext: () => void;
  loading: boolean;
}

const Sidebar: React.FC<SidebarProps> = ({
  onColChange,
  onRowChange,
  row,
  col,
  onRowDistanceChange,
  rowDistance,
  colDistance,
  onColDistanceChange,
  onNext,
  loading
}) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const { id, index } = useParams<{ id: string,index?:string }>();

  return (
    <Box className={classes.container}>
      <img src={Logo} alt="Logo"  onClick={()=>navigate('/')} className={classes.logo} />
      <div className={classes.buttonContainer}>
        <div style={{ display: "flex", flexDirection: "column" }}>
          <Typography variant="body2" sx={{ marginBottom: "12px" }}>
            STEP 2/7
          </Typography>
          <Typography variant="h4" sx={{ marginBottom: "12px" }}>
            New RRP Configuration
          </Typography>
          <Typography variant="h6" sx={{ marginBottom: "50px", maxWidth: '200px' }}>
            Set the width of the rows for the RRP area
          </Typography>
          <Input
            onChange={onRowChange}
            value={row}
            icon={<Row />}
            text={"Rows"}
            max={12}
            min={1}
          />
        </div>
        <div style={{ display: "flex", alignItems: "center" }}>
          <IconButton onClick={() => { 
            
            
            if(index ) {
              navigate('/rrp/step1/' + (id || '')+'?index='+(index || ''))

            }else {
              navigate('/rrp/step1/' + (id || ''))

            }



           }} size="large">
            <Box component="span" className={classes.iconButton}>
              <Back />
            </Box>
          </IconButton>
          <Button variant="contained" color="info" onClick={onNext} sx={{ marginTop: "0px" }}>
            {loading ? <CircularProgress size={24} /> : "Next"}
          </Button>
        </div>
      </div>
    </Box>
  );
};

export default Sidebar;
