import React, { useEffect, useState } from "react";
import { Button, Box } from "@mui/material";
import { styled } from "@mui/system";

// Define a type for the options
type OptionType = {
  id: number;
  label: string;
  color: string;
};

// Styled Button with a dynamic border color
const ColoredButton = styled(Button)<{ selected: boolean; color: string }>(
  ({ selected, color }) => ({
    borderColor: selected ? color : "transparent",
    borderWidth: selected ? 2 : 1,
    borderStyle: "solid",
    borderRadius: "8px",
    fontSize:"14px !important",
    fontWeight: selected ? "700" : "300",
    color:"#383A49",
    textTransform:"capitalize",
    width:"300px",
    height:"40px",
    border: selected ? "1px solid #0179FF" : 0,
    backgroundColor: selected ? "white":  "#F5F9FF",
    justifyContent:"flex-start",
    padding:"8px",
    margin: 1,
    "&:hover": {
      opacity:"0.9",
      background: "transparent" // Adds a light background color on hover
    },
  })
);

interface SelectComponentProps {
  onChange: (value:number)=>void;
  question2:any[]

}

const SelectComponent: React.FC<SelectComponentProps> = ({onChange,question2}) => {
  const [selectedId, setSelectedId] = useState<number | null>( window.location.href.includes('duplex') ? 5:4);

 


  const  [options,setOptions]= useState< OptionType[]> ([
    {
      id: 4,
      label: "Marketing Materials",
      color: "#ffeb3b",
    },
    { id:3, label: "LED Screens", color: "#F95252" },
    { id:5, label: "Duplex Pushers", color: "#9747FF" },

  ])

  useEffect(()=>{
    if( window.location.href.includes('duplex')) {
      setOptions([
      
        { id:5, label: "Duplex Pushers", color: "#9747FF" },

      ])
      return 
    }
    if(question2.includes("Marketing Materials") && question2.includes("LED Screens") ){
      setOptions([
        {
          id: 4,
          label: "Marketing Materials",
          color: "#ffeb3b",
        },
        { id:3, label: "LED Screens", color: "#F95252" },

      ])
    }else if(question2.includes("Marketing Materials") ) {
      setOptions([
        {
          id: 4,
          label: "Marketing Materials",
          color: "#ffeb3b",
        },

      ])
    }else if(question2.includes("LED Screens")) {
      setOptions([
     
        { id:3, label: "LED Screens", color: "#F95252" },

      ])
    }else {
      setOptions([
      
      ])
    }
  },[question2])

  const handleSelect = (id: number) => {
    setSelectedId(id);
    onChange(id)
    // Here you could also do something with the selected value
    // For example, calling a parent component's method via props
  };

  return (
    <div style={{ display: "flex", flexDirection: "column", gap:"8px" }}>
      {options.map((option) => (
        <ColoredButton
          key={option.id}
          selected={selectedId === option.id}
          color={"secondary"}
          onClick={() => handleSelect(option.id)}
          variant="outlined"
        >
            <div style={{ display: "flex", gap:"8px" }}>
            <div
            style={{
              width: 22,
              height: 22,
              backgroundColor: option.color ,
              borderRadius: "50%",
            }}
          ></div>

          {option.label}
            </div>
         
        </ColoredButton>
      ))}
    </div>
  );
};

export default SelectComponent;
