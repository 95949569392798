import React, { useState } from "react";
import { Button, Card, CardContent, IconButton, Menu, MenuItem, Tooltip } from "@mui/material";
import Typography from "@mui/material/Typography";
import ShelfCard from "../../../assets/images/HNBShelf.png";
import { useStyles } from "./ExcistShelfCardStyles";
import { useNavigate } from 'react-router-dom';
import { deleteRRPNewConfig } from "../../../services/rrpNewConfigurationService";
import BrandModal from "../../SKUPriority copy/ModalComponent/Modal";

interface ExcistShelfCardProps {
  name: string;
  col: number;
  row: number;
  createdAt: string;
  id: number;
  removeConfiguration: (id: number) => void;
  setError: (message: string | null) => void;
  groupedChains: any[];
  indId: number | undefined
}

const ExcistShelfCard: React.FC<ExcistShelfCardProps> = ({ name, col, row, createdAt, id, removeConfiguration, setError ,groupedChains,indId}) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const [openModal, setOpenModal] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleCloseModal = () => {
    setOpenModal(false);
  };
  const handleDelete = async () => {
    try {
     const result = await deleteRRPNewConfig(id.toString());
      if(result.data.success) {
        removeConfiguration(id);
      }else {
        setError(`This RRP Configuration is assigned to ${result.data.configurations.length} shelf configuration(s)`)
      }
      // Remove the item from the parent list on successful delete

    } catch (error) {
      setError("Failed to delete configuration. Please try again.");
      console.error('Error deleting configuration:', error);
    }
  };
  const handleEdit = () => {
    // Handle edit action
   // navigate('/step9/'+config.id)
    handleClose();
  };
  return (
    <>
          <BrandModal open={openModal} handleClose={handleCloseModal} groupedChains={groupedChains}  indId={indId} label="Shelf"  id={id}/>
          <Card className={classes.card}>
      <CardContent style={{display:'flex',justifyContent:'center',alignItems:'center',flexDirection:'column'}}>
      <div className={classes.header}>
        <IconButton
            aria-label="settings"
            className={classes.settingsButton}
            onClick={handleClick}
          >
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M2 12.88V11.12C2 10.08 2.85 9.22 3.9 9.22C5.71 9.22 6.45 7.94 5.54 6.37C5.02 5.47 5.33 4.3 6.24 3.78L7.97 2.79C8.76 2.32 9.78 2.6 10.25 3.39L10.36 3.58C11.26 5.15 12.74 5.15 13.65 3.58L13.76 3.39C14.23 2.6 15.25 2.32 16.04 2.79L17.77 3.78C18.68 4.3 18.99 5.47 18.47 6.37C17.56 7.94 18.3 9.22 20.11 9.22C21.15 9.22 22.01 10.07 22.01 11.12V12.88C22.01 13.92 21.16 14.78 20.11 14.78C18.3 14.78 17.56 16.06 18.47 17.63C18.99 18.54 18.68 19.7 17.77 20.22L16.04 21.21C15.25 21.68 14.23 21.4 13.76 20.61L13.65 20.42C12.75 18.85 11.27 18.85 10.36 20.42L10.25 20.61C9.78 21.4 8.76 21.68 7.97 21.21L6.24 20.22C5.33 19.7 5.02 18.53 5.54 17.63C6.45 16.06 5.71 14.78 3.9 14.78C2.85 14.78 2 13.92 2 12.88Z" fill="#9CA0AB"/>
              <path d="M12 15.25C13.7949 15.25 15.25 13.7949 15.25 12C15.25 10.2051 13.7949 8.75 12 8.75C10.2051 8.75 8.75 10.2051 8.75 12C8.75 13.7949 10.2051 15.25 12 15.25Z" fill="white"/>
            </svg>
          </IconButton>
          <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            <MenuItem onClick={handleOpenModal} sx={{fontSize:'14px'}}>Edit Key account</MenuItem>

    
          </Menu>
          <img src={ShelfCard} alt="Shelf" className={classes.image} />

        </div>
     

        <div className={classes.textContainer} style={{justifySelf:'start'}}>
          <div style={{ display: "flex", gap: "7px", marginBottom: "8px" }}>
            <div style={{ fontSize: "12px", fontWeight: "800", color: "#0179FF", background: "rgba(217, 231, 248, 0.50)", padding: "5px", borderRadius: "6px" }}>
              {col}x{row}
            </div>
            <div style={{ fontSize: "12px", fontWeight: "800", color: "#383A49", background: "rgba(217, 231, 248, 0.50)", padding: "5px", borderRadius: "6px" }}>
              {new Date(createdAt).getFullYear()}
            </div>
          </div>

          <Typography variant="body1" style={{ textAlign: "left" }} sx={{ fontSize: "16px", maxWidth: "250px", marginBottom: "20px" }}>
           
          </Typography>
          <Tooltip title= {name} arrow>
  <Typography
    variant="body1"
    style={{
      textAlign: "left",
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
      maxWidth: "200px", // Set max width to limit text to 300px
    }}
  >
    {name}
  </Typography>
</Tooltip>
          
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
              borderBottom: "1px solid #E1E3E6",
              paddingBottom: "12px",
              marginBottom: "16px"
            }}
          >
            <Typography variant="body2" className={classes.lastEdit}>
              Active date:
            </Typography>
            <Typography variant="body2" className={classes.lastEdit}>
              {new Date(createdAt).toLocaleDateString()}
            </Typography>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              width: "100%",
            }}
          >
            <Typography variant="body2" className={classes.lastEdit} style={{ textDecoration: "underline", cursor: "pointer" }} onClick={() => navigate(`/rrp/step1/${id}`)}>
              Edit RRP
            </Typography>
            {/* <Typography variant="body2" className={classes.lastEdit} style={{ textDecoration: "underline", cursor: "pointer" }} onClick={handleOpenModal}>
              Edit Key Account
            </Typography> */}
            { (
  <Button
    onClick={handleDelete}
    style={{ fontSize: "14px", fontWeight: 400, color: "#9CA0AB", background: "rgba(156, 160, 171, 0.10)", borderRadius: "6px", textTransform: "capitalize", height: "28px" }}
  >
    Delete
  </Button>
)}
          </div>
        </div>
      </CardContent>
    </Card>
    </>
   
  );
};

export default ExcistShelfCard;
