import React, { useEffect, useState } from 'react';
import { Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import Paper from '@mui/material/Paper';
import Typography from "@mui/material/Typography";
import MeasurementInput from '../InputV2/InputV2';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    flexContainer: {
      display: "flex",
      margin: `2px`,
    },
    square: {
      height: 40,
      backgroundColor: "#B5D8FF !important",
      boxShadow: "none",
    },
    gridContainer: {
      margin: "-5px",
      width: "auto",
      "& > .MuiGrid-item": {
        padding: "5px",
      },
    },
    controlBar: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      marginBottom: theme.spacing(2),
      backgroundColor: "#D9E7F8",
      width: "100%",
      borderRadius: "3px",
    },
    verticalControlBar: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      padding: "0 34px",
      backgroundColor: "#D9E7F8",
      borderRadius: "3px",
      alignItems: "center",
      marginLeft: theme.spacing(2),
      height: "100%",
    },
    iconButton: {
      width: 28,
      height: 28,
      borderRadius: "4px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      backgroundColor: "white",
      color: "#0179FF",
    },
    iconButton2: {
      padding: 6,
    },
    smallDiv: {
      width: 28,
      height: 38,
      backgroundColor: "#ffffff",
      margin: "1.25px",
      borderRadius: '4px'
    },
  })
);

interface GridDemoProps {
  row: number;
  col: number;
  rowDistance: number;
  setRowDistance: React.Dispatch<React.SetStateAction<number>>;
  colDistance: number;
  setColDistance: React.Dispatch<React.SetStateAction<number>>;
  setRow: React.Dispatch<React.SetStateAction<number>>;
  setCol: React.Dispatch<React.SetStateAction<number>>;
  columnWidths: number[];
  setColumnWidths: React.Dispatch<React.SetStateAction<number[]>>;
  ccSpace: number;
  setCcSpace: React.Dispatch<React.SetStateAction<number>>;
}

const GridDemo: React.FC<GridDemoProps> = ({
  row,
  col,
  rowDistance,
  colDistance,
  columnWidths,
  setColumnWidths,
  ccSpace,
  setCcSpace
}) => {
  const classes = useStyles();
  const [globalWidthEnabled, setGlobalWidthEnabled] = useState(true);

  useEffect(() => {
    if (col > columnWidths.length) {
      const additionalWidths = Array(col - columnWidths.length).fill(50);
      setColumnWidths(columnWidths.concat(additionalWidths));
    } else if (col < columnWidths.length) {
      setColumnWidths(columnWidths.slice(0, col));
    }
  }, [col]);

  const handleValueChange = (value: number, colIndex: number) => {
    if (colIndex === -1) {
      setColumnWidths(Array(col).fill(value));
    } else {
      const newColumnWidths = [...columnWidths];
      newColumnWidths[colIndex] = value;
      setColumnWidths(newColumnWidths);
      setGlobalWidthEnabled(false);
    }
    const newCcSpace = Math.floor((value * 5) / 30.5); // Convert cm to small divs
    setCcSpace(newCcSpace);
  };

  const handleCcSpaceChange = (value: number) => {
    setCcSpace(value);
    const newWidth = value * 30.5 / 5; // Convert small divs to cm
    setColumnWidths(columnWidths.map(() => newWidth)); // Update column widths in cm
  };

  const renderInputRow = () => {
    const inputRow = [];
    for (let i = 0; i < col; i++) {
      inputRow.push(
        <div key={`div-${i}`} style={{ padding: `${rowDistance * 5 / 2}px`, paddingBottom: "20px", borderBottom: "2px dashed #9CA0AB", paddingTop: '10px' }}>
          <MeasurementInput
            key={`input-${i}`}
            value={columnWidths[i]}
            unit="cm"
            onValueChange={(value) => handleValueChange(value, i)}
            onUnitChange={() => { }}
            width={columnWidths[i]}
          />
        </div>
      );
    }
    return (
      <div style={{ flexDirection: "column", display: "flex", justifyContent: "center", alignItems: "center", gap: "4px", textAlign: "center" }}>
        <div className={classes.flexContainer}>
        </div>
        <Typography variant="h6" sx={{ marginBottom: "4px", marginTop: "12px" }}>
          Width per drawer
        </Typography>
        <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center' }}>
          <div style={{ display: 'flex', }}>
            <MeasurementInput
              key="inputCcSpace"
              value={parseInt(ccSpace.toString())}
              unit="CC Space"
              onValueChange={(value) => handleCcSpaceChange(value)}
              onUnitChange={() => { }}
              width={156}
            />
            <MeasurementInput
              key={`input-${0}`}
              value={columnWidths[0]}
              unit="cm"
              onValueChange={(value) => handleValueChange(value, 0)}
              onUnitChange={() => { }}
              width={120}
            />
          </div>
        </div>
      </div>
    );
  };

  const renderSmallDivs = (numDivs: number) => {
    const smallDivs = [];
    for (let i = 0; i < numDivs; i++) {
      smallDivs.push(<div key={i} className={classes.smallDiv} />);
    }
    return smallDivs;
  };

  const renderSquares = () => {
    const rowsArray = [];
    for (let i = 0; i < row; i++) {
      const rowSquares = [];
      for (let j = 0; j < col; j++) {
        const squareWidthCm = columnWidths[j]; // Width in cm
        const squareWidthPx = squareWidthCm * 5; // Convert cm to px (1 cm = 10px for display)
        const numDivs = ccSpace;
        rowSquares.push(
          <div
            key={`${i}-${j}`}
            style={{
              padding: `${colDistance * 5 / 2}px ${rowDistance * 5 / 2}px`,
              width: `${squareWidthPx}px`,
            }}
          >
            <Paper className={classes.square} style={{ width: `${squareWidthPx}px` }}>
              <div style={{ display: "flex", flexWrap: "wrap", alignItems: 'center' }}>
                {renderSmallDivs(numDivs)}
              </div>
            </Paper>
          </div>
        );
      }
      rowsArray.push(
        <div key={i} className={classes.flexContainer}>
          {rowSquares}
        </div>
      );
    }
    return rowsArray;
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        backgroundColor: "#F5F9FF",
      }}
    >
      <div
        style={{
          backgroundColor: "#F5F9FF",
          height: "100vh",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          overflow: "auto",
          width: "fit-content",
          padding: "0 10px",
        }}
      >
        <div style={{ display: "flex", flexDirection: "column", borderBottom: "2px dashed #9CA0AB", paddingBottom: 20 }}>
          {renderSquares()}
        </div>
        {renderInputRow()}
      </div>
    </div>
  );
};

export default GridDemo;
