import React from "react";
import { loginRequest } from "../../authConfig";
import { Box, Button, Grid, Typography } from "@mui/material";
import { useMsal } from "@azure/msal-react";
import Logo from "../../assets/images/Logo1.png";
import { useAuth } from "../../auth/authProvider";

const LoginPage: React.FC = () => {
  const { instance } = useMsal();
  const API_URL = process.env.REACT_APP_API_URL;

  const handleLogin = async () => {
    try {
      const response = await instance.loginPopup(loginRequest);
      const idToken = response.idToken;
      sessionStorage.setItem('authToken', response.idToken);  // Store the correct access token

      // Send token to the backend for verification
      const verifyResponse = await fetch(`${API_URL}/verify-token`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${idToken}`, // Send token to backend
        },
      });
  
      if (verifyResponse.ok) {
        const data = await verifyResponse.json();
        console.log("User authenticated", data);
        
        // Proceed to the next step
      } else {
        console.error("Authentication failed");
      }
    } catch (error) {
      console.log("Login failed", error);
    }
  };

  const containerStyle = {
    padding: "20px",
    margin: "auto",
    maxWidth: "500px",
    flexGrow: 1,
    justifyItems: "center",
    borderRadius: "12px",
    backgroundColor: "#ffffff",
    minHeight: "90vh", // Mimics Paper height
  };

  const logoStyle = {
    maxWidth: "100%",
    maxHeight: "60px",
    marginBottom: "16px",
    marginTop: "40px",
  };

  return (
    <>
      {/* Full-page container styled like Paper */}
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        minHeight="100vh"
        sx={{ backgroundColor: "#ffffff" }}
      >
        <Box sx={containerStyle}>
          {/* Logo at the top */}
          <Box display="flex" justifyContent="center">
            <img src={Logo} alt="Logo" style={logoStyle} />
          </Box>

          {/* Main content below the logo */}
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            minHeight="calc(100vh - 200px)" // Remaining space below the logo
            sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
          >
            {/* Centering the Grid content both vertically and horizontally */}
            <Grid container spacing={2} sx={{ textAlign: "center" }}>
              <Grid item xs={12}>
              <Typography variant="h4" sx={{marginBottom:'8px'}}>
             Sign in 
                </Typography>
                <Typography variant="h6">
                  Please sign in to your Account
                </Typography>
              </Grid>
              <Grid
                item
                xs={12}
                display="flex"
                justifyContent="center"
                alignItems="center"
              >
               
                <Button
                fullWidth
            color="primary"
            variant="contained"
            onClick={() => handleLogin()}
                        sx={{marginBottom:"10px !important","background-color":"#0179FF","color":"white",width:"470px",justifyContent:"center", 
            "&:hover": {
              color: "black", // Replace with your desired color
            }
           }}

         
          >
            Log in
          </Button>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default LoginPage;
