import React, { useState, useEffect } from 'react';
import Grid from "@mui/material/Grid";
import { useParams, useNavigate } from 'react-router-dom';
import Sidebar from '../components/Step17-new copy/sideBar/SideBar';
import DemoComponent from '../components/Step17-new copy/Content/Content';
import { getShelfConfigById, updateShelfConfig } from '../services/shelfConfigurationService';
import { getRRPConfigurationById } from '../services/rrpConfigurationService';
import { getRRPNewConfigById } from '../services/rrpNewConfigurationService';

type Square = {
  id?: number;
  width: number;
  height: number;
  x: number;
  y: number;
  title?: string;
  positions?: any;
};

const Step7: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const [squaresYellow, setSquaresYellow] = useState<Square[]>([]);
  const [initialSelectedRRPs, setInitialSelectedRRPs] = useState<number[]>([]);
  const [isInitialFetchDone, setIsInitialFetchDone] = useState(false);

  const [col, setCol] = useState(2);
  const [row, setRow] = useState(2);
  const [rowDistance, setRowDistance] = useState(2);
  const [colDistance, setColDistance] = useState(2);
  const [widths, setWidths] = useState<number[]>([]);
  const [pmiPositions, setPmiPositions] = useState<any[][][]>([]);
  const [question2, setQuestion2] = useState<any[]>([]);
  const [hiddencells,setHiddencells] = useState([])

  useEffect(() => {
    const fetchShelfConfig = async () => {
      try {
        const config = id ? await getShelfConfigById(id) : { rrp: [] };
        const rrpConfigurations = await Promise.all(
          (config.rrp || []).map(async (rrp: { id: number, x: number, y: number }) => {
            const fullRRP = await getRRPNewConfigById(rrp.id.toString());
            return {
              ...fullRRP,
              x: rrp.x,
              y: rrp.y,
            };
          })
        );
        const newSquares = rrpConfigurations.map((value) => ({
          id: value.id,
          title: value?.name,
          width: value?.widths[0] *5 || 250,
          height:  (value.row_position /2) * 51.5+ (value.row_position /2 - 1) * (colDistance *5),
          x: value.x || 0,
          y: value.y || 0,
        }));
        console.log('rrpConfigurations',newSquares)

        setSquaresYellow(newSquares);
        setInitialSelectedRRPs(config.rrp?.map((rrp: { id: number }) => rrp.id) || []);
        setCol(config.col_position);
        setRow(config.row_position);
        setRowDistance(config.rowDistance); 
        setColDistance(config.colDistance);
        setWidths(config.widths.map((width: any) => width * 5));
        setPmiPositions(config.pmiPositions || []);
        setQuestion2(config.question2)
        setHiddencells(config.hiddencells)

        setIsInitialFetchDone(true);
      } catch (error) {
        console.error("Error fetching shelf configuration:", error);
      }
    };

    fetchShelfConfig();
  }, [id]);

  const handleValueSelected = (values: any[]) => {
    console.log('the selected Value',values)
    const newSquares = values.map((value) => {
      const existingSquare = squaresYellow.find(square => square.id === value.id);
      return {
        id: value.id,
        title: value?.name,
        width: value.widths[0] *5 || 250,
        height: (value.row_position /2) * 51.5+ (value.row_position /2 - 1) * (colDistance *5),
        x: existingSquare ? existingSquare.x : 0,
        y: existingSquare ? existingSquare.y : 0,
      };
    });
    setSquaresYellow(newSquares);
  };

  const handleSave = async () => {
    try {
      const rrps = squaresYellow.map(square => ({
        id: square?.id,
        x: square.x || 0,
        y: square.y || 0,
      }));
      await updateShelfConfig(id!, { rrp: rrps });
      if(question2.includes('Marketing Materials') ||  question2.includes('LED Screens')) {
        question2.length > 0 ?   navigate("/step8/"+id):  navigate("/step9/"+id);

      }else if(question2.includes('Duplex pushers')) {
        question2.length > 0 ?   navigate("/step8-duplex/"+id):  navigate("/step9/"+id);

      }else {
        navigate("/step9/"+id);
      }
     
    } catch (error) {
      console.error("Error saving shelf configuration:", error);
    }
  };

  return (
    <div>
      <Grid container>
        <Grid item xs={3}>
          <Sidebar
            onValueSelected={handleValueSelected}
            initialSelectedRRPs={initialSelectedRRPs}
            onSave={handleSave}
          />
        </Grid>
        <Grid item xs={9}>
          <div style={{ width: "100%", backgroundColor: "#e0e0e0" }}>
            {isInitialFetchDone && (
              <DemoComponent
                squaresYellow={squaresYellow}
                yellowSquares={squaresYellow}
                setYellowSquares={setSquaresYellow}
                col={col}
                row={row}
                rowDistance={rowDistance }
                colDistance={colDistance}
                widths={widths}
                selectedBoxes={pmiPositions}
                hiddencells={hiddencells}

              />
            )}
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default Step7;
