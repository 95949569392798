import React, { useState } from 'react';
import { Card, CardContent, Typography, Button, Box, Snackbar, Alert } from '@mui/material';
import { deleteSku } from '../../../services/skuService';

type SKUCardProps = {
  imageUrl: string; // The URL of the image to display on the card
  title: string; // The title text to display on the card
  id: number; // Add SKU ID property
  onEditClick: (id: number) => void; // Pass SKU ID to edit click handler
};

const SKUCard: React.FC<SKUCardProps> = ({ imageUrl, title, id, onEditClick }) => {
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState<'success' | 'info' | 'error'>('success');

  const cardStyle: React.CSSProperties = {
    width: '100%',
    height: '206px',
    background: '#FFFFFF',
    borderRadius: '8px',
    boxShadow: '0 2px 4px 0 rgba(0,0,0,0.1)',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign:"left"
  };

  const imageStyle: React.CSSProperties = {
    width: '90%',
    height: '133px',
    borderRadius: '8px 8px 0px 0px',
    background: '#F5F9FF',
    justifyContent: 'center',
    display: 'flex',
    alignItems: "center"
  };

  const titleStyle: React.CSSProperties = {
    marginTop: '10px',
    fontWeight: 'bold'
  };

  const linkStyle: React.CSSProperties = {
    marginTop: '5px',
    fontSize: '12px',
    color: '#0179FF', // typical link color
    textDecoration: 'underline',
    cursor: 'pointer',
    fontWeight:"400",
    marginRight:'20px'
  };
  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };
  const onDelete = async () => {
    try {
      const {data} =await deleteSku(id);
      if(data.success) {
        setSnackbarMessage('SKU deleted successfully!');
        setSnackbarSeverity('success');
        setSnackbarOpen(true);
      }else {
         console.log('data',data)
        if(data.keyAccountNames.length  > 0  &&  data.shelfConfigurations.length > 0 ) {
          setSnackbarMessage(`The SKU you are trying to delete is still present in ${data.keyAccountNames.length} key account(s) and ${data.shelfConfigurations.length} planogram(s) due to a manual change.`);

        }else if (data.keyAccountNames.length  > 0) {
          setSnackbarMessage(`The SKU you are trying to delete is still present in ${data.keyAccountNames.length} key account(s) `);

        }else if (data.shelfConfigurations.length > 0) {
          setSnackbarMessage(`The SKU you are trying to delete is still present in  ${data.shelfConfigurations.length} planogram(s) due to a manual change.`);

        }else {
          setSnackbarMessage(`ok`);

        }
        setSnackbarSeverity('info');
        setSnackbarOpen(true);
      }
     
    } catch (error) {
      setSnackbarMessage('Failed to delete SKU. Please try again.');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    }
  };
  return (
    <div style={cardStyle}>
        <div style={imageStyle} >
        <img src={imageUrl} alt={title} style={{maxWidth:"90px",maxHeight:'120px'}} />

        </div>
        <div style={{alignSelf:"start",padding:"0 10px", width:'100%'}}>
        <Typography variant="subtitle2" color="text.primary" sx={{
          textTransform:'capitalize'
        }}>
            {title}
          </Typography>
          <div style={{display:'flex', justifyContent:'space-between'}}>
          <a style={linkStyle} onClick={() => onEditClick(id)}>
        Edit Product
      </a>
      <a style={linkStyle} onClick={() => onDelete()}>
        Delete
      </a>
          </div>
   
        </div>
        <Snackbar
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
      >
        <Alert onClose={handleCloseSnackbar} severity={snackbarSeverity} sx={{ width: '100%' }}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default SKUCard;
