import React, { useState } from "react";
import { Box, Button, IconButton, Snackbar } from "@mui/material";
import { Add as AddIcon } from "@mui/icons-material";
import Alert from '@mui/material/Alert';
import Logo from "../../../../assets/images/Logo1.png";
import { ReactComponent as Back } from "../../../../assets/icons/Back.svg";
import { useStyles } from "./SideBarStyles";
import { useNavigate, useParams } from 'react-router-dom';
import Typography from "@mui/material/Typography";

import SideBarRight from "../../../SKUList/AddSkuModal/SideBarRight";
import BrandModal from "../ModalComponent/Modal";
import FillOrder from "../FillOrder/FillOrder";
import { getFromJson } from '../../../helpers/localData';

interface SidebarProps {
  onColChange: (value: number) => void;
  onRowChange: (value: number) => void;
  onRowDistanceChange: (value: number) => void;
  onColDistanceChange: (value: number) => void;
  colDistance: number;
  rowDistance: number;
  row: number;
  col: number;
  selectedProductCardImage: string;
  setSelectedProductCardImage: React.Dispatch<React.SetStateAction<string>>;
  selectedSquare: { col: number; row: number } | null;
  items: { text: string; id: number; color: string; value: number; width: number; }[];
  setItems: React.Dispatch<React.SetStateAction<{ text: string; id: number; color: string; value: number; width: number; }[]>>;
  displayItems: { text: string; id: number; color: string; value: number; width: number; }[];
  setDisplayItems: React.Dispatch<React.SetStateAction<{ text: string; id: number; color: string; value: number; width: number; }[]>>;
  handleDuplexTypeChange: (typeIndex: number, value: number) => void;
  getDuplexTypeValue: (typeIndex: number) => number;
  selectedButton: string;
  onContinue: () => void;
  widths:number[];
  overLayResult:boolean[][][]  | undefined;
setOverLayResult: React.Dispatch<React.SetStateAction<boolean[][][] | undefined>> // New prop for continue action
}

const Sidebar: React.FC<SidebarProps> = ({
  onColChange,
  onRowChange,
  row,
  col,
  onRowDistanceChange,
  rowDistance,
  colDistance,
  onColDistanceChange,
  setSelectedProductCardImage,
  selectedProductCardImage,
  selectedSquare,
  items,
  setItems,
  displayItems,
  setDisplayItems,
  handleDuplexTypeChange,
  getDuplexTypeValue,
  selectedButton,
  onContinue,
  widths,
  overLayResult
   // Destructure the new prop
}) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const { id, index } = useParams<{ id: string,index?:string }>();
  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };

  const handleDuplexTypeChangeWithCheck = (typeIndex: number, value: number) => {
    if (!selectedSquare) return;

    const currentTotalWidth = items.reduce((sum, item, index) => {
      if (index === typeIndex) {
        return sum + item.width * value + 2;
      }
      return sum + item.width * item.value + 2;
    }, 0);

    const maxWidth = (widths[0] || 50) * 5;
    if (currentTotalWidth > maxWidth) {
      setSnackbarMessage("You don't have enough space to add a pusher 33");
      setSnackbarOpen(true);
      return;
    }

    handleDuplexTypeChange(typeIndex, value);
  };

  return (
    <Box className={classes.container}>
      <SideBarRight isOpen={isOpen} setIsOpen={setIsOpen}></SideBarRight>
      <BrandModal open={openModal} handleClose={() => { setOpenModal(false) }} label={"Configuration"} />
      <img src={Logo} alt="Logo" onClick={()=>navigate('/')} className={classes.logo} />
      <div className={classes.buttonContainer}>
        <div style={{ display: "flex", flexDirection: "column", width: "100%" }}>
          <Typography variant="body2" sx={{ marginBottom: "12px" }}>
            STEP 3/7
          </Typography>
          <Typography variant="h4" sx={{ marginBottom: "12px" }}>
            RRP Brand Allocation
          </Typography>
          <Typography variant="h6" sx={{ marginBottom: "10px", maxWidth: '200px' }}>
            Allocate the desired brands to the rows
          </Typography>
        </div>
        {selectedSquare && <div style={{
          height: "32px",
          padding: "10px 14px",
          borderRadius: "32px",
          display: "flex",
          alignItems: "center",
          backgroundColor: "#F5F9FF"
        }}>
          <Typography variant="h6" sx={{ fontSize: "12px", fontWeight: "800 !important" }}>COLUMN {selectedSquare?.col + 1}, ROW {selectedSquare?.row + 1}</Typography>
        </div>}
        {selectedSquare && (
          <FillOrder
            height={58}
            boxPerRow={selectedSquare.row}
            items={items}
            overLayResult={overLayResult}
            setItems={setItems}
            displayItems={displayItems}
            setDisplayItems={setDisplayItems}
            handleDuplexTypeChange={handleDuplexTypeChangeWithCheck}
            getDuplexTypeValue={getDuplexTypeValue}
            selectedButton={selectedButton}
            setSnackbarMessage={setSnackbarMessage}
            setSnackbarOpen={setSnackbarOpen}
            widths={widths}
          />
        )}
        <div style={{ display: "flex", alignItems: "center" }}>
          <IconButton onClick={() => { 
             if(index ) {
              navigate('/rrp/step4/' + (id || '')+'/'+(index || ''))

            }else {
              navigate('/rrp/step4/' + (id || ''))

            }
            
            
            }} size="large">
            <Box component="span" className={classes.iconButton2}>
              <Back />
            </Box>
          </IconButton>
          <Button variant="contained" color="info" onClick={onContinue} sx={{ marginTop: "0px" }}>
            Continue
          </Button>
        </div>
      </div>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}  // Position the Snackbar higher on the screen
        style={{ bottom: '2%' }}  // Adjust the top position to move it higher
      >
        <Alert onClose={handleCloseSnackbar} severity="warning">
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default Sidebar;
