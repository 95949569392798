import React, { useState, useEffect } from "react";
import { Box, Button, IconButton } from "@mui/material";
import Logo from "../../../assets/images/Logo1.png";
import { ReactComponent as Back } from "../../../assets/icons/Back.svg";
import { useStyles } from "./SideBarStyles";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import Typography from "@mui/material/Typography";
import Modal from "../ModalComponent/Modal";
import { getAllRRPConfigurations } from "../../../services/rrpConfigurationService";
import { getAllRRPNewConfigs } from "../../../services/rrpNewConfigurationService";

interface SidebarProps {
  onValueSelected: (value: any[]) => void;
  initialSelectedRRPs: number[];
  onSave: () => void;
}

const Sidebar: React.FC<SidebarProps> = ({ onValueSelected, initialSelectedRRPs, onSave }) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const [modalOpen, setModalOpen] = useState(false);
  const [configurations, setConfigurations] = useState<any[]>([]);
  const { id } = useParams<{ id: string }>();
  const [searchParams] = useSearchParams();
  const index = searchParams.get('index');
  console.log('index',index)
  useEffect(()=>{
    if(index){
      setModalOpen(true)

    }
  },[index])
  useEffect(() => {
    const fetchConfigurations = async () => {
      try {
        const configs = await getAllRRPNewConfigs();
        setConfigurations(configs);
      } catch (error) {
        console.error("Error fetching configurations:", error);
      }
    };
    fetchConfigurations();
  }, []);

  return (
    <Box className={classes.container}>
      <Modal
        open={modalOpen}
        handleClose={() => setModalOpen(false)}
        label=""
        onValueSelected={onValueSelected}
        configurations={configurations}
        initialSelectedValues={initialSelectedRRPs}
      />
      <img src={Logo} alt="Logo"  onClick={()=>navigate('/')} className={classes.logo} />
      <div className={classes.buttonContainer}>
        <div style={{ display: "flex", flexDirection: "column" }}>
          <Typography variant="body2" sx={{ marginBottom: "12px" }}>
            STEP 6/10
          </Typography>
          <Typography
            variant="h4"
            sx={{ marginBottom: "12px", fontSize: "20px !important" }}
          >
            Set the position of special RRP
          </Typography>
          <Typography variant="h6" sx={{ marginBottom: "50px" }}>
          Select the RRP configuration
          </Typography>

          <Button
            color="primary"
            variant="contained"
            onClick={() => setModalOpen(true)}
            sx={{
              marginBottom: "10px !important",
              "background-color": "#F2F8FF",
              color: "#0179FF",
              width: "100%",
              justifyContent: "center",
              "&:hover": {
                color: "black",
              },
            }}
          >
            Select configuration
          </Button>
        </div>
        <div>
          <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
            <IconButton onClick={() => navigate("/step6/"+id)}>
              <Box component="span" className={classes.iconButton}>
                <Back />
              </Box>
            </IconButton>
            <Button
              variant="contained"
              color="info"
              onClick={onSave}
              sx={{ marginTop: "0px" }}
            >
              Next
            </Button>
          </div>
        </div>
      </div>
    </Box>
  );
};

export default Sidebar;
