import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Avatar,
  Typography,
  Popover,
} from "@mui/material";
import {
  Add as AddIcon,
} from "@mui/icons-material";
import LogoutIcon from '@mui/icons-material/Logout';

import Logo from "../../assets/images/Logo1.png";
import { ReactComponent as SKUS } from "../../assets/icons/SKUS.svg";
import { ReactComponent as Stores } from "../../assets/icons/Stores.svg";
import { ReactComponent as Home } from "../../assets/icons/Home.svg";
import { ReactComponent as SkuProi } from "../../assets/icons/SkuProi.svg";
import { ReactComponent as RRPIcon } from "../../assets/icons/RRP.svg";

import {useMsal} from "@azure/msal-react";

import {loginRequest} from "../../authConfig";
import {callMsGraph} from "../../graph";

import { useStyles } from "./SideBarStyles";
import { useLocation, useNavigate } from "react-router-dom";
import BrandModal from "../Step1/ModalComponent/Modal";
import { getAllKeyAccounts, getGroupedChains } from "../../services/posuniversService";
import { ClassNames } from "@emotion/react";
import { processUserRoles } from "../helpers/roles";

const Sidebar: React.FC = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const [openModal, setOpenModal] = useState(false);
  const location = useLocation();

  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };
  const [graphData, setGraphData] = useState(null);

  const { instance, accounts, inProgress } = useMsal();

  // Extract roles from the account object
  const userRoles = accounts[0]?.idTokenClaims?.roles || [];
  const currentRole =processUserRoles(userRoles)
    console.log('accounts',accounts)
  // Extract first and last initials from the account's name
  const fullName = accounts[0]?.name || "";
  const firstInitial = fullName.split(" ")[0]?.[0]?.toUpperCase() || "";
  const lastInitial = fullName.split(" ")[1]?.[0]?.toUpperCase() || "";
  const initials = `${firstInitial}${lastInitial}`;

  // Helper function to check if the user has a specific role
  const hasRole = (role: string) => userRoles.includes(role);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handlePopoverOpen = (event: React.MouseEvent<HTMLDivElement>) => {
    setAnchorEl(event.currentTarget);
  };
  
  const handlePopoverClose = () => {
    setAnchorEl(null);
  };
  
  const open = Boolean(anchorEl);
  useEffect(() => {
    // Silently acquires an access token which is then attached to a request for MS Graph data
    instance
      .acquireTokenSilent({
        ...loginRequest,
        account: accounts[0],
      })
      .then((response:any) => {
        callMsGraph(response.accessToken).then((response: any) => {
          setGraphData(response);
        });
      });
  }, []);
  const [groupedChains, setGroupedChains] = useState<any[]>([]);
  const [indId,setIndId]=useState<number| undefined>()
  useEffect(() => {
    const fetchShelfConfigs = async () => {
      try {
        const fetchedGroupedChains = await getGroupedChains();
        setGroupedChains(fetchedGroupedChains);
        const keyAccountsData = await getAllKeyAccounts();
        const Individual = keyAccountsData.find((value:any)=>value.name ==='Individual Store')
        setIndId(Individual.id)
      } catch (error) {
        console.error("Error fetching shelf configs:", error);
      } finally {
        //setLoading(false); // Set loading to false after data is fetched
      }
    };

    fetchShelfConfigs();
  }, []);
  return (
    <Box className={classes.container}>
      <img src={Logo} alt="Logo" onClick={()=>navigate('/')} className={classes.logo} />
      <div className={classes.buttonContainer}>
        
        <div style={{ display: "flex", flexDirection: "column",}}>
  
          <Button
            color="primary"
            variant="contained"
            sx={{marginBottom:"10px !important",fontSize: {
              sm: '12px',
              md: '12px !important',
              lg: '14px',
            }}}
            onClick={handleOpenModal}  // Open modal on click
            startIcon={
              <Box component="span" className={classes.iconButton}>
                <AddIcon />
              </Box>
            }
          >
            Create New Shelf Composition
          </Button>
          <Button
            color="secondary"
            variant="contained"
            onClick={() => {
              navigate("/");
            }}
            sx={{ fontWeight: "800" }}
            startIcon={
              <Box component="span" className={classes.iconButton}>
                <Home className={ location.pathname.includes('sku-priority') ? '': classes.svgIcon} />
              </Box>
            }
          >
            Home Page
          </Button>
          <Button
            color="secondary"
            variant="contained"
            onClick={() => {navigate('/skulist')}}
            startIcon={
              <Box component="span" className={classes.iconButton}>
                <SKUS />
              </Box>
            }
          >
            My SKUs
          </Button>
          {(hasRole("PMICLDSGShelfcreationtoolSKAdminPRDIGA") ||  hasRole("PMICLDSGShelfcreationtoolSKManagerPRDIGA"))  && (
            <Button
              color="secondary"
              variant="contained"
              onClick={() => {
                navigate("/pos");
              }}
              startIcon={
                <Box component="span" className={classes.iconButton}>
                  <Stores />
                </Box>
              }
            >
              My Stores
            </Button>
          )}

{(hasRole("PMICLDSGShelfcreationtoolSKAdminPRDIGA") ||  hasRole("PMICLDSGShelfcreationtoolSKManagerPRDIGA"))&& (
            <Button
              color="secondary"
              variant="contained"
              onClick={() => {
                navigate("/sku-priority/3");
              }}
              startIcon={
                <Box component="span" className={classes.iconButton}>
                  <RRPIcon  className={!location.pathname.includes('sku-priority/3') ? '': classes.svgIcon} />
                </Box>
              }
            >
              My RRP's
            </Button>
          )}
        </div>

        <div style={{ display: "flex", flexDirection: "column", marginTop: "20px" }}>
          {/* Display user initials */}
          {(hasRole("PMICLDSGShelfcreationtoolSKAdminPRDIGA") ||  hasRole("PMICLDSGShelfcreationtoolSKManagerPRDIGA"))&& (
            <Button
              color="secondary"
              variant="contained"
              onClick={() => {
                navigate("/sku-priority");
              }}
              sx={{
                marginBottom: "30px",
              }}
              startIcon={
                <Box component="span" className={classes.iconButton}>
                  <SkuProi />
                </Box>
              }
            >
              <div
                style={{
                  color: "#383A49",
                  fontSize: "14px",
                  fontWeight: "600",
                  textAlign: "start",
                }}
              >
                <div
                  style={{
                    color: "#9CA0AB",
                    fontSize: "12px",
                    fontWeight: "600",
                  }}
                >
                  Allocation is done based upon
                </div>
                SKU Priority List
              </div>
            </Button>
          )}
           <div>
    <div
      style={{ display: "flex", alignItems: "center", marginBottom: "10px", cursor: "pointer" }}
      onClick={handlePopoverOpen}
    >
      <Avatar sx={{ bgcolor: "#0179FF", color: "#fff", marginRight: "10px" }}>
        {initials}
      </Avatar>
      <span style={{ fontSize: "14px", fontWeight: "600", color: "#383A49", textTransform: 'capitalize' }}>
        Logged in as {firstInitial}.{lastInitial} {currentRole.userType}.
      </span>
    </div>

    <Popover
      open={open}
      anchorEl={anchorEl}
      onClose={handlePopoverClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
    >
      <Box sx={{ p: 2, minWidth: 200 }}>
        <Typography variant="body1" fontWeight="600">User Details</Typography>
        <Typography variant="body2">Name: {fullName}</Typography>
        <Typography variant="body2" sx={{textTransform:'capitalize'}}>Role: {currentRole.userType}</Typography>
        <Typography variant="body2" sx={{textTransform:'capitalize'}}>Key accounts:</Typography>

        {currentRole.keyAccounts.map((value)=>{
          return         <Typography variant="body2" sx={{marginLeft:'2px', textTransform:'capitalize'}}>{value}</Typography>

        })}
      </Box>
    </Popover>
  </div>
          
          <Button
            variant="contained"
            color="primary"
            sx={{
              maxHeight: "36px",
              fontSize: "14px",
              fontWeight: "600",
              padding: "10px 40px",
              background: "#7180961A",
              color: "#718096",
              display: "flex",
              justifyContent: "space-between",
              alignSelf: "flex-start",
              alignItems: "center",
              "&:hover": {
                color: "black",
                background: "#0179FF",
              },
            }}
            onClick={() => instance.logoutRedirect()}
          >
            <LogoutIcon />
            <Box sx={{ marginLeft: "10px" }}>Log out</Box>
          </Button>
    

        </div>
      </div>

      {/* Modal Component */}
      <BrandModal groupedChains={groupedChains} indId={indId} open={openModal} handleClose={handleCloseModal} label="Shelf"  />
    </Box>
  );
};

export default Sidebar;
