import React, { useState, useEffect } from 'react';
import Grid from "@mui/material/Grid";
import Sidebar from '../../components/RRP/Step3New/sideBar/SideBar';
import DemoComponent from '../../components/RRP/Step3New/Content/Content';
import { getFromJson, saveToJson } from '../../components/helpers/localData';
import { getRRPNewConfigById, updateRRPNewConfig } from '../../services/rrpNewConfigurationService';
import { useNavigate, useParams } from 'react-router-dom';
import { getLedScreenById } from '../../services/ledScreensService';
import { getMarketingMaterialById } from '../../services/marketingMaterialsService';

interface SquareData {
  row: number;
  col: number;
  duplexTypes: { id: number, value: number }[];
}

const duplexInputs = [
  { text: "VEEV ONE Standing DP", id: 0, color: '#5DD671', value: 0, width: 28.5 },
  { text: "VEEV NOW Laying  DP", id: 1, color: '#F95252', value: 0, width: 19.5},
  { text: "VEEV ONE Standing & Laying DP", id: 2, color: '#FFE455', value: 0, width: 28.5 },
  { text: " CC Laying 2 DP", id: 3, color: 'blue', value: 0, width: 27.5 },
  { text: "VEEV Now Standing DP", id: 4, color: '#5DD671', value: 0, width: 19.5 },
  { text: "HNB DP", id: 5, color: 'blue', value: 0, width: 35 },
  { text: "VEEV ONE Laying DP", id: 6, color: '#F95252', value: 0, width: 28.5 },
  { text: "HEETS", id: 7, color: '#0179FF', value: 0, width:   38.75  },
  { text: "TEREA", id: 8, color: '#F95252', value: 0, width:   38.75  },
  { text: "Fiit", id: 9, color: '#FFE455', value: 0, width:   38.75 },
  { text: "Delia", id: 10, color: '#A5ABAA', value: 0, width:  38.75  },
  { text: "VEEV ONE", id: 11, color: '#9747FF', value: 0, width: 28.5 },
  { text: "VEEV NOW", id: 12, color: '#FF9F47', value: 0, width: 19.5 },
  { text: "LEVIA", id: 13, color: '#5DD671', value: 0, width: 36.5 },
];
type Square = {
  width: number;
  height: number;
  x: number;
  y: number;
  title?: string;
  positions?: any;
};
type MarketingMaterial = {
  id?: number;
  title?: string;
  width: number;
  height: number;
  x: number;
  y: number;
  positions?: any;
};
const Step3New: React.FC = () => {
  const [col, setCol] = useState<number>(1);
  const [row, setRow] = useState<number>(2);
  const [rowDistance, setRowDistance] = useState<number>(2);
  const [colDistance, setColDistance] = useState<number>(1);

  const [selectedButton, setSelectedButton] = useState<string>('Veev Now');
  const [squaresWithImages, setSquaresWithImages] = useState<Array<Array<string>>>([]);
  const [selectedSquare, setSelectedSquare] = useState<{ col: number; row: number } | null>(null);
  const [selectedProductCardImage, setSelectedProductCardImage] = useState<string>('');
  const [items, setItems] = useState(duplexInputs);
  const [squaresData, setSquaresData] = useState<SquareData[]>([]);
  const [configId, setConfigId] = useState<string | null>(null);
  const [widths, setWidths] = useState<any[]>([]); // State to store the config ID
  const { id, index } = useParams<{ id: string,index?:string }>();
  const [ccSpace, setCcSpace] = useState<number>(8);
  const [squares, setSquares] = useState<Square[]>(
    []
 );
  const [squaresYellow, setSquaresYellow] = useState<Square[]>(
    []
   );
  
   const [pinkSquares, setPinkSquares] = useState(
   squares
   );
   const [yellowSquares, setYellowSquares] = useState(
     squaresYellow
   );
   const fetchShelfConfigLed = async (id: string) => {
    try {
      const shelfConfig = await getRRPNewConfigById(id);
      const ledScreenIds = shelfConfig.ledScreens;
  
      const ledScreenMap = new Map<number, MarketingMaterial & { isAdded: number, instances: { x: number, y: number }[] }>();
  
      for (const item of ledScreenIds) {
        const ledScreen = await getLedScreenById(item.id);
        if (ledScreenMap.has(ledScreen.id)) {
          const existingLedScreen = ledScreenMap.get(ledScreen.id)!;
          existingLedScreen.isAdded! += 1;
          existingLedScreen.instances.push({ x: item.x, y: item.y });
        } else {
          ledScreenMap.set(ledScreen.id, { ...ledScreen, isAdded: 1, instances: [{ x: item.x, y: item.y }] });
        }
      }
  
      const expandedLedScreens = Array.from(ledScreenMap.values()).flatMap(ledScreen => 
        ledScreen.instances.map(instance => ({
          ...ledScreen,
          x: instance.x,
          y: instance.y,
          isAdded: 1 // Set isAdded to 1 for each instance in the expanded list
        }))
      );
  
      setPinkSquares(expandedLedScreens);
    } catch (error) {
      console.error('Failed to fetch shelf configuration and LED screens:', error);
    }
  };
  useEffect(() => {
    const fetchConfig = async () => {
      id && setConfigId(id);
      try {
        const config =id && await getRRPNewConfigById(id);
        setCol(config.col_position);
        setRow(config.row_position);
        setRowDistance(config.rowDistance || 2);
        setColDistance(config.colDistance || 1);
        setSquaresData(config.squaresData);
        setWidths(config.widths);
        setCcSpace(config.ccSpace)
        const marketingMaterialIds = config.marketingMaterials || [];
        
        const materialMap = new Map<number, MarketingMaterial & { isAdded: number, instances: { x: number, y: number }[] }>();
      
      
        for (const item of marketingMaterialIds) {
          const material = await getMarketingMaterialById(item.id);
          if (materialMap.has(material.id)) {
            const existingMaterial = materialMap.get(material.id)!;
            existingMaterial.isAdded! += 1;
            existingMaterial.instances.push({ x: item.x, y: item.y });
          } else {
            materialMap.set(material.id, { ...material, isAdded: 1, instances: [{ x: item.x, y: item.y }] });
          }
        }
  
        const expandedMaterials = Array.from(materialMap.values()).flatMap(material => 
          material.instances.map(instance => ({
            ...material,
            x: instance.x,
            y: instance.y,
            isAdded: 1 // Set isAdded to 1 for each instance in the expanded list
          }))
        );
        setYellowSquares(expandedMaterials);

        setItems( items);
      } catch (error) {
        console.error('Error fetching config:', error);
      }
    };

    fetchConfig();
  }, []);
  useEffect(() => {
    if (id) {
      fetchShelfConfigLed(id);
    }
  }, [id]);

  useEffect(() => {
    setSquaresWithImages(new Array(row).fill(new Array(col).fill('')));
  }, [col, row]);

  const handleDuplexTypeChange = (typeIndex: number, value: number) => {
    if (!selectedSquare) return;
    setSquaresData(prev => prev.map(square => {
      if (square.row === selectedSquare.row && square.col === selectedSquare.col) {
        let newDuplexTypes = [...square.duplexTypes];
        newDuplexTypes[typeIndex] = { ...newDuplexTypes[typeIndex], value };
        return { ...square, duplexTypes: newDuplexTypes };
      }
      return square;
    }));
  };
const navigate = useNavigate()
function hasVeevNowWithValue(duplexTypes:any) {
  for (const dt of duplexTypes) {
    // Check if current duplex type matches our criteria
    if (dt.text === "VEEV NOW" && dt.value > 0) {
      return true;
    }

 
  }
  return false;
}

// Main function to check the entire data array
function hasVeevNowValueMoreThanZero(dataArray:any) {
  for (const item of dataArray) {
    if (item.duplexTypes && hasVeevNowWithValue(item.duplexTypes)) {
      return true;
    }
  }
  return false;
}
function hasVeevOneWithValue(duplexTypes:any,type:string) {
  for (const dt of duplexTypes) {
    // Check if current duplex type matches our criteria
    if (dt.text === type && dt.value > 0) {
      return true;
    }

 
  }
  return false;
}

// Main function to check the entire data array
function hasVeevOneValueMoreThanZero(dataArray:any,type:string) {
  for (const item of dataArray) {
    if (item.duplexTypes && hasVeevOneWithValue(item.duplexTypes,type)) {
      return true;
    }
  }
  return false;
}
  const handleContinue = async () => {
    if (!configId) {
      console.error('Config ID is not set');
      return;
    }

    const config = {
      col_position: col,
      row_position: row,
      rowDistance,
      colDistance,
      squaresData: squaresData,
      items: items,
      ccSpace: ccSpace,
      widths: widths,
    };

    try {
      const response = await updateRRPNewConfig(configId, config);
      if(hasVeevOneValueMoreThanZero(squaresData,'VEEV ONE') ||hasVeevOneValueMoreThanZero(squaresData,'VEEV ONE Standing DP') ||hasVeevOneValueMoreThanZero(squaresData,'VEEV ONE Standing & Laying DP') ||hasVeevOneValueMoreThanZero(squaresData,'VEEV ONE Laying DP') ) {
          navigate('/rrp/step4New/'+configId + '/'+(index || ''))

        }else {
          navigate('/rrp/step5/'+configId + '/'+(index || ''))

        }
      console.log('Config updated2:', response);
    } catch (error) {
      console.error('Error updating config:', error);
    }
  };
  

  return (
    <div>
      <Grid container>
        <Grid item xs={3}>
          <Sidebar
            col={col}
            row={row}
            colDistance={colDistance}
            onColDistanceChange={setColDistance}
            rowDistance={rowDistance}
            onRowDistanceChange={setRowDistance}
            onColChange={setCol}
            onRowChange={setRow}
            selectedProductCardImage={selectedProductCardImage}
            setSelectedProductCardImage={setSelectedProductCardImage}
            selectedSquare={selectedSquare}
            items={items}
            setItems={setItems}
            handleDuplexTypeChange={handleDuplexTypeChange}
            getDuplexTypeValue={(typeIndex: number) => items[typeIndex]?.value || 0}
            selectedButton={selectedButton}
            setSelectedButton={   setSelectedButton}
            squaresData={squaresData as any}
            setSquaresData={setSquaresData}
            widths={widths}
         


            onContinue={handleContinue} // Pass the handleContinue function to the Sidebar
          />
        </Grid>
        <Grid item xs={9} style={{ backgroundColor: "#F5F9FF" }}>
          <DemoComponent
            col={col}
            row={row}
            setCol={setCol}
            setRow={setRow}
            rowDistance={rowDistance}
            setRowDistance={setRowDistance}
            colDistance={colDistance}
            setColDistance={setColDistance}
            squaresWithImages={squaresWithImages}
            selectedSquare={selectedSquare}
            setSelectedSquare={setSelectedSquare}
            items={items}
            setItems={setItems}
            handleDuplexTypeChange={handleDuplexTypeChange}
            getDuplexTypeValue={(typeIndex: number) => items[typeIndex]?.value || 0}
            selectedButton={selectedButton}
            setSelectedButton={setSelectedButton}
            squaresData={squaresData as any}
            setSquaresData={setSquaresData as any}
            pinkSquares={pinkSquares || []}
            yellowSquares={yellowSquares || []}
          />
        </Grid>
      </Grid>
    </div>
  );
};

export default Step3New;
